import { format } from "date-fns";
import { Post } from "../Posts/processPosts";
import { CampaignOffer } from "../Campaigns/processCampaigns";

export function generatePostDates(offerPostCount: number, offerStartDate: number, offerEndDate: number) {

    // Calculate the difference in days between each post
    const timeDiff = offerEndDate - offerStartDate;
    const offerDays = timeDiff / (1000 * 3600 * 24);

    const estimatedAverageCountDaysBetweenEachPost = offerDays / offerPostCount;

    const listDates: Date[] = [];

    for (let x = 0; x < offerPostCount; x++) {
        const postDate = new Date(offerStartDate + (x * estimatedAverageCountDaysBetweenEachPost * 1000 * 3600 * 24));
        listDates.push(postDate);
    }

    return listDates
}

export function generateNewPost(date: Date, offer: CampaignOffer, type: string): Post {
    return {
        CPI: offer.offerCPI,
        CPE: offer.offerCPE,
        budgetValue: offer.offerBudget,
        budgetEngagements: Math.round((offer.expectedEngagementsHigh + offer.expectedEngagementsLow) * 0.5),
        budgetImpressions: Math.round((offer.expectedImpressionsHigh + offer.expectedImpressionsLow) * 0.5),
        date: format(date, 'yyyy/MM/dd'),
        campaignId: offer.campaignId,
        campaignOfferId: offer.campaignOfferId,
        socialAccountId: offer.socialAccountId,
        socialType: offer.socialAccountType,
        postType: type,
    }
}

export function generateNewPostList(listDates: Date[], offer: CampaignOffer, type: string): Post[] {
    const newPosts: Post[] = []
    listDates.forEach(date => {
        newPosts.push(generateNewPost(date, offer, type));
    });
    return newPosts;
}

export function generatePostCountPerMonth(listDates: Date[]): { x: string; y: number }[] {
    const counts = new Map<string, number>();

    listDates.forEach(date => {
        const monthYear = format(date, 'MMMM yyyy');
        counts.set(monthYear, (counts.get(monthYear) || 0) + 1); // Month is indexed at 0
    });

    return Array.from(counts).map(([monthYear, count]) => ({ x: monthYear, y: count }));
}