import { SERVER_URLS } from "../../constants";
import { Post } from "./processPosts";

export const getPostsForCampaignOfferId = async (
        idToken: string | undefined,
        campaignOfferId: number): Promise<any> => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${idToken}`)
  
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow' as RequestRedirect
    };
  
    try {
      const response = await fetch(SERVER_URLS.BACKEND_URL+"/valuence/api/getPostsForCampaignOfferId/"+campaignOfferId, requestOptions);
      if (!response.ok) {
        if (response.status == 404) {
          return [] // return an empty array so that TypeScript properly handles the empty Post array
        }
        else {
          throw new SyntaxError('SyntaxError');
        }
      }
      const result = await response.json();

      return result;
      
    } catch (error) {
        console.log("Couldn't get /getPostsForCampaignOfferId in getPostsForCampaignOfferId: ", error)
    };
  };