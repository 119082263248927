import React from 'react';
import { Elements, useStripe, useElements, CardElement, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Load Stripe with your public key
const stripePromise = loadStripe('pk_test_A7jK4iCYHL045qgjjfzAfPxu');

const StripeCheckoutForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: card!,
    });

    if (error) {
      console.log(error);
      return;
    }

    // Send `paymentMethod.id` to your server for further processing.
    console.log(paymentMethod);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit">Submit</button>
    </form>
  );
};

const StripeCheckout: React.FC = () => {
  return (
    <Elements stripe={stripePromise}>
      <StripeCheckoutForm />
    </Elements>
  );
};

export default StripeCheckout;
