import { SERVER_URLS } from "../../constants";

export const getCampaignListWithAdminUser = async (
        userId: string | undefined, 
        idToken: string | undefined): Promise<any> => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${idToken}`)
  
    const rawGetCampaignListWithAdminUser = JSON.stringify({
      "user_id": userId,
    });
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: rawGetCampaignListWithAdminUser,
      redirect: 'follow' as RequestRedirect
    };
  
    try {
      const response = await fetch(SERVER_URLS.BACKEND_URL+"/valuence/api/getCampaignListWithAdminUser", requestOptions);
      if (!response.ok) {
        throw new SyntaxError('SyntaxError');
      }
      const result = await response.json();

      return result.campaignList;
      
    } catch (error) {
        console.log("Couldn't get /getCampaignListWithAdminUser in getCampaignListWithAdminUser: ", error)
    }
};