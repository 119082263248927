import React, { useState, useRef, useEffect } from 'react';
import { DateRange } from 'react-date-range';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import styles from './DatePicker.module.css';
import './../../../Styles/globalColors.css';

interface DatePickerProps {
    onDateChange: (range: { startDate: Date; endDate: Date }) => void;
    daysCounter?: number;
  }
  
  const DatePicker: React.FC<DatePickerProps> = ({ onDateChange, daysCounter = 0 }) => {
    const [dateRange, setDateRange] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ]);
    const [showDateRange, setShowDateRange] = useState(false);
    const dateRangeRef = useRef<HTMLDivElement | null>(null);

    const toggleDateRange = () => {
        setShowDateRange(!showDateRange);
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (dateRangeRef.current && !dateRangeRef.current.contains(event.target as Node)) {
            setShowDateRange(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getFormattedDateRange = () => {
        const { startDate, endDate } = dateRange[0];
        if (startDate.toLocaleDateString() != endDate.toLocaleDateString()) {
            return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
        }
        return "Select a date range"; // Default caption
    };

    const daysText = (daysCounter < 2) ? 'day' : 'days'

    return (
        <div>
            <div className={styles.datePickerContainer}>
                <button onClick={toggleDateRange} className={styles.datePickerButton}>
                    <span>{getFormattedDateRange()}</span>
                </button>
                    <span style={{fontSize: '13px', color: 'var(--valGrey)', whiteSpace: 'nowrap'}}>{daysCounter} {daysText}</span>
            </div>
            {showDateRange && (
                <div style={{ position: 'absolute', zIndex: 1000 }} ref={dateRangeRef}>
                    <DateRange
                        editableDateInputs={true}
                        onChange={(item: any) => {
                            const newRange = item.selection;
                            setDateRange([newRange]);
                            onDateChange({ startDate: newRange.startDate, endDate: newRange.endDate });
                          }}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                        rangeColors={['#3d91ff']}
                    />
                </div>
            )}
        </div>
    )
}

export default DatePicker;
