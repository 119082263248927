import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import './StandardTextInput.css';

interface StyledTextFieldProps { 
  isFocused: boolean;
 };

const StyledTextField = styled(TextField)<StyledTextFieldProps>(({ isFocused }) => ({
    borderRadius: '20px',
    backgroundColor: isFocused ? '#FFF' : 'transparent',
    borderBottomRadius: '20px',
    '& fieldset': {
      borderRadius: '20px',
      borderWidth: '2px'
    },
    '& Mui-focused': {
      color: 'var(--defaultGreen)',
    },
    '& label': {
      backgroundColor: 'var(--beige)',
      paddingLeft: '6px',
      marginLeft: '-4.5px',
      paddingRight: '7.5px',
      borderRadius: '8px'
    },
    '& label.Mui-focused': {
      color: 'var(--defaultGreen)',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'var(--defaultGreen)', 
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--defaultGreen)',
        color: 'black',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        borderWidth: 0,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      },
      },
  }));

interface StandardTextInputProps {
    value: string;
    onChangeText: (text: string) => void;
    placeholder?: string;
    className?: string;
    style?: React.CSSProperties;
  }
  
  const StandardTextInput: React.FC<StandardTextInputProps> = ({ value, onChangeText, placeholder, className, style }) => {
    const [isFocused, setIsFocused] = useState(false);
  
    return (
        <StyledTextField 
          isFocused={isFocused}
          placeholder={placeholder}
          style={style}
          className={className}
          value={value}
          size='small'
          onChange={e => onChangeText(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
    );
  };

export default StandardTextInput;
