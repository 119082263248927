import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Modal } from '@mui/material';
import StandardTextInput from '../../Inputs/StandardTextInput/StandardTextInput';
import CurrencyDropdown from '../../Inputs/CurrencyDropdown/CurrencyDropdown';
import { debounce } from 'lodash';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';


import { UserContext } from '../../../App';

import styles from './SettingsModals.module.css'
import './../../../Styles/globalColors.css'
import MainButton from '../../Buttons/MainButton/MainButton';
import { createWorkspace } from '../../../Utils/Workspace/createWorkspace';


interface CreateWorkspaceModalProps {
    open: boolean;
    onClose: () => void;
}

const CreateWorkspaceModal: React.FC<CreateWorkspaceModalProps> = ({ open, onClose }) => {
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");
    const { user, setUser } = userContext;
    const [token, setToken] = useState<string | null>(null);
    const [brandName, setBrandName] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (user) {
        user.getIdToken(true).then((fetchedToken) => {
            setToken(fetchedToken);
        });
        }
    }, [user]);

    const createNewWorkspace = useMemo(() => debounce(async () => {
        if (!token) {
            console.error("Failed to retrieve ID token");
            return;
        }

        console.log("DEBUG - brandName", brandName)
    
        createWorkspace(user?.uid, brandName, token)
            .then((data: any) => {
                console.log(data);
                setSuccessMessage('Workspace created successfully!');
            })
            .catch((error: any) => {
                console.error("Failed create workspace", error);
            });
    }, 300), [token, user, brandName]);

    return (
        <Modal open={open} onClose={onClose} className={styles.background}>
            <div className={styles.modalContainer}>
                <div className={styles.profileSettings}>
                    <div className={styles.profileSubSection}>
                        <div className={styles.profileInputs}>
                        <StandardTextInput value={brandName} placeholder='Workspace Name' onChangeText={setBrandName} />
                        </div>
                    </div>

                    {/* Display success message */}
                    {successMessage && 
                        // <div className={styles.successMessage}>{successMessage}</div>
                        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                            {successMessage}
                        </Alert>
                    }
                </div>
                <MainButton title={'Create Workspace'} onPress={createNewWorkspace}/>
            </div>
        </Modal>
    );
};

export default CreateWorkspaceModal;