import React, { useState, useEffect, useRef, useCallback } from 'react';
import { alwaysFormatNumber } from '../../../Utils/Reports/formatNumber';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    ScatterController,
    LineController,
    TimeScale,
    TimeSeriesScale,
    BarElement,
    BarController
} from 'chart.js';
import { Line, Scatter, Chart } from 'react-chartjs-2';
import chartjsPluginDragData from 'chartjs-plugin-dragdata';
import 'chartjs-adapter-date-fns';
import { format, getDaysInMonth } from 'date-fns';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, ScatterController, LineController, TimeScale, TimeSeriesScale, chartjsPluginDragData, BarElement, BarController);

export type ChartDataPoint = {
    x: Date; // Date shown on the visual chart to improve UX
    y: number;
    label: Date; // Display +30 days on tooltip and set that date for the Post sent to the DB
};

type ChartProps = {
    datasetScatterExpected: ChartDataPoint[];
    datasetExpected: ChartDataPoint[];
    datasetActuals?: ChartDataPoint[];
    colorScatterExpected: string;
    colorExpected: string;
    colorActuals: string;
    labelOptionScatterExpected: string;
    labelOptionExpected: string;
    labelOptionActuals: string;
    dollarSign: boolean;
    startDate: Date;
    endDate: Date;
    hideScatterPlot?: boolean;
};

const InteractiveChart: React.FC<ChartProps& { onOfferDateListChange?: (offerDateList: Date[], startDate: Date, endDate: Date) => void }> = ({ 
    datasetScatterExpected,
    datasetExpected, 
    datasetActuals, 
    colorScatterExpected,
    colorExpected, 
    colorActuals, 
    labelOptionScatterExpected,
    labelOptionExpected, 
    labelOptionActuals, 
    dollarSign,
    startDate,
    endDate,
    hideScatterPlot,
    onOfferDateListChange
    }) => {
    const chartRef = useRef<any>();
    const [datasetPostDates, setDatasetPostDates] = useState<ChartDataPoint[]>(datasetScatterExpected);
    const datasetPostDatesRef = useRef(datasetPostDates);
    const [scatterDates, setScatterDates] = useState<ChartDataPoint[]>(datasetScatterExpected);

    const offsetDatePlus = (date: Date) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + (getDaysInMonth(date)/2));
        return newDate;
    };

    const offsetDateMinus = (date: Date) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - (getDaysInMonth(date)/2));
        return newDate;
    };
    
    useEffect(() => {
        const offsetDatasetScatterExpected = datasetScatterExpected.map(dataPoint => ({
            ...dataPoint,
            x: offsetDateMinus(dataPoint.x)
        }));
        setScatterDates(offsetDatasetScatterExpected);
    }, [datasetScatterExpected]);

    useEffect(() => {
        if (chartRef && chartRef.current) {
            const chartInstance = chartRef.current;
            chartInstance.update();
            setDatasetPostDates(datasetScatterExpected);
        }
    }, [datasetScatterExpected, datasetExpected, datasetActuals, datasetPostDates, scatterDates]);
    
    useEffect(() => {
        datasetPostDatesRef.current = datasetPostDates;
    }, [datasetPostDates]);
    
    const chartOptions: Record<string, any> = {
        scales: {
            x: {
                stacked: false,
                type: 'time',
                time: {
                    unit: 'month',
                    displayFormats: {
                        month: 'MMM DD'
                    },
                    // tooltipFormat: 'MMM d, yy'
                },
                ticks: {
                    autoSkip: true,
                    maxRotation: 0,
                    setInterval: 2,
                    callback: function(value: number, index: any, ticks: any) {
                        return format(new Date((value)), 'MMMM');
                        },
                    },
                min: startDate,
                max: endDate
            },
            y: {
                ticks: {
                    beginAtZero: true,
                    font: {
                        size: window.innerWidth > 650 ? 14 : 10
                    },
                    callback: function(value: string | number) {

                        if (typeof value === 'number') {
                            return dollarSign ? `$${alwaysFormatNumber(value)}` : alwaysFormatNumber(value);
                        }
                        return value;
                    }
                },
            }
        },
        legend: {
            display: true,
        },
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: 4,
                right: 8
            }
        },
        dragData: true,
        plugins: {
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                    generateLabels: (chart: { data: { datasets: any[]; }; }) => {
                        return chart.data.datasets.map((dataset, i) => {
                            return {
                                text: dataset.label,
                                fillStyle: dataset.backgroundColor,
                                strokeStyle: dataset.borderColor,
                                lineWidth: 0,
                                pointStyle: i === 0 ? 'circle' : 'rect',
                                // pointStyle: 'rect',
                            };
                        });
                    },
                },
            },
            tooltip: {
                callbacks: {
                    title: function(context: any) {
                        if (context[0].datasetIndex === 0) {
                            return 'Post date';
                        } else if (context[0].datasetIndex === 1) {
                            return 'Budget';
                        } else {
                            return 'Actuals'
                        }
                    },
                    label: function(context: any) {
                        if (context.datasetIndex === 0) {
                            const label = context.raw.x;
                            return format(offsetDatePlus(label), 'MMMM d, yyyy');
                        } else {
                            return  dollarSign ? `$${alwaysFormatNumber(context.raw.y)}` : alwaysFormatNumber(context.raw.y);
                        }
                    },
                }
            },
            dragData: {
                round: 1, // Optional: to round dragged values
                onDragStart: function (e: any, datasetIndex: any, index: any, value: any) {
                    // console.log(e);
                    // console.log(`Dragging start: datasetIndex: ${datasetIndex}, index: ${index}, value: ${value}`);
                },
                onDrag: function (e: any, datasetIndex: any, index: any, value: any) {
                    // console.log(`Dragging: datasetIndex: ${datasetIndex}, index: ${index}, value: ${value}`);
                },
                onDragEnd: function (e: any, datasetIndex: any, index: any, value: any) {
                    // console.log(`Drag End: datasetIndex: ${datasetIndex}, index: ${index}, value: ${value}`);
                    if (datasetIndex === 0) {
                        // console.log("Before update:", datasetPostDatesRef.current);
                        const newDatasetExpected = datasetPostDatesRef.current.map((item, idx) => 
                            idx === index ? { ...item, x: offsetDatePlus(new Date(value.x)) } : item
                        );
                        // console.log("ABOUT TO UPDATE:", index, offsetDatePlus(new Date(value.x)));
    
                        // const sortedDatasetExpected = [...newDatasetExpected].sort((a, b) => 
                        //     a.x.getTime() - b.x.getTime()
                        // );
                        // console.log("After update:", newDatasetExpected);
                        // Call the parent callback with the updated dataset
                        onOfferDateListChange && onOfferDateListChange(newDatasetExpected.map(dp => dp.x), startDate, endDate);
                    }
                },
                responsive: true,
                dragData: true,
                dragX: true,
                dragY: false,
                pointHitRadius: 30
            }
        }        
    };

    const chartData = {
        datasets: [
            {
            dragData: true,
            dragX: true,
            dragY: false,
            showLine: false,
            type: 'line' as const,
            data: scatterDates,
            label: labelOptionScatterExpected,
            borderRadius: 4,
            tension: 0.4,
            pointBorderWidth: 4,
            pointRadius: 4,
            pointBackgroundColor: 'transparent',
            borderColor: colorScatterExpected,
            backgroundColor: colorScatterExpected,
            borderWidth: 6,
            hidden: hideScatterPlot ? false : true
        },
        {
            dragData: false,
            type: 'bar' as const,
            fill: true,
            data: datasetExpected,
            label: labelOptionExpected,
            borderColor: colorExpected,
            backgroundColor: colorExpected,
            borderRadius: 4,
            tension: 0.4,
            pointBorderWidth: 0,
            pointRadius: 4,
            pointBackgroundColor: 'transparent',
            hidden: false
        }],
    };

    if (datasetActuals) {
        chartData.datasets.push({
            dragData: false,
            type: 'bar' as const,
            fill: true,
            data: datasetActuals,
            label: labelOptionActuals,
            borderColor: colorActuals,
            backgroundColor: colorActuals,
            borderRadius: 4,
            tension: 0.4,
            pointBorderWidth: 0,
            pointRadius: 4,
            pointBackgroundColor: 'transparent',
            hidden: false
        })
    }

    return (
        <div style={{width: 'calc(100% - 4px)'}}>
            <Chart ref={chartRef} type='line' data={chartData} options={chartOptions} />
        </div>
    );
}

export default InteractiveChart;
