import React, { useEffect, useState, createContext, ReactNode, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth } from './firebase';

import HomePage from './Pages/HomePage/HomePage';
import LoginPage from './Pages/LoginPage/LoginPage';
import CreatorPage from './Pages/CreatorPage/CreatorPage';
import CampaignListPage from './Pages/CampaignListPage/CampaignListPage';
import CampaignPage from './Pages/CampaignPage/CampaignPage';
import OfferPage from './Pages/OfferPage/OfferPage';
import LikesPage from './Pages/LikesPage/LikesPage';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';
import SideBar from './Components/Navigation/SideBar/SideBar';
import BottomNavBar from './Components/Navigation/BottomNavBar/BottomNavBar';

import './App.css';
import './Styles/globalColors.css';
import DiscoveryPage from './Pages/DiscoveryPage/DiscoveryPage';
import PreferencesModal from './Components/UserAuth/SettingsModals/PreferencesModal';
import BillingModal from './Components/UserAuth/SettingsModals/BillingModal';
import CreateWorkspaceModal from './Components/UserAuth/SettingsModals/CreateWorkspaceModal';

export const DesktopContext = React.createContext({
  isDesktop: true,
  setIsDesktop: (isDesktop: boolean) => { }
});

interface UserContextProps {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

export const UserContext = createContext<UserContextProps | undefined>(undefined);


const App: React.FC = () => {
  const [isDesktop, setIsDesktop] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const [isPreferencesModalOpen, setIsPreferencesModalOpen] = useState(false);
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [isCreateWorkspaceModalOpen, setIsCreateWorkspaceModalOpen] = useState(false);

  const handleCreateWorkspaceModalOpen = () => {
    setIsCreateWorkspaceModalOpen(true);
  }

  const handleCreateWorkspaceModalClose = () => {
    setIsCreateWorkspaceModalOpen(false);
  }

  const handlePreferencesModalOpen = () => {
    setIsPreferencesModalOpen(true);
  };
  
  const handlePreferencesModalClose = () => {
    setIsPreferencesModalOpen(false);
  };
  
  const handleBillingModalOpen = () => {
    setIsBillingModalOpen(true);
  };
  
  const handleBillingModalClose = () => {
    setIsBillingModalOpen(false);
  };

  // For Authentication
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false); // Stop loading once the auth state is resolved
    });

    return () => unsubscribe();
  }, []);

  // For Window Resize
  const handleResize = useCallback(() => {
    if (window.innerWidth >= 600 && !isDesktop) {
      setIsDesktop(true);
    } else if (window.innerWidth < 600 && isDesktop) {
      setIsDesktop(false);
    }
  }, [isDesktop]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]); 

  if (loading) {
    return <div className='app'></div>; // Or some loading spinner
  }

  return (
    <div className='app'>
      <UserContext.Provider value={{ user, setUser }}>
        <DesktopContext.Provider value={{ isDesktop, setIsDesktop }}>
          <Router>
            {user && (isDesktop ? 
              <SideBar onPreferencesClick={handlePreferencesModalOpen} onBillingClick={handleBillingModalOpen} onCreateWorkspaceClick={handleCreateWorkspaceModalOpen} /> 
              : 
              <BottomNavBar onPreferencesClick={handlePreferencesModalOpen} onBillingClick={handleBillingModalOpen} />)}
            <PreferencesModal open={isPreferencesModalOpen} onClose={handlePreferencesModalClose} />
            <BillingModal open={isBillingModalOpen} onClose={handleBillingModalClose} />
            <CreateWorkspaceModal open={isCreateWorkspaceModalOpen} onClose={handleCreateWorkspaceModalClose}/>
            <div className={isDesktop ? 'page' : 'page-mobile'}>
              <Routes>
                <Route path="/" element={user ? <HomePage /> : <Navigate to="/login" />} />
                <Route path="/discovery" element={user ? <DiscoveryPage /> : <Navigate to="/login" />} />
                <Route path="/creator" element={user ? <CreatorPage /> : <Navigate to="/login" />} />
                <Route path="/campaigns" element={user ? <CampaignListPage /> : <Navigate to="/login" />} />
                <Route path="/campaigns/:campaignName" element={user ? <CampaignPage /> : <Navigate to="/login" />} />
                <Route path="/campaigns/:campaignName/:socialAccountHandle" element={user ? <OfferPage /> : <Navigate to="/login" />} />
                <Route path="/library" element={user ? <LikesPage /> : <Navigate to="/login" />} />
                <Route path="/login" element={!user ? <LoginPage /> : <Navigate to="/" />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </div>
          </Router>
        </DesktopContext.Provider>
      </UserContext.Provider>
    </div>
  );
};

export default App;
