import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { InputBase, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faTiktok, faYoutube, faTwitch } from '@fortawesome/free-brands-svg-icons';
import SegmentedButtons, { ButtonProp } from '../../Buttons/SegmentedButtons/SegmentedButtons';

import styles from './SearchBar.module.css';


const SearchBar: React.FC = () => {
    const navigate = useNavigate();

    const [creator, setCreator] = useState('');
    const [selectedSocial, setSelectedSocial] = useState('instagram');
    const [placeholderIndex, setPlaceholderIndex] = useState(0);

    const placeholders = ['emmachamberlain', 'danielarsham', 'haleyybaylee', 'valerialipovetsky', 'callummullin',  'visualatelier8'];

    useEffect(() => {
        const interval = setInterval(() => {
            setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [placeholders.length]);

    const buttons: ButtonProp[] = [
        {
            value: 'instagram',
            label: 'Instagram',
            icon: faInstagram
        },
        {
            value: 'tiktok',
            label: 'TikTok',
            icon: faTiktok
        },
        {
            value: 'youtube',
            label: 'YouTube',
            icon: faYoutube
        },
        // {
        //     value: 'twitch',
        //     label: 'Twitch',
        //     icon: faTwitch
        // },
    ];

    const onSearchPress = async () => {
        if (creator) {
            const cleanedCreator = creator.trim().toLowerCase();
            navigate('/creator', { state: { selectedSocial, cleanedCreator } });
        }
    };

    return (
        <div className={styles.mainSearchBar}>
            <div className={styles.searchBar}>
                <span className={styles.searchBarAtHandle}>@</span>
                <InputBase
                    className={styles.searchBarInput}
                    placeholder={placeholders[placeholderIndex]}
                    inputProps={{ 'aria-label' : 'search creator' }}
                    value={creator}
                    onChange={(e) => setCreator(e.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter')
                            onSearchPress()
                    }}
                />
               <IconButton
                    className={styles.searchBarButton}
                    aria-label="search"
                    onClick={onSearchPress}>
                    <FontAwesomeIcon icon={faSearch} />
                </IconButton>
            </div>
            <SegmentedButtons buttons={buttons} onValueChange={setSelectedSocial} />
        </div>
    );
};

export default SearchBar;
