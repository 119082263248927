import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faHeartSolid, faX } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartOutline } from '@fortawesome/free-regular-svg-icons';
import { faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import ColorSmallCard from '../../Cards/ColorSmallCard/ColorSmallCard';

import { getUserReport, getReport, DiscoveryReportData, ReportData } from '../../../Utils/Reports/processReportData';

import '../../../Styles/globalColors.css';
import './DiscoveryCard.css';
import { formatNumber } from '../../../Utils/Reports/formatNumber';
import { deleteReport, updateUserReport } from '../../../Utils/User/updateUserReport';

interface DiscoveryCardProps {
    discoveryReportData: DiscoveryReportData | ReportData;
    token?: string | null;
    onDelete?: (userReportId: number) => void;
}

const DiscoveryCard: React.FC<DiscoveryCardProps> = ({ discoveryReportData, token, onDelete }) => {
    const [isPickerVisible, setIsPickerVisible] = useState(false);
    const [base64Image, setBase64Image] = useState<string | null>(null);
    const navigate = useNavigate();

    const report = discoveryReportData.report;
    const userReport = discoveryReportData.userReport;
    const [userReportId, setUserReportId] = useState(userReport?.userReportId);
    const [isLiked, setIsLiked] = useState(userReport?.likedReport);

    // useEffect(() => {
    //     if (report?.profilePictureUrl) {
    //         getImageAsBase64(report.profilePictureUrl)
    //             .then((base64String) => {
    //                 setBase64Image(`data:image/jpeg;base64, ${base64String}`);
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             });
    //     }
    // }, [report?.profilePictureUrl]);    


    if (!discoveryReportData) {
        return (
            <div className='socialProfileHeaderContainer'>
                <h2>No Report Data Available</h2>
            </div>
        );
    };

    const titleColor = 'white';

    const profilePic = report?.profilePictureUrl;
    const handle = report?.accountHandle || 'error :/';
    const followers = report? formatNumber(report.numberOfFollowers) : formatNumber(0);
    const impRate = report?.avgImpressionRate || 0.0;
    const engRate = report?.avgEngagementRate || 0.0;
    const postsPerMonth = report?.postsPerMonth || 1;

    let socialType = '';

    if ('reportCategory' in discoveryReportData) {
    socialType = discoveryReportData.reportCategory?.type || '';
    } else if ('userReport' in discoveryReportData) {
    socialType = discoveryReportData.userReport.reportType;
    }
    function getSocialTypeIcon(socialType: string): any {
        switch (socialType) {
            case 'instagram':
                return faInstagram;
            case 'tiktok':
                return faTiktok;
            case 'youtube':
                return faYoutube;
            default:
                return faInstagram;
        }
    }
    const handleIsLiked = async (event: React.MouseEvent) => {
        if (!token) {
            console.error("Failed to retrieve ID token");
            return;
        }
        const updatedReport = await updateUserReport(userReportId, !isLiked, token)  // !isLiked to indicate the change
            .then((data: any) => {
                setIsLiked(data.updatedUserReport.likedReport);  // Toggle isLiked
            })
            .catch((error: any) => {
                console.error("Failed update user report isLiked:", error);
            });
    };

    const handleDelete = async (event: React.MouseEvent) => {
        event.stopPropagation();
        if (!token || !userReportId) {
          console.error("Failed to retrieve ID token or userReportId is undefined");
          return;
        };
        if (onDelete) {
            try {
                await deleteReport(userReportId, token);
                onDelete(userReportId); // Use the onDelete prop here
                } catch (error: any) {
                console.error("Failed to delete report:", error);
                }
            }
        };      

    const handleClick = () => {
        navigate('/creator', { state: { discoveryReportData } });
    };

    return (
        <div className='discovery-card'>
            {userReportId && <FontAwesomeIcon
                icon={isLiked ? faHeartSolid : faHeartOutline as IconProp}
                size='xl'
                className='discoveryHeart'
                onClick={handleIsLiked}
            />}
            {userReportId && <FontAwesomeIcon
                icon={faX as IconProp}
                // size='md'
                className='discoveryDelete'
                onClick={handleDelete}
            />}
            <div className='discovery-card-background' onClick={handleClick}>
                <div className='discovery-card-profile-pic-container'>
                    <img src={profilePic} className='discovery-card-profile-pic' loading='lazy' />
                    <div className='discovery-card-title-container'>
                        <div className='discovery-card-title' style={{color: titleColor}}>
                            <FontAwesomeIcon icon={getSocialTypeIcon(socialType)} color={titleColor} className='discovery-card-icon'/>
                            <span>{handle}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='discovery-card-background-container'>
                <ColorSmallCard num={followers} title="followers" />
                <ColorSmallCard num={impRate} title="impression rate" isPercentage={true} />
                <ColorSmallCard num={engRate} title="engagement rate" isPercentage={true} />
                <ColorSmallCard num={postsPerMonth} title="posts per month" />
            </div>
        </div>
    );
};

export default DiscoveryCard;
