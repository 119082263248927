import React, { useState, useEffect } from 'react';
import ColorSmallCard from '../../Cards/ColorSmallCard/ColorSmallCard';

import { format } from 'date-fns';
import { Post, toUnixTimestamp } from '../../../Utils/Reports/processReportData';
import { getImageAsBase64 } from '../../../Utils/Images/getImageBase64';

import '../../../Styles/globalColors.css';
import "./PostDisplayVertical.css";
import TrianglesLoader from '../../Loaders/TrianglesLoader/TrianglesLoader';
  
  const PostDisplayVertical: React.FC<Post> = ({ 
    estPrice, 
    impressions, 
    engagements, 
    displayUrl, 
    videoUrl, 
    estimated, 
    isSponsoredPost, 
    timeData, 
    postLink, 
    caption, 
    postId
    }) => {
    const [base64Image, setBase64Image] = useState<string | undefined>(undefined);

    const postDate = format(new Date(toUnixTimestamp(timeData.timestamp)), 'MMM d, yyyy')

    useEffect(() => {
        getImageAsBase64(displayUrl, postId)
            .then((base64String) => {
                setBase64Image(`data:image/jpeg;base64, ${base64String}`);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [displayUrl]);

    return (
        <div>
            <button className='post-display-vertical'>
                {
                    base64Image ?
                    <img src={base64Image} alt="Media" className='media-vertical'/>
                    :
                    <TrianglesLoader />
                }
                <div className='post-metrics-date-group-vertical'>
                    <span>{postDate}</span>
                    <div className="post-metrics-vertical">
                        <ColorSmallCard num={estPrice} topTitle="value" type='value' isCurrency={true} />
                        <ColorSmallCard num={impressions} topTitle="impressions" type='imp' />
                        <ColorSmallCard num={engagements} topTitle="engagements" type='eng' />
                    </div>
                </div>
            </button>
        </div>
    );
}

export default PostDisplayVertical;
