import React, { useState } from 'react';
import StandardTextInput from '../../Inputs/StandardTextInput/StandardTextInput';
import PasswordInput from '../../Inputs/PasswordInput/PasswordInput';
import MainButton from '../../Buttons/MainButton/MainButton';

import { auth } from '../../../firebase';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import { SERVER_URLS } from '../../../constants';

import axios from 'axios';

import './SignUpOption.css';

interface SignUpProps {
    style?: React.CSSProperties;
}

const SignUp: React.FC<SignUpProps> = ({ style }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [displayName, setDisplayName] = useState("");

    const sendUserData = async (user: any, displayName: string, isCreator: boolean = true) => {
        if(user) {
            const data = {
                user_id: user.uid,
                email: user.email,
                name: displayName,
                isCreator: isCreator,
            };
            const idToken = await user.getIdToken(true);
            console.log("JWT:", idToken);
            const url = SERVER_URLS.BACKEND_URL+'/valuence/api/getOrCreateUser';
            try {
                const response = await axios.post(
                    url,
                    data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${idToken}`
                        },
                    }
                );
                console.log("Data sent successfully to /getOrCreateUser: ", response.data);
            } catch (error) {
                console.log("Error occurred while sending data to /getOrCreateUser: ", error);
            }
        }
    };

    const handleGoogleSignUp = async (response: any) => {
        const provider = new GoogleAuthProvider();

        try {
          const { user } = await signInWithPopup(auth, provider);
          sendUserData(user, "displayName");
          console.log('Signed up with Google!');
        } catch (error) {
          console.error(error);
        }
      }
    

    const handleSignUp = async () => {
        try {
            const { user } = await createUserWithEmailAndPassword(auth, email, password);
            sendUserData(user, displayName);
            console.log(displayName);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='SignUpOption'>
            <StandardTextInput value={displayName}
                onChangeText={setDisplayName}
                placeholder='Name'
               />
            <StandardTextInput value={email}
                onChangeText={setEmail}
                placeholder='Email address'
               />
            <PasswordInput value={password} onChangeText={setPassword}/>
            <MainButton title="Sign up" onPress={handleSignUp}/>
            <MainButton title="Sign up with Google" onPress={handleGoogleSignUp} googleIcon={true}/>
        </div>
    );
};

export default SignUp;
