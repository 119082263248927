import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getReport } from '../../Utils/Reports/getReport';
import SocialProfileHeader from '../../Components/Report/SocialProfileHeader/SocialProfileHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReportMetricsContainer from '../../Components/Report/ReportMetricsContainer/ReportMetricsContainer';
import PostDisplayList from '../../Components/Posts/PostDisplayList/PostDisplayList';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import BouncingBalls from '../../Components/Loaders/BallsLoader/BouncingBalls';

import { UserContext } from '../../App';
import { debounce } from 'lodash';

import '../../Styles/globalColors.css'
import styles from './CreatorPage.module.css';
import { ReportData } from '../../Utils/Reports/processReportData';
import DiscoveryManager from '../../Components/Discovery/DiscoveryManager/DiscoveryManager';
import ReportOfferBuilder from '../../Components/Report/ReportOfferBuilder/ReportOfferBuilder';

type CreatorPageProps = {
  reportFromUserReports?: ReportData;
};

const CreatorPage: React.FC<CreatorPageProps> = ({ reportFromUserReports }) => {
  const userContext = useContext(UserContext);
  if (!userContext) throw new Error("You probably forgot to provide the UserContext value");

  const { user, setUser } = userContext;
  const userId = user?.uid;
  const idToken = user?.getIdToken(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [reportData, setReportData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [socialAccountId, setSocialAccountId] = useState<string>("");
  const [socialType, setSocialType] = useState<string>("");

  const fetchReportData = useMemo(() => debounce(async () => {
    const executeFetch = async (selectedSocial: string, cleanedCreator: string) => {
      if (!idToken) {
        console.error("Failed to retrieve ID token");
        return;
      }
      setLoading(true);
      try {
        const data = await getReport(selectedSocial, cleanedCreator, userId, await idToken);
        setReportData(data);
        if(data.report.instagramAccountId != null){
          setSocialAccountId(data.report.instagramAccountId);
          setSocialType("instagram");
        }
        else if(data.report.tiktokAccountId != null){
          setSocialAccountId(data.report.tiktokAccountId);
          setSocialType("tiktok");
        }
        else if(data.report.youtubeAccountId != null){
          setSocialAccountId(data.report.youtubeAccountId);
          setSocialType("youtube");
        }
      } catch (error) {
        console.error("Failed to fetch report:", error);
      } finally {
        setLoading(false);
      }
    };
  
    const state = location.state;
    let selectedSocial, cleanedCreator;
  
    if (state) {
      if (state.reportData && state.reportData.userReport) {
        selectedSocial = state.reportData.userReport.reportType;
        cleanedCreator = state.reportData.report.accountHandle;
      } else if (state.discoveryReportData && state.discoveryReportData.reportCategory) {
        selectedSocial = state.discoveryReportData.reportCategory.type;
        cleanedCreator = state.discoveryReportData.report.accountHandle;
      } else if (state.discoveryReportData && state.discoveryReportData.userReport) {
        selectedSocial = state.discoveryReportData.userReport.reportType;
        cleanedCreator = state.discoveryReportData.report.accountHandle;
      } else {
        selectedSocial = state.selectedSocial;
        cleanedCreator = state.cleanedCreator;
      }
  
      console.log("Fetching report data due to changes in location.state", state);
      executeFetch(selectedSocial, cleanedCreator);
    }
  }, 300), [location.state]);
  

  useEffect(() => {
    fetchReportData();

    return () => fetchReportData.cancel(); // Cancel the debounce if the component unmounts
  }, [fetchReportData]);

  const length = reportData?.posts.filter(function(post: { estimated: boolean; }){
    return post.estimated;
  }).length;

  console.log(length)

  return (
    <div className={styles.mainCreator}>
      <div style={{ display: 'flex', width: '100%' }}>
        <button onClick={() => navigate(-1)} className={styles.backButton}>
          <FontAwesomeIcon icon={faChevronLeft} />
          Go Back
        </button>
      </div>
      {loading ?
        <div className={styles.loaderContainer}>
          <BouncingBalls />
          <span>We're building your report with the latest data</span>
          <span>This may take up to 30 seconds</span>
        </div>
        :
        <div className={styles.reportContainer}>
          {
            reportData ?
              <div className={styles.reportContainer}>
                <SocialProfileHeader reportData={reportData} extraInfoVisible={true} />
                {/* {socialAccountId && socialType != "" ? 
                  <DiscoveryManager socialAccountId={socialAccountId} type={socialType}/>
                  :
                  null
                } */}
                <ReportMetricsContainer reportData={reportData} />
                <ReportOfferBuilder reportData={reportData} />
                <PostDisplayList reportData={reportData} />
              </div>
              :
              'This creator may not exist.  Please make sure their username is correct and that their profile is public'}
        </div>
      }
    </div>
  );
};

export default CreatorPage;