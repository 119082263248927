import React, { useState, useContext, useRef, useEffect, useCallback  } from 'react';
import InteractiveChart, { ChartDataPoint } from '../../../Charts/InteractiveChart/InteractiveChart';
import ReportOfferBuilder from '../../../Report/ReportOfferBuilder/ReportOfferBuilder';

import '../../../../Styles/globalColors.css'
import styles from './CampaignOfferBuilderAndChart.module.css';

import { UserContext } from '../../../../App';

import { ReportData } from '../../../../Utils/Reports/processReportData';
import { CampaignOffer } from '../../../../Utils/Campaigns/processCampaigns';
import { generatePostCountPerMonth } from '../../../../Utils/CampaignOffers/generatePostDatesAndCounts';
import { parse } from 'date-fns';
import { Post, generatePostListFromDateList } from '../../../../Utils/Posts/processPosts';

type ToggleButtonProps = {
    title: string;
    active: boolean;
    onPress: () => void;
    color: string;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({ title, active, onPress, color }) => (
    <button
        onClick={onPress}
        className={`${styles.chartButton} ${active ? styles.activeChartTab : ''}`}
        style={{ '--color': color } as React.CSSProperties} >
        {title}
    </button>
);

interface CampaignOfferBuilderAndChartProps {
    offerData: CampaignOffer;
    reportData: ReportData;
    isSubComponent?: boolean;
    onOfferUpdated?: (updatedOffer: CampaignOffer) => void;
}

const CampaignOfferBuilderAndChart: React.FC<CampaignOfferBuilderAndChartProps> = ({ 
    reportData,
    offerData,
    onOfferUpdated,
    isSubComponent = false 
    }) => {
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");

    const [isEditMode, setIsEditMode] = useState<boolean>(true);

    const handleClickEdit = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsEditMode(!isEditMode);
    }

    const today = new Date();
    const todayPlus1Year = new Date(today);
    todayPlus1Year.setDate(todayPlus1Year.getDate() + 365);

    const [active, setActive] = useState(0);
    const indicatorPosition = useRef(active * 100);
    const [scatterPlotBudgetData, setScatterPlotBudgetData] = useState<ChartDataPoint[]>([]);
    const [linePlotBudgetData, setLinePlotBudgetData] = useState<ChartDataPoint[]>([]); // TO DO: fetch DB Posts and set Budget data
    const [linePlotActualData, setLinePlotActualData] = useState<ChartDataPoint[]>([]); // TO DO: fetch DB Posts and set Actual data
    const [offerDateList, setOfferDateList] = useState<Date[]>([(new Date())]);
    const [startDate, setStartDate] = useState<Date>(today);
    const [endDate, setEndDate] = useState<Date>(todayPlus1Year);
    const [posts, setPosts] = useState<Post[]>([])

    useEffect(() => {
        indicatorPosition.current = active * 100;
    }, [active]);

    // Formatting list of post dates returned by ReportOfferBuilder
    // and pass formatted data to InteractiveChart
    const formatInteractiveChartData = (dateList: Date[], exptectedMetric: number, campaignOffer: CampaignOffer) => {
        const postCountPerMonth = generatePostCountPerMonth(dateList);
        const postList = generatePostListFromDateList(dateList, campaignOffer);
        const scatterPlotData = dateList.map(date => ({
            x: date,
            y: 0,
            label: date
        }));
        setScatterPlotBudgetData(scatterPlotData);

        const linePlotData = postCountPerMonth.map(month => ({
            x: parse(month.x, "MMMM yyyy", new Date()),
            y: exptectedMetric * month.y,
            label: parse(month.x, "MMMM yyyy", new Date())
        }));
        setLinePlotBudgetData(linePlotData);
        setStartDate(startDate);
        setEndDate(endDate)
        setPosts(postList);
    };

    const campaignOfferImpressions = offerData.expectedImpressionsLow;
    const campaignOfferEngagements = offerData.expectedEngagementsLow;
    const avgPostPrice = reportData.report.postPrice
    const metricExpected = [avgPostPrice, campaignOfferImpressions, campaignOfferEngagements]
    const colorsScatterExpected = ['rgba(57, 87, 78, 1)', 'rgba(152, 77, 105, 1)', 'rgba(64, 115, 135, 1)'];
    const colorsExpected = ['rgba(111, 174, 156, 0.48)', 'rgba(230, 128, 165, 0.48)', 'rgba(97, 130, 161, 0.48)'];
    const colorsActuals = ['rgba(111, 174, 156, 0.96)', 'rgba(230, 128, 165, 0.96)', 'rgba(97, 130, 161, 0.96)'];
    const dollarSign = [true, false, false];
    const chartButtonTitles = ['Post value', 'Impressions', 'Engagements']
    const legendScatterExpected = ["Budgeted", ""]


    // Via the callback function below:
    // - ReportOfferBuilder will set the date list on page load, and on 'postCount' and 'storyCount' change
    // - InteractiveChart will return the updated date list ('dragEnd' event) back to this component, 
    //   the latter will pass the updated and formatted data back to InteractiveChart to display
    const handleOfferDateListChange = useCallback((newOfferDateList: Date[], startDate: Date, endDate: Date) => {
        formatInteractiveChartData(newOfferDateList, metricExpected[active], offerData);
        setStartDate(startDate);
        setEndDate(endDate);
        }, [metricExpected[active]]);


    if (!offerData) {
        return (
            <div className={styles.campaignOfferContainer}>
                <h2>No Report Data Available</h2>
            </div>
        );
    };

    const isMobile = (window.innerWidth < 650) ? true : false;

    return (
        <div className={isSubComponent ? styles.subComponentCampaignOffer : styles.campaignOfferContainer}>
            {isMobile === true && <span className='section-subtitle' style={{ margin: '0px 0 4px' }}>Performance</span>}
            <div className={styles.bottomCard} style={isMobile ? {marginBottom: '1.5em'} : {}}>
                <div className={styles.chartButtonGroup}>
                    {isMobile === false && <h2 className='section-subtitle'>Performance</h2>}
                    {chartButtonTitles.map(( title, index) => (
                    <ToggleButton
                        key={index}
                        title={title}
                        active={active === index}
                        onPress={() => setActive(index)}
                        color={colorsActuals[index]}
                    />
                    ))}
                </div>
                <div className={styles.chartContainer}>
                    <InteractiveChart
                        datasetScatterExpected={scatterPlotBudgetData}
                        datasetExpected={linePlotBudgetData}
                        // datasetActuals={linePlotBudgetData}
                        colorScatterExpected={colorsScatterExpected[active]}
                        colorExpected={colorsExpected[active]}
                        colorActuals={colorsActuals[active]}
                        labelOptionScatterExpected="Post date"
                        labelOptionExpected="Budget"
                        labelOptionActuals="Actuals"
                        dollarSign={dollarSign[active]}
                        startDate={startDate}
                        endDate={endDate}
                        hideScatterPlot
                        onOfferDateListChange={handleOfferDateListChange}
                    />
                </div>
            </div>
            {isEditMode && <ReportOfferBuilder 
                reportData={reportData} 
                isCondensed={true} 
                campaignOfferData={offerData}
                onOfferUpdated={onOfferUpdated}
                isSubComponent={true} 
                onOfferDateListChange={handleOfferDateListChange}
                postList={posts}
                />}
            {/* <button onClick={handleClickEdit}>Edit offer</button> */}
        </div>
    );
};

export default CampaignOfferBuilderAndChart;
