import { SERVER_URLS } from "../../constants";

export const updateUserReport = async (userReportId: number | undefined, isLiked:boolean | undefined, idToken: string | undefined) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${idToken}`)
  
    const raw = JSON.stringify({
      "userReportId": userReportId,
      "isLiked": isLiked
    });
  
    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow' as const
    };
  
    try {
      const response = await fetch(SERVER_URLS.BACKEND_URL+"/valuence/api/updateUserReportIsLiked", requestOptions);
      const result = await response.json();
      return result;
    } catch (error) {
      console.log('error in updateUserReport', error);
      return null;
    }
  };

  export const deleteReport = async (userReportId: number | undefined, idToken: string | undefined) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${idToken}`)
  
    const raw = JSON.stringify({
      "user_report_id": userReportId,
    });
  
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow' as const
    };
  
    try {
      const response = await fetch(SERVER_URLS.BACKEND_URL+"/valuence/api/deleteUserReport", requestOptions);
      const result = await response.json();
      return result;
    } catch (error) {
      console.log('error in updateUserReport', error);
      return null;
    }
  }
  
  