import React, { useState, useEffect, useContext, useCallback } from 'react';
import ColorSmallCard from '../../Cards/ColorSmallCard/ColorSmallCard';

import { UserContext } from '../../../App';
import { getReport, ReportData } from '../../../Utils/Reports/processReportData';
import { formatNumberOffer, formatNumber } from '../../../Utils/Reports/formatNumber';

import '../../../Styles/globalColors.css'
import '../../../Styles/globalTexts.css'
import styles from './ReportOfferBuilder.module.css';

import NumberInputControl from '../../Inputs/NumberInputControl/NumberInputControl';
import MainButton from '../../Buttons/MainButton/MainButton';
import DatePicker from '../../Inputs/DatePicker/DatePicker';
import { generateNewPost, generatePostDates } from '../../../Utils/CampaignOffers/generatePostDatesAndCounts';
import { CampaignOffer } from '../../../Utils/Campaigns/processCampaigns';
import { Alert } from '@mui/material';
import { updateCampaignOffer } from '../../../Utils/CampaignOffers/updateCampaignOffer';
import ModalCreateOffer from '../../Campaign/CampaignOffers/ModalCreateOffer/ModalCreateOffer';
import { Post } from '../../../Utils/Posts/processPosts';
import { createPosts } from '../../../Utils/Posts/createPosts';
import { getPostsForCampaignOfferId } from '../../../Utils/Posts/getPostsForCampaignOfferId';
import { updatePosts } from '../../../Utils/Posts/updatePosts';
import { format } from 'date-fns';

interface ReportOfferBuilderProps {
    reportData: ReportData;
    isCondensed?: boolean;
    isModal?: boolean;
    campaignOfferData?: CampaignOffer;
    onOfferUpdated?: (updatedOffer: CampaignOffer) => void;
    isSubComponent?: boolean;
    onOfferDateListChange?: (offerDateList: Date[], startDate: Date, endDate: Date) => void;
    onOfferMetricsChange?: (
        offerBudget: number, 
        postCount: number, 
        storyCount: number, 
        offerCPI: number, 
        offerCPE: number, 
        expectedImpressionsLow: number, 
        expectedImpressionsHigh: number, 
        expectedEngagementsLow: number, 
        expectedEngagementsHigh: number,  
    ) => void;
    postList?: Post[];
    passedPostCount?: number;
    passedStoryCount?: number;
}

const ReportOfferBuilder: React.FC<ReportOfferBuilderProps> = ({ 
    reportData,
    isCondensed = false,
    isModal = false,
    campaignOfferData,
    onOfferUpdated,
    isSubComponent = false,
    onOfferDateListChange,
    onOfferMetricsChange,
    postList,
    passedPostCount,
    passedStoryCount
    }) => {

    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");
    const { user, setUser } = userContext;
    const [token, setToken] = useState<string | null>(null);

    const [selectedOfferHandle, setSelectedOfferHandle] = useState<string>("");
    const [openModalCreateOffer, setOpenModalCreateOffer] = useState<boolean>(false);

    const handleCreateCampaignModal = () => {
        setOpenModalCreateOffer(!openModalCreateOffer)
    }

    const handleCreateCampaignModalOpen = () => {
        setOpenModalCreateOffer(true);
    };

    const handleNewOfferCreated = () => {
        setOpenModalCreateOffer(false);
    }

    useEffect(() => {
        if (user) {
            user.getIdToken(true).then((fetchedToken) => {
                setToken(fetchedToken);
            });
        }
    }, [user]);

    const today = new Date();
    const todayPlus1Year = new Date(today);
    todayPlus1Year.setDate(todayPlus1Year.getDate() + 365);

    const report = getReport(reportData);
    const [postCount, setPostCount] = useState<number>(campaignOfferData?.postCount || passedPostCount || 1);
    const [storyCount, setStoryCount] = useState<number>(campaignOfferData?.storyCount || passedStoryCount || 1);
    const [CPI, setCPI] = useState(0);
    const [CPE, setCPE] = useState(0);
    const [offerValue, setOfferValue] = useState(0);
    const [daysCount, setDaysCount] = useState(365.1);
    const [startDate, setStartDate] = useState(today.getTime());
    const [endDate, setEndDate] = useState(todayPlus1Year.getTime());
    const [offerDateList, setOfferDateList] = useState<Date[]>([(new Date())]);
    const [storedPostList, setStoredPostList] = useState<Post[]>([]);
    const [udpatedStoredPostList, setUpdatedStoredPostList] = useState<Post[]>([]);
    const [newPostList, setNewPostList] = useState<Post[]>([]);
    const [avgOfferMonthlyPosts, setAvgOfferMonthlyPosts] = useState(0);
    const [isTooLarge, setIsTooLarge] = useState(false);
    const [saved, setSaved] = useState<boolean>(true);

    const getStoredPosts = useCallback(async () => {
        if (campaignOfferData && token) {
            const getPosts = await getPostsForCampaignOfferId(token, campaignOfferData.campaignOfferId) || [];
            setStoredPostList(getPosts.posts);
        }
    }, [campaignOfferData, token]); 

    useEffect(() => {
        getStoredPosts();
    }, [getStoredPosts]);
    
    useEffect(() => {
        const isPostCountUnchanged = postCount === (campaignOfferData?.postCount || passedPostCount);
        const isStoryCountUnchanged = storyCount === (campaignOfferData?.storyCount || passedStoryCount);

        setSaved(isPostCountUnchanged && isStoryCountUnchanged);
    }, [postCount, storyCount, campaignOfferData, passedPostCount, passedStoryCount]);

    const handle = report?.accountHandle;
    const avgPostPrice = report?.postPrice;
    const avgStoryPrice = report?.storyPrice;
    const avgPostsPerMonth = report?.postsPerMonth;

    const postLowEstImpressions = report?.postLowEstImpressions;
    const postAvgEstImpressions = report?.postAvgEstImpressions;
    const postHighEstImpressions = report?.postHighEstImpressions;
    const postLowEstEngagements = report?.postLowEstEngagements;
    const postAvgEstEngagements = report?.postAvgEstEngagements;
    const postHighEstEngagements = report?.postHighEstEngagements;

    const storyLowEstImpressions = report?.storyLowEstImpressions;
    // const storyAvgEstImpressions = report?.storyAvgEstImpressions;
    const storyHighEstImpressions = report?.storyHighEstImpressions;
    const storyLowEstEngagements = report?.storyLowEstEngagements;
    // const storyAvgEstEngagements = report?.storyAvgEstEngagements;
    const storyHighEstEngagements = report?.storyHighEstEngagements;

    const calculatedPosts = postCount * (avgPostPrice || 0);
    const calculatedStories = storyCount * (avgStoryPrice || 0);
    const calculatedTotal = Number(((calculatedPosts + calculatedStories) / 25).toFixed(0)) * 25;

    const calculatedPostLowImpressions = postCount * (postLowEstImpressions || 0);
    const calculatedPostLowEngagements = postCount * (postLowEstEngagements || 0);
    const calculatedPostHighImpressions = postCount * (postHighEstImpressions || 0);
    const calculatedPostHighEngagements = postCount * (postHighEstEngagements || 0);

    const calculatedStoryLowImpressions = storyCount * (storyLowEstImpressions || 0);
    const calculatedStoryLowEngagements = storyCount * (storyLowEstEngagements || 0);
    const calculatedStoryHighImpressions = storyCount * (storyHighEstImpressions || 0);
    const calculatedStoryHighEngagements = storyCount * (storyHighEstEngagements || 0);

    const calculatedTotalLowImpressions = (calculatedPostLowImpressions + calculatedStoryLowImpressions);
    const calculatedTotalLowEngagements = (calculatedPostLowEngagements + calculatedStoryLowEngagements);
    const calculatedTotalHighImpressions = (calculatedPostHighImpressions + calculatedStoryHighImpressions);
    const calculatedTotalHighEngagements = (calculatedPostHighEngagements + calculatedStoryHighEngagements);

    const handleDateChange = (range: { startDate: Date; endDate: Date }) => {
        const { startDate, endDate } = range;
        setStartDate(startDate.getTime()); setEndDate(endDate.getTime());
        const calculatedDaysCount = Math.ceil(Math.abs(startDate.getTime() - endDate.getTime()) / (1000 * 3600 * 24));
        setDaysCount(calculatedDaysCount);
    };

    useEffect(() => {
        const initialCPI = (avgPostPrice || 0) / (postAvgEstImpressions || 0);
        setCPI(initialCPI);
        const initialCPE = (avgPostPrice || 0) / (postAvgEstEngagements || 0);
        setCPE(initialCPE);
        const offerValue = calculatedTotal || 0;
        setOfferValue(offerValue);
        const calculatedPostsCountPerMonth = postCount / (daysCount / 30);
        setAvgOfferMonthlyPosts(calculatedPostsCountPerMonth);
        if (Math.round(((avgPostsPerMonth || 1) * 0.4)) <= avgOfferMonthlyPosts) {
            setIsTooLarge(true);
        }
        if ((Math.round(((avgPostsPerMonth || 1) * 0.4)) > avgOfferMonthlyPosts) || postCount <= 2) {
            setIsTooLarge(false);
        }

    }, [avgPostPrice, postAvgEstImpressions, postAvgEstEngagements, calculatedTotal, avgPostsPerMonth, avgOfferMonthlyPosts, daysCount, postCount]);

    useEffect(() => {
        onOfferMetricsChange?.(offerValue, postCount, storyCount, CPI, CPE, 
            calculatedTotalLowImpressions, calculatedTotalHighImpressions, calculatedTotalLowEngagements, calculatedTotalHighEngagements);
    }, [offerValue, postCount, storyCount, CPI, CPE, 
        calculatedTotalLowImpressions, calculatedTotalHighImpressions, calculatedTotalLowEngagements, calculatedTotalHighEngagements]);

    const filterOutStoredPosts = (posts: Post[]): Post[] => {
        return posts.filter(post => !post.hasOwnProperty('id'));
    }; // Only keeping new posts without an id

    const filterOutNewPosts = (posts: Post[]): Post[] => {
        return posts.filter(post => post.hasOwnProperty('id'));
    }; // Only keeping already stored posts with an id

    // Here we are creating the list of dates to display on the chart
    // We handle the 2 following cases:
    // - When no stored post associated to this offer has been passed
    // - When an array of posts has been passed and the user is adding or removing posts in preview mode
    useEffect(() => {
        if (startDate > 0 && endDate > 0 && postCount > 0 && isSubComponent) {
            const existingPostDates: Date[] = postList ? postList.map(post => new Date(post.date)) : [];
        
            // Here we make sure existingPostDates is sorted to find the latest date and assign it to generatePostDates
            const sortedExistingPostDates = existingPostDates.sort((a, b) => a.getTime() - b.getTime());

            // We adjust the existing dates if postCount is less than the length of postList
            let adjustedExistingDates: Date[] = [];
            if (sortedExistingPostDates.length > postCount) {
                adjustedExistingDates = sortedExistingPostDates.slice(0, postCount);
            } else {
                adjustedExistingDates = sortedExistingPostDates;
            }
    
            let netPostCountToGenerate = postCount - adjustedExistingDates.length;
    
            // Use the latest date timestamp from adjustedExistingDates as the new start date, if available
            const newStartDate = adjustedExistingDates.length > 0 ? 
                (adjustedExistingDates[adjustedExistingDates.length - 1].getTime() + (86400000 * 7 * 4))  // Adding four weeks to the latest date
                : 
                startDate + (86400000 * 7);
            const newGeneratedPostDates = generatePostDates(netPostCountToGenerate, newStartDate, endDate);
    
            // Combine the adjusted existing dates with any new dates needed
            const newOfferDateList: Date[] = [...adjustedExistingDates, ...newGeneratedPostDates];
            onOfferDateListChange?.(newOfferDateList, new Date(startDate), new Date(endDate));
            setOfferDateList(newOfferDateList);
        }
    }, [startDate, endDate, postCount, onOfferDateListChange]);

    useEffect(() => {
        // Assuming `offerDateList` is the updated list of dates received by
        // CampaignOfferBuilderAndChart's interactivechart, and to be mapped to `storedPostList` in order to update stored posts' dates
        const updatedPosts: Post[] = offerDateList.map((newDate, index) => {
            if (storedPostList && index < storedPostList.length) {
                // Update existing posts with new dates
                return { ...storedPostList[index], date: format(newDate, 'yyyy-MM-dd') };
            } else {
                // Create new post objects for additional dates - adjust this according to how you create new posts
                if (campaignOfferData) {
                    console.log("WE'RE CREATING A NEW POST GONZO")
                    console.log(newDate)
                    return generateNewPost(newDate, campaignOfferData, 'post');
                };
            }
        }).filter(post => post !== undefined) as Post[];
    
        // If offerDateList is shorter than storedPostList, decide whether to truncate storedPostList or handle differently
        if (storedPostList && offerDateList.length < storedPostList.length) {
            // Option 1: Truncate storedPostList to match offerDateList length
            const truncatedStoredPostList = storedPostList.slice(0, offerDateList.length);
            // setStoredPostList(truncatedStoredPostList);
    
            // Option 2: Handle the remaining posts in storedPostList differently
            // E.g., you could mark them as inactive, move them to a different list, etc.
        } else {
            // If offerDateList is longer or equal, update storedPostList with the new or additional posts
        }
    
        setUpdatedStoredPostList(filterOutNewPosts(updatedPosts));
        setNewPostList(filterOutStoredPosts(updatedPosts))
    }, [offerDateList, storedPostList]);

    const handleEditOffer = async () => {
        if (!token) {
            console.error("Failed to retrieve ID token");
            return;
        }

        if (reportData && campaignOfferData) {
            const updatedOffer = await updateCampaignOffer(
                user?.uid,
                token,
                campaignOfferData.campaignOfferId,
                reportData.report.accountHandle,
                reportData.report.profilePictureUrl,
                `temporaryCreatorUserId-${campaignOfferData.socialAccountId}-${campaignOfferData.socialAccountType}`,
                // ^this is temporary while we don't have any creatorUserId's yet, also should return null when creatorUserId non-existant yet?
                reportData?.report.accountHandle,
                'Lastname',
                campaignOfferData.campaignId,
                campaignOfferData.campaignName,
                offerValue,
                postCount,
                storyCount,
                campaignOfferData.offerCPI,
                campaignOfferData.offerCPE,
                calculatedTotalLowImpressions,
                calculatedTotalHighImpressions,
                calculatedTotalLowEngagements,
                calculatedTotalHighEngagements,
                ''
            );
            if (onOfferUpdated) {
                onOfferUpdated(updatedOffer);
            };
            if (postList) {
                if (udpatedStoredPostList.length > 0) {updatePosts(token, udpatedStoredPostList)};
                if (newPostList.length > 0) {createPosts(token, newPostList)};
                setSaved(true);
            }
        }
    }

    return (
        <div className={styles.offerBuilderTextWrapper} 
            style={(isModal || isSubComponent) ? 
                {padding: '0', width: '100%'} : isSubComponent ? 
                {borderRadius: 0, backgroundColor: 'transparent'} : 
                {}}>
            <div className={styles.offerBuilder} style={isModal ? {alignItems: 'start', gap: '3em'} : {}}>
                <div className={styles.offerBuilderInput}>     
                    {isModal === false &&
                        <span className={'section-subtitle'} style={{ marginBottom: '0.5em' }}>{ isCondensed === false ? 'Create offer' : 'Your offer'}</span>
                    }
                    <div className={styles.inputContainer}>
                        <div className={styles.inputColumn} style={isModal ? {width: 'max-content'} : {width: '100%'}}>
                            <div className={styles.inputRow}>
                                <NumberInputControl onChangeText={setPostCount} initialValue={postCount} inputTitle='Post' />
                                <NumberInputControl onChangeText={setStoryCount} initialValue={storyCount} inputTitle='Story' />
                            </div>
                            {/* <div className={styles.inputColumn}>
                                <NumberInputControl onChangeText={setCPI} initialValue={CPI} inputTitle='Cost per impression' isDollarValue={true}/>
                                <NumberInputControl onChangeText={setCPE} initialValue={CPE} inputTitle='Cost per engagement' isDollarValue={true}/>
                            </div> */}
                            {isModal === true && <DatePicker onDateChange={handleDateChange} daysCounter={(daysCount == 365.1) ? 0 : daysCount}/>}
                        </div>
                        <div className={styles.inputColumnButton}>
                            {isModal === true && <NumberInputControl onChangeText={setOfferValue} initialValue={(Number((offerValue*0.01).toFixed(0))*100)} inputTitle='Budget' isDollarValue={true} decimals={0} />}
                            {isModal === false &&
                                ( campaignOfferData ?
                                    <MainButton title={'Save changes'} onPress={handleEditOffer} color='var(--defaultGreen)' className={saved ? styles.mainButtonOfferSaved : styles.mainButtonOffer} />
                                    :
                                    <MainButton title={'Create offer'} onPress={handleCreateCampaignModal} color='var(--defaultGreen)' className={styles.mainButtonOffer} />
                                )
                            }
                        </div>
                    </div>
                </div>
                <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                    {isCondensed === false &&
                        <span className={`${styles.descriptionText} smallBody`}style={isModal ? {order: 2, margin: '6px 0 0 0'} : {}}>
                            Offer value for @{handle} based on their audience quality and expected content performance.
                        </span>}
                    <div className={styles.pricingOfferCard} style={isModal ? {order: 1} : {}}>
                        <div className={styles.pricingOfferTotalContainer}>
                            Offer value
                            <div className={styles.pricingOfferTotalValue}>
                                ${formatNumberOffer(calculatedTotal)}
                            </div>
                        </div>
                        <div className={`${styles.pricingOfferCardContainerSub} ${styles.metricsImp}`}>
                            <ColorSmallCard
                                num={`${formatNumber(calculatedTotalLowImpressions)} - ${formatNumber(calculatedTotalHighImpressions)}`}
                                title="impressions" />
                            <ColorSmallCard
                                num={`$${(CPI.toFixed(3))}`}
                                title="per impression" />
                        </div>
                        <div className={`${styles.pricingOfferCardContainerSub} ${styles.metricsEng}`} >
                            <ColorSmallCard
                                num={`${formatNumber(calculatedTotalLowEngagements)} - ${formatNumber(calculatedTotalHighEngagements)}`}
                                title="engagements" />
                            <ColorSmallCard
                                num={`$${(CPE.toFixed(2))}`}
                                title="per engagement" />
                        </div>
                    </div>
                </div>
            </div>
            {isTooLarge &&
                // <span className={styles.warningText}>
                //     The amount of posts may be too high for the given date range and @{handle}'s current posting frequency, or may reduce their audience receptiveness to paid content.
                //     <br></br>
                //     Try reducing the number of posts or extending the date range.
                // </span>
                <Alert severity="warning" className={styles.warningText}>
                    <b>Try reducing the number of posts or extending the date range</b>
                    <br></br>
                    The number of posts may be too high for the given date range based on @{handle}'s posting frequency, and may reduce their audience's receptiveness to paid content.
                </Alert>
            }
            <ModalCreateOffer 
                open={openModalCreateOffer} 
                onClose={handleCreateCampaignModal}
                reportData={reportData}
                onOfferCreated={handleNewOfferCreated}
                passedPostCount={postCount}
                passedStoryCount={storyCount} />
        </div>
    );
};

export default ReportOfferBuilder;
