import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Modal } from '@mui/material';
import StandardTextInput from '../../../Inputs/StandardTextInput/StandardTextInput';
import CurrencyDropdown from '../../../Inputs/CurrencyDropdown/CurrencyDropdown';

import { UserContext } from '../../../../App';
import { fetchUserReports } from '../../../../Utils/Reports/fetchUserReports';
import { debounce } from 'lodash';

import { createCampaignOffer } from '../../../../Utils/CampaignOffers/createCampaignOffer';
import { ReportData } from '../../../../Utils/Reports/processReportData';

import styles from './ModalCreateOffer.module.css'
import './../../../../Styles/globalColors.css'

import MainButton from '../../../Buttons/MainButton/MainButton';
import ReportOfferBuilder from '../../../Report/ReportOfferBuilder/ReportOfferBuilder';
import AutocompleteTextField from '../../../Inputs/AutocompleteTextField/AutocompleteTextField';
import SocialProfile from '../../../SocialProfile/SocialProfile/SocialProfile';
import ColorMediumCard from '../../../Cards/ColorMediumCard/ColorMediumCard';
import { Campaign, CampaignOffer } from '../../../../Utils/Campaigns/processCampaigns';
import { getCampaignListWithAdminUser } from '../../../../Utils/Campaigns/getCampaignListWithAdminUser';
import TrianglesLoader from '../../../Loaders/TrianglesLoader/TrianglesLoader';
import { createCampaign } from '../../../../Utils/Campaigns/createCampaign';


interface ModalCreateOfferProps {
    open: boolean;
    onClose?: () => void;
    offerHandle?: string;
    campaignId?: number;
    campaignName?: string;
    reportData?: ReportData;
    campaignOfferData?: CampaignOffer;
    onOfferCreated?: (newOffer: any) => void;
    passedPostCount?: number;
    passedStoryCount?: number;
}

const ModalCreateOffer: React.FC<ModalCreateOfferProps> = ({ 
    open, 
    onClose, 
    offerHandle, 
    campaignId, 
    campaignName, 
    reportData,
    onOfferCreated,
    passedPostCount,
    passedStoryCount
    }) => {
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");
    const { user, setUser } = userContext;
    const [token, setToken] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedCampaignId, setSelectedCampaignId] = useState<number>(campaignId || 0);
    const [selectedCampaignName, setSelectedCampaignName] = useState<string>(campaignName || '');

    const [selectedOfferHandle, setSelectedOfferHandle] = useState<string>("");

    const [userReports, setUserReports] = useState<ReportData[]>([]);
    const [selectedReportData, setSelectedReportData] = useState<ReportData | null>(reportData || null);
    const [offerMetrics, setOfferMetrics] = useState({
        offerBudget: 0,
        postCount: 0,
        storyCount: 0,
        offerCPI: 0,
        offerCPE: 0,
        expectedImpressionsLow: 0,
        expectedImpressionsHigh: 0,
        expectedEngagementsLow: 0,
        expectedEngagementsHigh: 0,
    });

    useEffect(() => {
        if (offerHandle) {
            setSelectedOfferHandle(offerHandle)
        };
        if (user) {
            user.getIdToken(true).then((fetchedToken) => {
                setToken(fetchedToken);
            });
        }
    }, [user]);

    
    // Section below is in case the user wants to create an offer from a report, with no campaign passed to this component
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [newCampaignName, setNewCampaignName] = useState<string>('');

    const campaignNameList = campaigns.map((campaign: Campaign, index: any) => ({
        title: campaign.campaignName,
        id: campaign.campaignId
    }))

    const fetchCampaigns = () => {
        if (!token) {
            console.error("Failed to retrieve ID token");
            return;
        };

        getCampaignListWithAdminUser(user?.uid, token)
            .then((campaignList: any) => {
                setCampaigns(campaignList.reverse())})
    }

    useEffect(() => {
        if (user?.uid && token && !campaignId) { // only triggered if campaign is not passed to this component
            fetchCampaigns()
        }
    }, [user?.uid, token])

    const handleCreateCampaign = () => {
        if (!token) {
            console.error("Failed to retrieve ID token");
            return;
        }

        setLoading(true);

        if (newCampaignName != '') {
            createCampaign(user?.uid, token, newCampaignName)
            .then((data) => {
                setSelectedCampaignId(data.campaign.campaignId);
                setSelectedCampaignName(data.campaign.campaignName);
            });
        } else {
            console.error("No campaign name");
        }

        setLoading(false);
    }
    // End of campaign related section


    const fetchReports = async () => {
        if (!token) {
            console.error("Failed to retrieve ID token");
            return;
        }
        fetchUserReports(user?.uid, token)
            .then((data) => {
                setUserReports(data.reverse());
            })
            .catch((error) => {
                console.error("Failed to fetch report:", error);
            });
    };

    const getUserReports = useCallback(debounce(fetchReports, 300), [token, user]);

    useEffect(() => {
        if (user?.uid && token) {
            // ADD TRY CATCH IF USER HAS NO USER REPORTS
            getUserReports();
        }

        return () => getUserReports.cancel(); // Cancel the debounce if the component unmounts
    }, [getUserReports, user?.uid, token]);

    const handleList = userReports.map((reportData: ReportData, index: any) => ({
        title: reportData.report.accountHandle
    }));

    const handleSocialProfileSelection = (reportData: ReportData | null) => {
        setSelectedReportData(reportData);
    };

    const handleOfferMetricsChange = (
        offerBudget: number, 
        postCount: number, 
        storyCount: number, 
        offerCPI: number, 
        offerCPE: number, 
        expectedImpressionsLow: number, 
        expectedImpressionsHigh: number, 
        expectedEngagementsLow: number, 
        expectedEngagementsHigh: number, 
    ) => {
        console.log("here");
        setOfferMetrics({
            offerBudget, postCount, storyCount, offerCPI, offerCPE,
            expectedImpressionsLow, expectedImpressionsHigh,
            expectedEngagementsLow, expectedEngagementsHigh
        });
    };
    
    const handleCreateOffer = async () => {
        if (!token) {
            console.error("Failed to retrieve ID token");
            return;
        }

        if (selectedReportData) {
            createCampaignOffer(
                user?.uid,
                token,
                selectedReportData?.userReport.reportSocialAccountId,
                selectedReportData?.report.accountHandle,
                selectedReportData?.userReport.reportType,
                selectedReportData?.report.profilePictureUrl,
                `temporaryCreatorUserId-${selectedReportData?.userReport.reportSocialAccountId}-${selectedReportData?.userReport.reportType}`,
                // ^this is temporary while we don't have any creatorUserId's yet, also should return null when creatorUserId non-existant yet?
                selectedReportData?.report.accountHandle,
                'Lastname',
                // ^Same thing for the creator's first and last names here
                selectedCampaignId,
                selectedCampaignName,
                offerMetrics.offerBudget, 
                offerMetrics.postCount, 
                offerMetrics.storyCount, 
                offerMetrics.offerCPI, 
                offerMetrics.offerCPE,
                offerMetrics.expectedImpressionsLow, 
                offerMetrics.expectedImpressionsHigh,
                offerMetrics.expectedEngagementsLow, 
                offerMetrics.expectedEngagementsHigh,
                ''
            ).then(response => {
                if (response.campaignOffer) {
                    onOfferCreated?.(response.campaignOffer); // Call the callback with the campaignOffer response
                }
            })
        }
    }

    return (
        <Modal open={open} onClose={onClose} className={styles.background}>
            <div className={styles.modalContainer}>
                <span className={styles.title}>New Offer</span>
                <div className={styles.profileInputs}>
                    {!reportData && <AutocompleteTextField 
                        widthCSS={(selectedReportData && selectedReportData !== null) ? '100%' : 300 }
                        placeholder='Select creator'
                        textTip='Invite'
                        optionList={handleList}
                        onValueChange={() => setSelectedOfferHandle('')} 
                        providedRenderOption={
                            (props, option) => {
                                const report = userReports.find(r => r.report.accountHandle === option.title);
                                if (report) {
                                return <li><SocialProfile 
                                    key={option.title} 
                                    {...props} 
                                    reportData={report} 
                                    picVisible={true}
                                    openOnClick={false} 
                                    handleOnClick={() => handleSocialProfileSelection(report)} 
                                /></li>;
                                }
                                return null;
                            }
                        } 
                        providedRenderInput={
                            (selectedReportData && selectedReportData !== null) && 
                            <div className={styles.selectReportShowcase}>
                                <SocialProfile 
                                    reportData={selectedReportData} 
                                    openOnClick={false} 
                                    picVisible 
                                    handleOnClick={() => handleSocialProfileSelection(null)}
                                    showUnselect={true}
                                />
                                <div className={styles.socialProfileHeaderMetrics}>
                                    <ColorMediumCard num={selectedReportData.report.numberOfFollowers} topTitle="followers" type='followers'/>
                                    <ColorMediumCard num={selectedReportData.report.avgImpressionRate} topTitle="imp. rate" type='imp' isPercentage={true}/>
                                    <ColorMediumCard num={selectedReportData.report.avgEngagementRate} topTitle="eng. rate" type='eng' isPercentage={true} />
                                    <ColorMediumCard num={selectedReportData.report.postPrice} topTitle="post value" type='value' isCurrency={true} />
                                </div>
                            </div>
                        }
                    />}
                    {selectedReportData && <ReportOfferBuilder reportData={selectedReportData} passedPostCount={passedPostCount} passedStoryCount={passedStoryCount} isModal={true} onOfferMetricsChange={handleOfferMetricsChange} />}
                </div>
                {loading ? 
                    <TrianglesLoader className={styles.loader}/>
                    :
                    <div className={styles.mainButtonRow}>
                        {!campaignId && <AutocompleteTextField 
                            widthCSS={320}
                            placeholder='Select Campaign'
                            optionList={campaignNameList} 
                            onValueChange={(value) => {
                                setSelectedCampaignName(value.title); 
                                setSelectedCampaignId(value.id);
                                setSelectedReportData(reportData || null);
                                if (!value.title) {
                                    setNewCampaignName(value);
                                }
                            }}
                            onNewValuePress={handleCreateCampaign} />
                        }
                        <MainButton title={'Create Offer'} onPress={handleCreateOffer} style={selectedCampaignId ? {width: '50%'} : {pointerEvents: 'none', width: '50%', opacity: 0.64}}/>
                    </div>
                }
            </div>
        </Modal>
    );
};

export default ModalCreateOffer;
