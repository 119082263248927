import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import { getUserReport, getReport, ReportData } from '../../../Utils/Reports/processReportData';

import '../../../Styles/globalColors.css'
import styles from './SocialProfile.module.css';

import { UserContext } from '../../../App';

interface SocialProfileProps {
    reportData: ReportData;
    picVisible?: boolean;
    openOnClick?: boolean;
    handleOnClick?: () => void; // not certain of all types of objects to be passed here, for now only ReportData
    showUnselect?: boolean;
}

const SocialProfile: React.FC<SocialProfileProps> = ({ reportData, picVisible, openOnClick = true, handleOnClick, showUnselect }) => {
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");

    const navigate = useNavigate();

    const handleClick = () => {
        if (openOnClick)
            return navigate('/creator', { state: { reportData } });
        if (openOnClick === false && handleOnClick) {
            return handleOnClick()
        }
    };

    const report = getReport(reportData);
    const userReport = getUserReport(reportData);
    const profilePic = report?.profilePictureUrl;


    const socialType = userReport?.reportType || '';
    function getSocialTypeIcon(socialType: string): any {
        switch (socialType) {
            case 'instagram':
                return faInstagram;
            case 'tiktok':
                return faTiktok;
            case 'youtube':
                return faYoutube;
            default:
                return faYoutube;
        }
    }

    return (
        <div className={styles.card} onClick={handleClick} style={showUnselect ? {borderRadius: '20px'} : {}}>
            <div className={styles.picHandle}>
                {picVisible &&
                    (<img
                        src={profilePic}
                        className={styles.profilePic}
                        alt='Creator profile'
                        loading='lazy'
                    />)
                }
                <div className={styles.textCard}>
                    <FontAwesomeIcon icon={getSocialTypeIcon(socialType)} size='2x' color='black' className={styles.profileIcon} />
                    <span className={styles.handle} >{report?.accountHandle}</span>
                </div>
            </div> 
            {showUnselect && <FontAwesomeIcon icon={faXmark} size='2x' color='grey' className={styles.unselectIcon} />}
        </div>
    );
};

export default SocialProfile;
