import { format } from 'date-fns';

export type ReportData = {
  userReport: UserReport;
  report: Report;
  posts: Post[];
};

export type DiscoveryReportData = {
  report: Report;
  reportCategory: ReportCategory;
  userReport?: UserReport;
};

export type UserReport = {
  reportType: string;
  lastSeenTimestamp: any;
  reportSocialAccountId: string;
  creatorProfessionalName: string | null;
  userReportId: number;
  likedReport: boolean;
};

export type Report = {
  numberOfFollowers: number;
  accountHandle: string;
  biography: string | null;
  externalUrlToSocialsTree: string | null;
  profilePictureUrl: string;
  creatorCategory: string;

  avgImpressions: number;
  avgImpressionRate: number;
  avgEngagments: number;
  avgEngagementRate: number;
  postPrice: number;
  storyPrice: number;
  postsPerMonth: number;

  postHighEstImpressions: number;
  postAvgEstImpressions: number;
  postLowEstImpressions: number;
  postHighEstEngagements: number;
  postAvgEstEngagements: number;
  postLowEstEngagements: number;

  storyHighEstImpressions: number;
  storyAvgEstImpressions: number;
  storyLowEstImpressions: number;
  storyHighEstEngagements: number;
  storyAvgEstEngagements: number;
  storyLowEstEngagements: number;
};

export type ReportCategory = {
  socialAccountId: string;
  type: string;
  categoryName: string;
}

export type Post = {
  postId: string;
  estPrice: number;
  impressions: number;
  engagements: number;
  displayUrl: string;
  timeData: {
    month?: string;
    year?: string;
    timestamp: any;
  };
  postType: string;
  isSponsoredPost: boolean;
  estimated?: boolean;
  videoUrl?: string;
  postLink: string;
  caption?: string;
  outlier?: boolean;
  video?: boolean;
  commentCount?: number;
  likeCount?: number;
};


type DataSet = {
  [key: string]: number[]
};

type PostDataSet = {
  [key: string]: Post[]
}

type SortedData = {
  [key: string]: number[];
}

type AverageData = {
  [key: string]: number
};

export function toUnixTimestamp(dateString: string): number {
  const date = new Date(dateString);
  return Math.floor(date.getTime());
}

export const getLastValue = (metric: { [key: string]: number }): number => {
  const keys = Object.keys(metric);
  const lastKey = keys[keys.length - 1];
  const lastValue = Math.floor(metric[lastKey]);
  return lastValue;
};

export const getCostPer = (price: number, metric: number): number => {
  return Math.round(price / metric * 100) / 100 * 1000
};

export const storyPrice = (postPrice: number) => {
  if (postPrice) {
    return postPrice * .175
  }
};


export const groupByCategory = (data: DiscoveryReportData[]): { [key: string]: DiscoveryReportData[] } => {
  const grouped: { [key: string]: DiscoveryReportData[] } = {};

  for (const item of data) {
    const category = item.reportCategory.categoryName;
    if (!grouped[category]) {
      grouped[category] = [];
    }
    grouped[category].push(item);
  }

  return grouped;
};


export function getUserReport(reportData: ReportData): UserReport | undefined {
  if (reportData && reportData.userReport) {
      return reportData.userReport
  }
};

export function getReport(reportData: ReportData): Report | undefined {
  if (reportData && reportData.report) {
      return reportData.report
  }
};

export function getPosts(reportData: ReportData): Post[] | undefined {
  if (reportData && reportData.posts) {
      return reportData.posts
  }
};


export const calculateAverage = (dataSet: DataSet): AverageData => {
  const averageData: AverageData = {};
  for (const month in dataSet) {
    const values = dataSet[month];
    averageData[month] = values.length ? values.reduce((a, b) => a + b, 0) / values.length : 0;
  }
  return averageData;
};


export function calculatePercentile(values: number[], percentile: number): number {
  if (values.length === 0) return 0;
  if (percentile <= 0) return Math.min(...values);
  if (percentile >= 1) return Math.max(...values);

  let sortedValues = [...values].sort((a, b) => a - b);
  let index = (sortedValues.length - 1) * percentile;
  let lower = Math.floor(index);
  let upper = lower + 1;
  let weight = index % 1;

  if (upper >= sortedValues.length) return sortedValues[lower];
  return sortedValues[lower] * (1 - weight) + sortedValues[upper] * weight;
};


export const getAllPosts = (reportData: ReportData) => {
  let estPriceDataSet: DataSet = {};
  let impressionsDataSet: DataSet = {};
  let engagementsDataSet: DataSet = {};
  let timestampDataSet: { [key: string]: number[] } = {};
  let postDataSet: PostDataSet = {};

  const processPosts = (posts: Post[]) => {
    posts.sort((a, b) => {
      const dateA = new Date(a.timeData.timestamp);
      const dateB = new Date(b.timeData.timestamp);
      return dateA.getTime() - dateB.getTime();
    });
    posts.forEach((post, postIndex) => {
      const unixTimestamp = toUnixTimestamp(post.timeData.timestamp);
      if (!estPriceDataSet[postIndex]) estPriceDataSet[postIndex] = [];
      if (!impressionsDataSet[postIndex]) impressionsDataSet[postIndex] = [];
      if (!engagementsDataSet[postIndex]) engagementsDataSet[postIndex] = [];
      if (!timestampDataSet[postIndex]) timestampDataSet[postIndex] = [];
      estPriceDataSet[postIndex].push(post.estPrice);
      impressionsDataSet[postIndex].push(post.impressions);
      engagementsDataSet[postIndex].push(post.engagements);
      timestampDataSet[postIndex].push(unixTimestamp);
    });
  };

  processPosts(reportData.posts);

  const sortedEstPriceDataSet: SortedData = Object.keys(estPriceDataSet)
    .sort((a, b) => timestampDataSet[a][0] - timestampDataSet[b][0])
    .reduce((obj: SortedData, key) => {
      obj[format(new Date(timestampDataSet[key][0]), 'd MMM')] = estPriceDataSet[key];
      return obj;
    }, {});

  const sortedImpressionsDataSet: SortedData = Object.keys(impressionsDataSet)
    .sort((a, b) => timestampDataSet[a][0] - timestampDataSet[b][0])
    .reduce((obj: SortedData, key) => {
      obj[format(new Date(timestampDataSet[key][0]), 'd MMM')] = impressionsDataSet[key];
      return obj;
    }, {});

  const sortedEngagementsDataSet: SortedData = Object.keys(engagementsDataSet)
    .sort((a, b) => timestampDataSet[a][0] - timestampDataSet[b][0])
    .reduce((obj: SortedData, key) => {
      obj[format(new Date(timestampDataSet[key][0]), 'd MMM')] = engagementsDataSet[key];
      return obj;
    }, {});

  return {
    estPrice: calculateAverage(sortedEstPriceDataSet),
    impressions: calculateAverage(sortedImpressionsDataSet),
    engagements: calculateAverage(sortedEngagementsDataSet),
    posts: postDataSet,
  };
};


export const calculateGroupedAverages = (reportData: ReportData) => {
  let estPriceDataSet: DataSet = {};
  let impressionsDataSet: DataSet = {};
  let engagementsDataSet: DataSet = {};
  let timestampDataSet: { [key: string]: number[] } = {};
  let postGroupDataSet: PostDataSet = {};
  
  const getDivider = (postCount: number) => {
    if (postCount <= 30) { return 2 }
    else if (postCount <= 45) { return 3 }
    else if (postCount <= 70) { return 7 }
    else if (postCount <= 100) { return 10 }
    else return 15
  }

  const processPosts = (posts: Post[]) => {
    posts.sort((a, b) => {
      const dateA = new Date(a.timeData.timestamp);
      const dateB = new Date(b.timeData.timestamp);
      return dateA.getTime() - dateB.getTime();
    });
    posts.forEach((post, postIndex) => {
      const groupIndex = Math.floor(postIndex / getDivider(posts.length));
      const unixTimestamp = toUnixTimestamp(post.timeData.timestamp);
      if (!estPriceDataSet[groupIndex]) estPriceDataSet[groupIndex] = [];
      if (!impressionsDataSet[groupIndex]) impressionsDataSet[groupIndex] = [];
      if (!engagementsDataSet[groupIndex]) engagementsDataSet[groupIndex] = [];
      if (!timestampDataSet[groupIndex]) timestampDataSet[groupIndex] = [];
      if (!postGroupDataSet[groupIndex]) postGroupDataSet[groupIndex] = [];
      estPriceDataSet[groupIndex].push(post.estPrice);
      impressionsDataSet[groupIndex].push(post.impressions);
      engagementsDataSet[groupIndex].push(post.engagements);
      timestampDataSet[groupIndex].push(unixTimestamp);
      postGroupDataSet[groupIndex].push(post);
      // console.log(posts.length)
      // console.log(postGroupDataSet)
    });
  }

  const calculateMedian = (values: number[]) => {
    if (values.length === 0) return 0;

    values.sort((a, b) => a - b);

    const half = Math.floor(values.length / 2);

    if (values.length % 2)
      return values[half];

    return (values[half - 1] + values[half]) / 2.0;
  }

  processPosts(reportData.posts);

  let allPostValues: number[] = [];
  for (let key in estPriceDataSet) {
    allPostValues.push(...estPriceDataSet[key]);
  }
  const percentile75value = calculatePercentile(allPostValues, 0.8);
  for (let key in estPriceDataSet) {
    estPriceDataSet[key] = estPriceDataSet[key].filter((value: number) => value <= percentile75value);
  }

  let allEngagements: number[] = [];
  for (let key in engagementsDataSet) {
    allEngagements.push(...engagementsDataSet[key]);
  }
  const percentile75eng = calculatePercentile(allEngagements, 0.8);
  for (let key in engagementsDataSet) {
    engagementsDataSet[key] = engagementsDataSet[key].filter((engagement: number) => engagement <= percentile75eng);
  }

  let allImpressions: number[] = [];
  for (let key in impressionsDataSet) {
    allImpressions.push(...impressionsDataSet[key]);
  }
  const percentile75imp = calculatePercentile(allImpressions, 0.8);
  for (let key in impressionsDataSet) {
    impressionsDataSet[key] = impressionsDataSet[key].filter((impressions: number) => impressions <= percentile75imp);
  }

  const sliceFromPrice = Math.max(0, Object.keys(estPriceDataSet).length - 20);
  const sliceFromImp = Math.max(0, Object.keys(impressionsDataSet).length - 20);
  const sliceFromEng = Math.max(0, Object.keys(engagementsDataSet).length - 20);


  const sortedEstPriceDataSet: SortedData = Object.keys(estPriceDataSet)
    .sort((a, b) => calculateMedian(timestampDataSet[a]) - calculateMedian(timestampDataSet[b]))
    .slice(sliceFromPrice)
    .reduce((obj: SortedData, key) => {
      obj[format(new Date(calculateMedian(timestampDataSet[key])), 'd MMM')] = estPriceDataSet[key];
      return obj;
    }, {});

  console.log(sortedEstPriceDataSet)

  const sortedImpressionsDataSet: SortedData = Object.keys(impressionsDataSet)
    .sort((a, b) => calculateMedian(timestampDataSet[a]) - calculateMedian(timestampDataSet[b]))
    .slice(sliceFromImp)
    .reduce((obj: SortedData, key) => {
      obj[format(new Date(calculateMedian(timestampDataSet[key])), 'd MMM')] = impressionsDataSet[key];
      return obj;
    }, {});

  const sortedEngagementsDataSet: SortedData = Object.keys(engagementsDataSet)
    .sort((a, b) => calculateMedian(timestampDataSet[a]) - calculateMedian(timestampDataSet[b]))
    .slice(sliceFromEng)
    .reduce((obj: SortedData, key) => {
      obj[format(new Date(calculateMedian(timestampDataSet[key])), 'd MMM')] = engagementsDataSet[key];
      return obj;
    }, {});

  return {
    estPrice: calculateAverage(sortedEstPriceDataSet),
    impressions: calculateAverage(sortedImpressionsDataSet),
    engagements: calculateAverage(sortedEngagementsDataSet),
    postGroups: postGroupDataSet
  };
};