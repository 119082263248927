import { SERVER_URLS } from "../../constants";
import { Post } from "./processPosts";

export const createPosts = async (
        idToken: string | undefined,
        posts: Post[]): Promise<any> => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${idToken}`)
  
    const rawCreateCampaignOffer = JSON.stringify(posts);  
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: rawCreateCampaignOffer,
      redirect: 'follow' as RequestRedirect
    };
  
    try {
      const response = await fetch(SERVER_URLS.BACKEND_URL+"/valuence/api/createPosts", requestOptions);
      if (!response.ok) {
        throw new SyntaxError('SyntaxError');
      }
      const result = await response.json();

      return result;
      
    } catch (error) {
        console.log("Couldn't get /createPosts in createPosts: ", error)
    }
  };