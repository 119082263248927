import React from 'react';
import { Button, ButtonGroup } from '@mui/material';

import './SegmentedLoginControl.css';
import '../../../Styles/globalColors.css';

interface SegmentedControlProps {
  onToggle: () => void;
  isLogin: boolean;
}

const SegmentedControl: React.FC<SegmentedControlProps> = ({ onToggle, isLogin }) => {
  return (
    <ButtonGroup className="segmentedControl" onClick={onToggle}>
      <Button 
        variant={isLogin ? "contained" : "outlined"} 
        className={isLogin ? "selected" : "notSelected"}
      >
        Login
      </Button>
      <Button 
        variant={!isLogin ? "contained" : "outlined"} 
        className={!isLogin ? "selected" : "notSelected"}
      >
        Sign Up
      </Button>
    </ButtonGroup>
  );
};

export default SegmentedControl;
