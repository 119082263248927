import { SERVER_URLS } from "../../constants";

export const deleteCampaign = async (
        idToken: string | undefined,
        campaignId: number): Promise<any> => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${idToken}`)
  
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow' as RequestRedirect
    };
  
    try {
      const response = await fetch(SERVER_URLS.BACKEND_URL+"/valuence/api/deleteCampaign/"+campaignId, requestOptions);
      if (!response.ok) {
        throw new SyntaxError('SyntaxError');
      }
      const result = await response.json();

      return result;
      
    } catch (error) {
        console.log("Couldn't get /deleteCampaign in deleteCampaign: ", error)
    };
  };