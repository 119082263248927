import React, { useState, useEffect } from 'react';
import './NumberInputControl.css';

interface NumberInputControlProps {
    onChangeText: (text: number) => void;
    inputTitle?: string;
    isDollarValue?: boolean;
    decimals?: number;
    initialValue?: number;
}

const NumberInputControl: React.FC<NumberInputControlProps> = ({
    onChangeText,
    inputTitle,
    isDollarValue = false,
    decimals = 3,
    initialValue
}) => {
    const [value, setValue] = useState<number>(initialValue || 1);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (initialValue !== undefined) {
            setValue(initialValue);
        }
    }, [initialValue]);

    const handleIncrement = () => {
        if (isDollarValue) {
            const newValue = value + 100;
            setValue(newValue);
            onChangeText(newValue)
        }
        else {
            const newValue = value + 1;
            setValue(newValue);
            onChangeText(newValue)
        }
    };

    const handleDecrement = () => {
        if (isDollarValue) {
            const newValue = value - 100;
            setValue(newValue);
            onChangeText(newValue)
        }
        else {
            if (value > 0) {
            const newValue = value - 1;
            setValue(newValue);
            onChangeText(newValue)
            }
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputString = e.target.value;
        const newValue = inputString === '' ? 0 : parseFloat(inputString.replace(/^0+/, ''));

        if (!isNaN(newValue) && newValue >= 0) {
            setValue(newValue);
            onChangeText(newValue);
        }
    };

    const displayValue = isDollarValue ? `${value.toFixed(decimals)}` : value;

    return (
        <div className='numberInputContainer'>
            <span className='numberinputTitle'>
                {inputTitle}
            </span>
            {isDollarValue && <i className='numberInputDollarIcon'>$</i>}
            <input
                type="number"
                value={isFocused ? value.toString() : displayValue}
                onChange={handleChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                className={`numberInputBox ${isDollarValue && 'numberInputBoxDollar'}`}
            />
            <div className='numberInputControllerGroup'>
                <button className='numberInputController' onClick={handleIncrement}>
                    ▲
                </button>
                <button className='numberInputController' onClick={handleDecrement}>
                    ▼
                </button>
            </div>
        </div>
    );
};

export default NumberInputControl;
