import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';

import styles from './CampaignListPage.module.css';
import '../../Styles/globalStyles.css';

import { UserContext } from '../../App';

import { faPlus } from '@fortawesome/free-solid-svg-icons';

import TrianglesLoader from '../../Components/Loaders/TrianglesLoader/TrianglesLoader';

import campaignsTest from '../../Assets/testDataObjects/campaignList.json';
import { Campaign } from '../../Utils/Campaigns/processCampaigns';
import CampaignHeader from '../../Components/Campaign/CampaignHeader/CampaignHeader';
import AutocompleteTextField from '../../Components/Inputs/AutocompleteTextField/AutocompleteTextField';
import MainButton from '../../Components/Buttons/MainButton/MainButton';
import ModalCreateCampaign from '../../Components/Campaign/ModalCreateCampaign/ModalCreateCampaign';
import { Tooltip } from '@mui/material';
import { getCampaignListWithAdminUser } from '../../Utils/Campaigns/getCampaignListWithAdminUser';

export type SortOption = 'updatedAt' | 'createdAt' | 'campaignBudget' | 'campaignEarnedValue' | 'completionRate' | '';

const CampaignListPage: React.FC = () => {
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");

    const { user, setUser } = userContext;
    const [loading, setLoading] = useState<boolean>(true);
    const [token, setToken] = useState<string | null>(null);  // Caching idToken

    const [sortOption, setSortOption] = useState<SortOption>('updatedAt');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const toggleSortOrder = useCallback(() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc'), []);

    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [selectedCampaignName, setSelectedCampaignName] = useState<string>("");
    const [openModalCreateCampaign, setOpenModalCreateCampaign] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
          user.getIdToken(true).then((fetchedToken) => {
            setToken(fetchedToken);
          });
        }
      }, [user]);

    const fetchCampaigns = () => {
        if (!token) {
            console.error("Failed to retrieve ID token");
            return;
        };

        getCampaignListWithAdminUser(user?.uid, token)
            .then((campaignList: any) => {
                console.log(campaignList);
                setCampaigns(campaignList)})
        // setCampaigns(campaignsTest)
    }

    useEffect(() => {
        if (user?.uid && token) {
            fetchCampaigns()
        }
    }, [user?.uid, token])

    const handleCreateCampaignModal = () => {
        setOpenModalCreateCampaign(!openModalCreateCampaign)
    }

    const handleCreateCampaignModalOpen = () => {
        setOpenModalCreateCampaign(true);
    };

    const handleNewCampaignCreated = (newCampaign: any) => {
        setCampaigns(prevCampaigns => [...prevCampaigns, newCampaign]);
        setOpenModalCreateCampaign(false);
    }

    const campaignNameList = campaigns.map((campaign: Campaign, index: any) => ({
        title: campaign.campaignName
    }))
    
    function getNestedValue(obj: any, path: string): any {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    }

    const sortedAndFilteredCampaigns = useMemo(() => [...campaigns].reverse()
        .filter((campaign) => { 
            return selectedCampaignName === "" || campaign.campaignName === selectedCampaignName;
        })
        .sort((a, b) => {
            if (!sortOption) return 0;
            const aValue = getNestedValue(a, sortOption);
            const bValue = getNestedValue(b, sortOption);
            if (sortOrder === 'desc') return bValue - aValue;
            return aValue - bValue;
        }), [campaigns, selectedCampaignName, sortOption, sortOrder]);

    const tooltipPlacement = (window.innerWidth > 600) ? "right" : "top";
    const tooltipDelay = (window.innerWidth > 600) ? 700 : 0;

    return (
        <div className={styles.campaignListPageContainer}>
            <h1>Your Campaigns</h1>
            {campaigns ?
                <div className={styles.campaignListContainer}>
                    <div className={styles.campaignListContainerTopRow}>
                        <div className={styles.campaignInputTopRow}>
                            <AutocompleteTextField 
                                placeholder='Campaign Name'
                                optionList={campaignNameList} 
                                onValueChange={(value) => setSelectedCampaignName(value)}
                                onNewValuePress={handleCreateCampaignModalOpen} />
                            <Tooltip title='Create a new campaign' arrow placement={tooltipPlacement} enterTouchDelay={tooltipDelay}>
                                {(window.innerWidth > 800) ? 
                                <div><MainButton onPress={handleCreateCampaignModal} title='New Campaign' icon={faPlus} /></div>
                                :
                                <div><MainButton onPress={handleCreateCampaignModal} icon={faPlus} style={{padding: '8px', aspectRatio: '1/1'}} /></div>
                                }
                            </Tooltip>
                        </div>
                        <div className={styles.campaignSortFilterGroupContainer}>
                            <select className={styles.campaignSortContainer} value={sortOption} onChange={(e) => setSortOption(e.target.value as SortOption)}>
                                <option value="updatedAt">Date updated</option>
                                <option value="createdAt">Date created</option>
                                <option value="campaignBudget">Budget</option>
                                <option value="campaignEarnedValue">Value</option>
                                <option value="completionRate">Completion rate</option>
                            </select>
                            <div className={styles.campaignOrderContainer}>
                                {sortOrder === 'desc' ? 'Descending' : 'Ascending'}
                                <button className={styles.campaignOrderButton} onClick={toggleSortOrder}>
                                    {sortOrder === 'desc' ? '▼' : '▲'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.campaignListContainer}>
                        {sortedAndFilteredCampaigns.map((campaign, index) => (
                            <CampaignHeader campaignData={campaign} isClickable={true} key={index}/>
                        ))}
                    </div>
                </div>
                :
                loading ?
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}><TrianglesLoader /></div>
                    :
                    <p style={{ textAlign: 'center' }}>You haven't created any campaign yet</p>
            }
            <ModalCreateCampaign 
                open={openModalCreateCampaign} 
                onClose={handleCreateCampaignModal} 
                campaignName={selectedCampaignName}
                onCampaignCreated={handleNewCampaignCreated} />
        </div>
    );
};

export default CampaignListPage;
