import React, { useState, useMemo, useCallback, useContext, useEffect } from 'react';
import { ReportData } from '../../../Utils/Reports/processReportData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'

import '../../../Styles/globalColors.css';
import '../../../Styles/globalStyles.css';
import './UserReportList.css';
import SocialProfileHeader from '../../Report/SocialProfileHeader/SocialProfileHeader';

type ReportListProps = {
  reportList: ReportData[];
};

export type SortOption = 'userReport.lastSeenTimestamp' | 'report.numberOfFollowers' | 'report.avgImpressionRate' | 'report.avgEngagementRate' | 'report.postPrice' | '';

const UserReportList: React.FC<ReportListProps> = ({ reportList }) => {
    const [sortOption, setSortOption] = useState<SortOption>('userReport.lastSeenTimestamp');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [showLikedReports, setShowLikedReports] = useState(true)
  
    const toggleSortOrder = useCallback(() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc'), []);
  
    const reports: ReportData[] = useMemo(() => {
        if (!reportList) return [];
        const reversedList = [...reportList].reverse();
        return reversedList.filter((report: ReportData) => {
          if (showLikedReports === true) {
            return report.userReport.likedReport === true;
          }
          return report.userReport;
      });
    }, [reportList, showLikedReports]);

    function getNestedValue(obj: any, path: string): any {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    }

    const handleListClick = () => {
      console.log(showLikedReports)
      setShowLikedReports(!showLikedReports)
    }
  
    const sortedAndFilteredReports = useMemo(() => [...reports]
        .sort((a, b) => {
            if (!sortOption) return 0;
            const aValue = getNestedValue(a, sortOption);
            const bValue = getNestedValue(b, sortOption);
            if (sortOrder === 'desc') return bValue - aValue;
            return aValue - bValue;
      }), [reports, sortOption, sortOrder]);
  

  return (
    <div className='report-list-container'>
      <div className='report-list-container-top-row'>
        <div className="report-sort-filter-group-container">
          <select className="report-sort-container" value={sortOption} onChange={(e) => setSortOption(e.target.value as SortOption)}>
              <option value="userReport.lastSeenTimestamp">Last viewed</option>
              <option value="report.numberOfFollowers">Followers</option>
              <option value="report.avgImpressionRate">Impression rate</option>
              <option value="report.avgEngagementRate">Engagement rate</option>
              <option value="report.postPrice">Post value</option>
          </select>
          <div className='report-order-container'>
              {sortOrder === 'desc' ? 'Descending' : 'Ascending'}
              <button className="report-order-button" onClick={toggleSortOrder}>
                  {sortOrder === 'desc' ? '▼' : '▲'}
              </button>
          </div>
        </div>
        {/* <button 
          onClick={() => handleListClick} 
          className={`${showLikedReports? 'searchHistory' : 'searchHistoryActive'}`}>
          <FontAwesomeIcon icon={faClockRotateLeft} />
          View history
          </button> */}
      </div>
      <div className="report-list-container">
        {sortedAndFilteredReports.map(report => 
        <SocialProfileHeader key={report.userReport.userReportId} reportData={report} isClickable={true} />
        )}
      </div>
    </div>
  );
};

export default UserReportList;
