import { SERVER_URLS } from "../../constants";

export const getAllReportCategories = async (idToken: string | undefined): Promise<any> => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${idToken}`)
  
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow' as RequestRedirect
      };

      try {
        const response = await fetch(SERVER_URLS.BACKEND_URL+"/valuence/api/getAllReportCategories", requestOptions);
        if (!response.ok) {
          throw new SyntaxError('SyntaxError');
        }
        return await response.json();
    

      } catch (error) {
        console.log('error at /getAllReportCategories')
      }
}