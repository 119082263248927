import React, { useState, useEffect, useContext, useMemo } from 'react';
import SearchBar from '../../Components/Inputs/SearchBar/SearchBar';
import DiscoveryCardList from '../../Components/Discovery/DiscoveryCardList/DiscoveryCardList';
import { Alert } from '@mui/material';

import { UserContext } from '../../App';
import { debounce } from 'lodash';

import styles from './DiscoveryPage.module.css';
import { getAllReportCategories } from '../../Utils/Discovery/getAllReportCategories';
import { DiscoveryReportData, groupByCategory } from '../../Utils/Reports/processReportData';
import { fetchUserReports } from '../../Utils/Reports/fetchUserReports';
import { aiHandleSearch } from '../../Utils/Reports/aiHandleSearch';
import StandardTextInput from '../../Components/Inputs/StandardTextInput/StandardTextInput';

const DiscoveryPage: React.FC = () => {
  const userContext = useContext(UserContext);
  if (!userContext) throw new Error("You probably forgot to provide the UserContext value");

  const { user, setUser } = userContext;
  const [discoveryReportsLists, setDiscoveryReportsLists] = useState<any>(null);
  const [aiGenReport, setAiGenReports] = useState<any[]>([]);
  const [searchLocation, setSearchLocation] = useState<string>('');
  const [searchTheme, setSearchTheme] = useState<string>('');
  const [searchType, setSearchType] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      user.getIdToken(true).then((fetchedToken) => {
        setToken(fetchedToken);
      });
    }
  }, [user]);

  const getDiscoveryReports = useMemo(() => debounce(async () => {
    if (!token) {
      console.error("Failed to retrieve ID token");
      return;
    }
    setLoading(true);
    try {
      const allCategoriesResponse = await getAllReportCategories(token);
      const allCategoriesReports = allCategoriesResponse.map((report: DiscoveryReportData) => report);
      if (!allCategoriesReports || allCategoriesReports.length === 0) {
        setLoading(false);
        return;
      }
      const groupedDiscoveryLists = groupByCategory(allCategoriesReports)
      setDiscoveryReportsLists(groupedDiscoveryLists);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch report:", error);
      setLoading(false);
    }
  }, 300), [token, user]);

  useEffect(() => {
    if (user?.uid && token) {
      getDiscoveryReports();
    }
    return () => getDiscoveryReports.cancel();
  }, [getDiscoveryReports, user?.uid, token]);


  const getAiGenReports = async () => {
    if (!token) {
      console.error("Failed to retrieve ID token");
      return;
    }
    setLoading(true);
    try {
      aiHandleSearch(token, searchLocation, searchTheme, searchType)
      .then(data => {
        console.log(data);
        setAiGenReports(data);
      });
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch report:", error);
      setLoading(false);
    }
  };


  return (
    <div className={styles.main}>
      <div className={styles.discoveryTopSection}>
        <h1 className={styles.discoveryTitle}>Discover Creators</h1>
        {/* <SearchBar /> */}
        <Alert severity="info">We are excited to announce new Discovery functionalities coming in March</Alert>
        {/* <StandardTextInput value={searchLocation} onChangeText={setSearchLocation} placeholder='Location' />
        <StandardTextInput value={searchTheme} onChangeText={setSearchTheme} placeholder='Theme/Vibe' />
        <StandardTextInput value={searchType} onChangeText={setSearchType} placeholder='Social type' />
        <button onClick={getAiGenReports}>Search</button>
        {aiGenReport && aiGenReport.map((report: any) =>
          <p>{report}</p>
        )} */}
      </div>
      {discoveryReportsLists &&
        Object.entries(discoveryReportsLists as { [key: string]: DiscoveryReportData[] }).map(([categoryName, discoveryReports]) =>
          <DiscoveryCardList reportList={discoveryReports} categoryName={categoryName} />
        )
      }
    </div>
  );
};

export default DiscoveryPage;
