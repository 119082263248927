import * as React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses, LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import './../../../Styles/globalColors.css';

interface LinearProgressBarProps extends LinearProgressProps {
  value: number;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: (window.innerWidth > 1000) ? 200 : 150,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? 'var(--balancedCyan)' : 'var(--balancedCyan)',
  },
}));

const LinearProgressBar: React.FC<LinearProgressBarProps> = (props) => {

  const tooltipDelay = (window.innerWidth > 600) ? 700 : 0;
  
  return (
    <Tooltip title={`Completion rate: ${Math.round(props.value)}%`} arrow placement="bottom" enterTouchDelay={tooltipDelay}>
        <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
          <BorderLinearProgress variant="determinate" {...props} />
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >{`${Math.round(props.value)}%`}</Typography>
        </div>
    </Tooltip>
  );
}

export default LinearProgressBar;
