import React, { useContext, useState } from 'react';
import { Modal } from '@mui/material';
import StandardTextInput from '../../Inputs/StandardTextInput/StandardTextInput';
import CurrencyDropdown from '../../Inputs/CurrencyDropdown/CurrencyDropdown';

import { UserContext } from '../../../App';

import styles from './SettingsModals.module.css'
import './../../../Styles/globalColors.css'
import MainButton from '../../Buttons/MainButton/MainButton';


interface PreferencesModalProps {
    open: boolean;
    onClose: () => void;
}

const PreferencesModal: React.FC<PreferencesModalProps> = ({ open, onClose }) => {
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");
    const { user, setUser } = userContext;

    const [uploadedImage, setUploadedImage] = useState<string | null>(null);

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files && e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setUploadedImage(reader.result as string);
        };
      }
    };

    return (
        <Modal open={open} onClose={onClose} className={styles.background}>
            <div className={styles.modalContainer}>
                <div className={styles.profileSettings}>
                    <span className={styles.title}>Profile</span>
                    <div className={styles.profileSubSection}>
                        <div className={styles.profileInputs}>
                            <StandardTextInput value={''} placeholder='Full name' onChangeText={function (text: string): void {
                                throw new Error('Function not implemented.');
                            }} />
                            <StandardTextInput value={''} placeholder='Organization name' onChangeText={function (text: string): void {
                                throw new Error('Function not implemented.');
                            }} />
                            <span className={styles.subText}>
                                This information will appear on offers your send to creators.
                            </span>
                        </div>
                        <div className={styles.imageContainer}>
                            {uploadedImage ? 
                            <img src={uploadedImage} alt="Uploaded Preview" className={styles.imagePreview} /> 
                            :
                            <div className={styles.imagePlaceholder}></div>}
                            <div className={styles.customUploadButton} onClick={() => document.getElementById("hiddenFileInput")?.click()}>
                                Upload Image
                            </div>
                            <input
                                type="file"
                                id="hiddenFileInput"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleImageUpload}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.reportSettings} style={{marginBottom: '0.5em'}}>
                    <span className={styles.title}>Report settings</span>
                    <CurrencyDropdown />
                    <span className={styles.subText}>
                        Select your preferred currency to measure content value.
                    </span>
                </div>
                <MainButton title={'Save changes'} onPress={undefined}/>
            </div>
        </Modal>
    );
};

export default PreferencesModal;