import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import BouncingBalls from '../../Components/Loaders/BallsLoader/BouncingBalls';

import { UserContext } from '../../App';

import '../../Styles/globalColors.css'
import styles from './OfferPage.module.css';
import { ReportData } from '../../Utils/Reports/processReportData';
import CampaignOfferHeader from '../../Components/Campaign/CampaignOffers/CampaignOfferHeader/CampaignOfferHeader';
import CampaignOfferBuilderAndChart from '../../Components/Campaign/CampaignOffers/CampaignOfferBuilderAndChart/CampaignOfferBuilderAndChart';
import { CampaignOffer } from '../../Utils/Campaigns/processCampaigns';
import ReportOfferBuilder from '../../Components/Report/ReportOfferBuilder/ReportOfferBuilder';

import userReportData from '../../Assets/testDataObjects/userReport.json';
import { getCreatorReportSummary } from '../../Utils/Reports/getCreatorReportSummary';


type OfferPageProps = {
    reportFromUserReports?: ReportData;
};

const OfferPage: React.FC<OfferPageProps> = ({ reportFromUserReports }) => {
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");

    const { user, setUser } = userContext;
    const [token, setToken] = useState<string | null>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [offer, setOffer] = useState<CampaignOffer>();
    const [offerUpdated, setOfferUpdated] = useState<boolean>(false); // State to track if offer has been updated by user
    const [userReport, setUserReport] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
            user.getIdToken(true).then((fetchedToken) => {
                setToken(fetchedToken);
            });
        }
    }, [user]);

    useEffect(() => {
        const fetchCreatorReportSummary = async () => {
            if (offer && token) {
                try {
                    const report = await getCreatorReportSummary(offer.socialAccountId, offer.socialAccountType, token);
                    setUserReport(report);
                } catch (error) {
                    console.error("Error fetching report:", error);
                }
            }
        };
        fetchCreatorReportSummary();
    }, [offer, token]);

    const handleUpdatedOffer = (updatedOffer: CampaignOffer) => {
        setOffer(updatedOffer);
        setOfferUpdated(true);
    };

    useEffect(() => {
        const state = location.state;
        // Update offer from location.state only if it has not been updated by handleUpdatedOffer
        if (!offerUpdated) {
            setOffer(state.campaignOfferData);
        }
    }, [location.state]);

    return (
        <div className={styles.mainOffer}>
            <div style={{ display: 'flex', width: '100%' }}>
                <button onClick={() => navigate(-1)} className={styles.backButton}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                    {offer?.campaignName}
                </button>
            </div>
            {loading ?
                <div className={styles.loaderContainer}>
                    <BouncingBalls />
                    <span>We're fetching your report</span>
                    <span>This may take up to 45 seconds</span>
                </div>
                :
                <div className={styles.offerContainer}>
                    {
                        offer ?
                            <div className={styles.offerContainer}>
                                <CampaignOfferHeader campaignOfferData={offer} extraInfoVisible={true} />
                                { userReport &&
                                    <CampaignOfferBuilderAndChart 
                                    offerData={offer} 
                                    onOfferUpdated={handleUpdatedOffer}
                                    reportData={userReport} />
                                }
                            </div>
                            :
                            'This creator may not exist.  Please make sure their username is correct and that their profile is public'}
                </div>
            }
        </div>
    );
};

export default OfferPage;