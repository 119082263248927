import { SERVER_URLS } from "../../constants";

export const fetchUserReports = async (userId: string | undefined, idToken: string | undefined): Promise<any> => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${idToken}`)
  
    const rawGetUserReports = JSON.stringify({
      "user_id": userId
    });
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: rawGetUserReports,
      redirect: 'follow' as RequestRedirect
    };
  
    try {
      const response = await fetch(SERVER_URLS.BACKEND_URL+"/valuence/api/getUserReports", requestOptions);
      if (!response.ok) {
        throw new SyntaxError('SyntaxError');
      }
      const reports = await response.json();
  
      const foundReport = reports.filter((report: any) => 
        report.userReport
      );
  
      return foundReport;
      
    } catch (error) {
        console.log("Couldn't get /getUserReports in fetchUserReports: ", error)
    }
  };  