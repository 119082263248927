import { SERVER_URLS } from "../../constants";

export const getWorkspaceList = async (userId: string | undefined, idToken: string | undefined) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${idToken}`)
  
    const raw = JSON.stringify({
      "user_id": userId,
    });
  
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow' as const
    };
  
    try {
      const response = await fetch(SERVER_URLS.BACKEND_URL+"/valuence/api/workspace/getWorkspaceList", requestOptions);
      const result = await response.json();
      return result;
    } catch (error) {
      console.log('error in getWorkspaceList', error);
      return null;
    }
  }
  