import axios from 'axios'

export async function getImageAsBase64(url: string, id: string) {
  const requestURL =  "https://valuencer-e3e83.ue.r.appspot.com/getImageAsBase64"
  const requestBody = {
    imageUrl: url,
    postId: id
  }
  const response = await axios.post(requestURL, requestBody)
  return response.data
}