import { CampaignOffer } from "../Campaigns/processCampaigns";

export type Post = {
    id?: string; // maps to campaignOfferId, not needed when creating posts
    partitionKey?: string; // generated using UUID, not needed when creating posts
    CPI: number;
    CPE: number;
    budgetValue: number;
    budgetEngagements: number;
    budgetImpressions: number;
    date: string;
    campaignId: number;
    campaignOfferId: number;
    socialAccountId: string;
    socialType: string;
    postType: string; // post or story
}

export const generatePostListFromDateList = (
    dateList: Date[],
    campaignOffer: CampaignOffer
    ): Post[] => {
    return dateList.map(date => ({
        CPI: campaignOffer.offerCPI,
        CPE: campaignOffer.offerCPE,
        budgetValue: campaignOffer.offerBudget,
        budgetEngagements: Math.round((campaignOffer.expectedEngagementsLow + campaignOffer.expectedEngagementsHigh) / 2),
        budgetImpressions: Math.round((campaignOffer.expectedImpressionsLow + campaignOffer.expectedImpressionsHigh) / 2),
        date: date.toISOString().split('T')[0], // convert Date to YYYY-MM-DD format
        campaignId: campaignOffer.campaignId,
        campaignOfferId: campaignOffer.campaignOfferId,
        socialAccountId: campaignOffer.socialAccountId,
        socialType: campaignOffer.socialAccountType,
        postType: campaignOffer.postCount > 0 ? 'post' : 'story', // Simplistic approach, you might need a better logic here
    }));
};

export const filterOutStoredPosts = (posts: Post[]): Post[] => {
    return posts.filter(post => !post.hasOwnProperty('id'));
};