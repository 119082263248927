import React, { useContext, useState } from 'react';
import { Modal } from '@mui/material';

import { UserContext } from '../../../App';

import styles from './SettingsModals.module.css'
import './../../../Styles/globalColors.css'
import StripeCheckout from '../StripePayment/StripeCheckout';


interface BillingModalProps {
    open: boolean;
    onClose: () => void;
}

const BillingModal: React.FC<BillingModalProps> = ({ open, onClose }) => {
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");
    const { user, setUser } = userContext;

    const [uploadedImage, setUploadedImage] = useState<string | null>(null);

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files && e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setUploadedImage(reader.result as string);
        };
      }
    };

    return (
        <Modal open={open} onClose={onClose} className={styles.background}>
            <div className={styles.modalContainer}>
                <div className={styles.planSettings}>
                    <span className={styles.title}>Subscription plan</span>
                    <div className={styles.planOptions}>
                        <div className={styles.priceCard}>
                            Plan A
                        </div>
                        <div className={styles.priceCard}>
                            Plan B
                        </div>
                        <div className={styles.priceCard}>
                            Plan C
                        </div>
                    </div>
                </div>
                <div className={styles.checkoutContainer}>
                    <StripeCheckout />
                </div>
            </div>
        </Modal>
    );
};

export default BillingModal;