import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { format } from 'date-fns'

import ColorSmallCard from '../../../Cards/ColorSmallCard/ColorSmallCard';
import LinearProgressBar from '../../../Cards/ProgressBars/LinearProgressBar';
import VerticalBarChart from '../../../Charts/VerticalBarChart/VerticalBarChart';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';

import { CampaignOffer } from '../../../../Utils/Campaigns/processCampaigns';

import '../../../../Styles/globalColors.css'
import styles from './CampaignOfferHeader.module.css';

import { UserContext } from '../../../../App';
import LinkButton from '../../../Buttons/LinkButton/LinkButton';
import CampaignOfferBuilderAndChart from '../CampaignOfferBuilderAndChart/CampaignOfferBuilderAndChart';

import userReportData from '../../../../Assets/testDataObjects/userReport.json';
import { formatNumber } from '../../../../Utils/Reports/formatNumber';
import { ReportData } from '../../../../Utils/Reports/processReportData';
import { getReport } from '../../../../Utils/Reports/getReport';
import { getCreatorReportSummary } from '../../../../Utils/Reports/getCreatorReportSummary';

import EllipsisMenu from '../../../../DumbComponents/EllipsisMenu/EllipsisMenu';
import CustomAlert from '../../../../DumbComponents/CustomAlert/CustomAlert';
import { deleteCampaignOffer } from '../../../../Utils/CampaignOffers/deleteCampaignOffer';


type ToggleButtonProps = {
    title: string;
    active: boolean;
    onPress: () => void;
    color: string;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({ title, active, onPress, color }) => (
    <button
        onClick={onPress}
        className={`${styles.chartButton} ${active ? styles.activeChartTab : ''}`}
        style={{ '--color': color } as React.CSSProperties} >
        {title}
    </button>
);

interface CampaignOfferHeaderProps {
    campaignOfferData: CampaignOffer;
    isClickable?: boolean;
    extraInfoVisible?: boolean;
    onDeleteOffer?: (deletedOfferId: number) => void;
    onOfferUpdated?: (updatedOffer: CampaignOffer) => void;
}

const CampaignOfferHeader: React.FC<CampaignOfferHeaderProps> = ({ campaignOfferData, isClickable, extraInfoVisible, onDeleteOffer, onOfferUpdated }) => {
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");
    const { user, setUser } = userContext;
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        if (user) {
            user.getIdToken(true).then((fetchedToken) => {
                setToken(fetchedToken);
            });
        }
    }, [user]);

    const navigate = useNavigate();
    const location = useLocation();

    const [offer, setOffer] = useState<CampaignOffer>(campaignOfferData);

    const handleUpdatedOffer = (updatedOffer: CampaignOffer) => {
        setOffer(updatedOffer);
        if (onOfferUpdated) {
            onOfferUpdated(updatedOffer);
        }
    };

    const [reportData, setReportData] = useState<ReportData>()
    const socialAccountHandle = campaignOfferData?.socialAccountHandle ?? '';
    const addToUrl = socialAccountHandle.replace(/ /gi, '');

    const fetchCreatorReportSummary = async () => {
        if (offer && token) {
            try {
                const report = await getCreatorReportSummary(offer.socialAccountId, offer.socialAccountType, token);
                setReportData(report);
            } catch (error) {
                console.error("Error fetching report:", error);
            }
        }
    };

    const [expanded, setExpanded] = useState<boolean>(false);
    const handleClick = () => {
        if (isClickable) {
            // navigate(`${location.pathname}/${addToUrl}`, { state: { campaignOfferData } });
            fetchCreatorReportSummary();
            setExpanded(!expanded)
        }
    };

    const [active, setActive] = useState(0);
    const indicatorPosition = useRef(active * 100);

    useEffect(() => {
        indicatorPosition.current = active * 100;
    }, [active]);

    const campaignOfferBudget = offer.offerBudget || 0;
    const campaignOfferEarnedValue = campaignOfferBudget; // CHANGE TO EARNED VALUE
    const campaignOfferImpressions = (offer.expectedImpressionsHigh + offer.expectedImpressionsLow) / 2 || 0;
    const campaignOfferEngagements = (offer.expectedEngagementsHigh + offer.expectedEngagementsLow) / 2 || 0;
    const campaignOfferCompletionRate = offer.completionRate * 100;

    const colorsExpected = ['rgba(57, 87, 78, 1)', 'rgba(152, 77, 105, 1)', 'rgba(64, 115, 135, 1)'];
    const colorsActuals = ['rgba(111, 174, 156, 0.96)', 'rgba(230, 128, 165, 0.96)', 'rgba(97, 130, 161, 0.96)'];
    const dollarSign = [true, false, false];

    const CPI = campaignOfferBudget / campaignOfferImpressions;
    const CPE = campaignOfferBudget / campaignOfferEngagements;

    const handle = offer.socialAccountHandle;

    const dateFormat = 'MMM yy'
    const offerStartDate = format(new Date(offer.offerStartDate || 0), dateFormat);
    const offerEndDate = format(new Date(offer.offerEndDate || 0), dateFormat);
    const status = offer.offerStatus
    const socialType = offer?.socialAccountType || '';
    function getSocialTypeIcon(socialType: string): any {
        switch (socialType) {
            case 'instagram':
                return faInstagram;
            case 'tiktok':
                return faTiktok;
            case 'youtube':
                return faYoutube;
            default:
                return faInstagram;
        }
    }

    function getSocialLink(socialType: string, handle: string): any {
        switch (socialType) {
            case 'instagram':
                return `https://instagram.com/${handle}`;
            case 'tiktok':
                return `https://tiktok.com/@${handle}`;
            case 'youtube':
                return `https://youtube.com/@${handle}`;
            default:
                return `https://instagram.com/${handle}`;
        }
    }

    const profileUrl = getSocialLink(socialType, handle);

    const handleLinkOpen = async (event: React.MouseEvent) => {
        event.stopPropagation();
        
        window.open(profileUrl);
        return;
    }

    const handleViewProfileClick = (event: React.MouseEvent, socialType: string, handle: string) => {
        event.stopPropagation();
        navigate('/creator', { state: { selectedSocial: socialType, cleanedCreator: handle } });
    };

    function getStatusColor(status: string): any {
        switch (status) {
            case 'Preview':
                return 'var(--valGrey)';
            case 'Sent':
                return 'var(--valGrey)';
            case 'In discussion':
                return 'var(--valGrey)';
            case 'Active':
                return 'var(--defaultGreen)';
            case 'Cancelled':
                return 'var(--valRed)';
            case 'Completed':
                return 'var(--defaultGreen)';
            default:
                return 'var(--valGrey)';
        }
    }

    const [deleteOfferAlert, setDeleteOfferAlert] = useState<boolean>(false)
    const handleDeleteOffer = (offer: CampaignOffer) => {
        if (token) {
            deleteCampaignOffer(token, offer.campaignOfferId);
            if (onDeleteOffer) {
                onDeleteOffer(offer.campaignOfferId);}
        }
    };

    if (!campaignOfferData) {
        return (
            <div className={styles.campaignOfferContainer}>
                <h2>🤷</h2>
            </div>
        );
    }

    return (
        <div>
            <div className={`${(isClickable) ? styles.clickableCampaignOffer : styles.campaignOfferContainer}`}>
                <div className={styles.topCard} onClick={handleClick}>
                    <div className={`${styles.topCardFlexBoxClickable}`}>
                        <div className={styles.picTitle}>
                            <img
                                src={offer.socialAccountPictureUrl}
                                className={styles.campaignOfferPic}
                                alt='Campaign picture'
                                loading='lazy'
                            />
                            <div className={styles.titleDateStatusContainer}>
                                <div className={styles.titleCard}>
                                    <FontAwesomeIcon icon={getSocialTypeIcon(socialType)} size='1x' color='black' className={styles.profileIcon} />
                                    <span className={styles.title}>{handle}</span>
                                    <LinkButton
                                        title={''}
                                        onPress={handleLinkOpen}
                                        className={styles.linkButton}
                                    />
                                    <button className={styles.viewReportButton} onClick={(event) => handleViewProfileClick(event, socialType, handle)}>
                                        Open report
                                    </button>
                                </div>
                                <LinearProgressBar value={campaignOfferCompletionRate} />
                                <div className={styles.dateStatus}>
                                    {/* <span className={styles.dateCard}>{offerStartDate} - {offerEndDate}</span> */}
                                    <div className={styles.postCountText}>
                                        <span className={styles.postCount}>0</span>/{offer?.postCount} posts
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        <span className={styles.postCount}>0</span>/{offer?.storyCount} stories</div>
                                    <span 
                                        className={styles.statusCard}
                                        style={{'--color': getStatusColor("Preview")} as React.CSSProperties}>
                                        Preview - Draft
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.metricsAndDropdownButton}>
                        <div className={styles.campaignOfferMetrics}>
                            <ColorSmallCard num={campaignOfferEarnedValue} topTitle={`value`} title={`vs $${formatNumber(campaignOfferBudget)} budget`} type='value' isCurrency={true} />
                            <ColorSmallCard num={campaignOfferImpressions} topTitle="impressions" title={`vs. ${formatNumber(campaignOfferImpressions)} budget`} type='imp' />
                            <ColorSmallCard num={campaignOfferEngagements} topTitle="engagements" title={`vs. ${formatNumber(campaignOfferEngagements)} budget`} type='eng' />
                        </div>
                        <EllipsisMenu menuItems={[{title: 'Delete', action: () => setDeleteOfferAlert(true)}]} />
                    </div>
                </div>
                {(expanded && reportData) && <div style={{width: '100%'}}><CampaignOfferBuilderAndChart offerData={offer} reportData={reportData} isSubComponent={true} onOfferUpdated={handleUpdatedOffer} /></div>}
            </div>
            {deleteOfferAlert && <CustomAlert 
                open={deleteOfferAlert} 
                onClose={() => setDeleteOfferAlert(false)} 
                severity={'error'} 
                message={<span>You are about to delete your offer with @<b>{offer.socialAccountHandle}</b>'s {(offer.socialAccountType.charAt(0).toUpperCase() + offer.socialAccountType.substring(1).toLowerCase())} account</span>}
                actions={[
                    {label: 'Delete', onClick: () => handleDeleteOffer(offer), className:'delete' },
                    {label: 'Cancel', onClick: () => setDeleteOfferAlert(false), className:'cancel' }
                    ]}
                />}
        </div>
    );
};

export default CampaignOfferHeader;
