import React, { useState, useEffect, useRef, useMemo } from 'react';
import BezierChartMobile from '../../Charts/BezierChartMobile/BezierChartMobile';
import AdvancedChart from '../../Charts/AdvancedChart/AdvancedChart';
import ColorCardLarge from '../../Cards/ColorCardLarge/ColorCardLarge';
import { DesktopContext } from '../../../App';

import { getUserReport, getReport, calculateGroupedAverages, getAllPosts, storyPrice, Post, toUnixTimestamp, ReportData } from '../../../Utils/Reports/processReportData';

import './ReportMetricsContainer.css';
import '../../../Styles/globalTexts.css';
import PostDisplayVertical from '../../Posts/PostDisplayVertical/PostDisplayVertical';
import SegmentedButtonSolo, { ButtonProp } from '../../Buttons/SegmentedButtons/SegmentedButtonSolo';

type ToggleButtonProps = {
  title: string;
  active: boolean;
  onPress: () => void;
  color: string;
};

type ReportMetricsContainerProps = {
  reportData: ReportData;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({ title, active, onPress, color }) => (
  <button
    onClick={onPress}
    className={`chart-button ${active ? 'active-chart-tab' : ''}`}
    style={{'--color': color} as React.CSSProperties}
    >
    {title}
  </button>
);

export const ReportMetricsContainer: React.FC<ReportMetricsContainerProps> = ({ reportData }) => {
  const { isDesktop } = React.useContext(DesktopContext);
  const [active, setActive] = useState(0);
  const indicatorPosition = useRef(active * 100);

  const [advanced, setAdvanced] = useState(false);
  const toggleAdvancedChart = () => setAdvanced(!advanced);
  const buttons: ButtonProp[] = [
    {
      value: false,
      label: 'Show posts',
    },
  ];
  const [selectedPost, setSelectedPost] = useState<Post | any>(reportData.posts.slice(-1)[0]);

  useEffect(() => {
    indicatorPosition.current = active * 100;
  }, [active]);

  if (!reportData) {
    return null;
  };

  const { estPrice, impressions, engagements } = calculateGroupedAverages(reportData);

  const datasets = [
    {
      labels: Object.keys(estPrice),
      datasets: [
        {
          data: Object.values(estPrice),
        }
      ]
    },
    {
      labels: Object.keys(impressions),
      datasets: [
        {
          data: Object.values(impressions),
        }
      ]
    },
    {
      labels: Object.keys(engagements),
      datasets: [
        {
          data: Object.values(engagements),
        }
      ]
    },
  ];

  const getScatterPlotData = (type: 'estPrice' | 'impressions' | 'engagements') => {
    return reportData.posts.map((post: any) => ({
      x: toUnixTimestamp(post.timeData.timestamp),
      y: post[type],
      sponsored: post.isSponsoredPost,
      postId: post.id
    }));
  };

  const scatterPlotData = getScatterPlotData(active === 0 ? 'estPrice' : active === 1 ? 'impressions' : 'engagements');

  const handlePointClick = (index: any) => {
    const post = reportData.posts[index]
    console.log(post)
    setSelectedPost(post);
  }

  const userReport = getUserReport(reportData);
  const report = getReport(reportData);
  const avgPostPrice = report?.postPrice || 0;
  const avgStoryPrice = storyPrice(avgPostPrice) || 0;
  const avgImpRate = report?.avgImpressionRate;
  const avgEngRate = report?.avgEngagementRate;
  const avgImp = report?.avgImpressions;
  const avgEng = report?.avgEngagments;

  const followerCount = report?.numberOfFollowers;
  const postsPerMonth = report?.postsPerMonth;

  const colors = ['rgba(111, 174, 156, 0.8)', 'rgba(230, 128, 165, 0.8)', 'rgba(97, 130, 161, 0.8)'];
  const labelOptions = ["Avg. post value", "Avg. impressions", "Avg. engagements"];
  const advancedLabelOptions = ["Post value", "Impressions", "Engagements"];
  const dollarSign = [true, false, false];

  return (
    <div className="metrics-container">
      <div className={isDesktop ? 'metrics-sub-container-desktop' : 'metrics-sub-container-mobile'}>

        <div className={isDesktop ? 'report-card-rows-container-desktop' : 'report-card-rows-container-mobile'}>
          <div className='report-card-row' style={{ backgroundColor: 'var(--valuenceBlack)' }}>
            <ColorCardLarge title={'followers'} num={followerCount} className={'left-card'} />
            <ColorCardLarge title={'posts per month'} num={postsPerMonth} className={'right-card'} />
          </div>
          {/* <div className='report-card-row' style={{ backgroundColor: 'var(--defaultGreen)' }}>
            <ColorCardLarge title={'post value'} num={avgPostPrice} format={'currency'} className={'left-card'} />
            <ColorCardLarge title={'story value'} num={avgStoryPrice} format={'currency'} className={'right-card'} />
          </div> */}
          <div className='report-card-row' style={{ backgroundColor: 'var(--flashyPink)' }}>
            <ColorCardLarge title={'impression rate'} num={avgImpRate} format={'percentage'} className={'left-card'} />
            <ColorCardLarge title={'avg. impressions'} num={avgImp} className={'right-card'} />
          </div>
          <div className='report-card-row' style={{ backgroundColor: 'var(--handsomeBlue)' }}>
            <ColorCardLarge title={'engagement rate'} num={avgEngRate} format={'percentage'} className={'left-card'} />
            <ColorCardLarge title={'avg. engagements'} num={avgEng} className={'right-card'} />
          </div>
        </div>

          <div className="chart-container">
            <div className='flex-row-title'>
              <div className='flex-row'>
                <span className='section-subtitle'>Performance history</span>
                {isDesktop && <SegmentedButtonSolo buttons={buttons} onToggle={toggleAdvancedChart} className={'smallerSegment'} />}
              </div>
              <div className="chart-button-group">
                {[
                  { title: 'Post value', type: 'value' },
                  { title: 'Impressions', type: 'imp' },
                  { title: 'Engagements', type: 'eng' },
                ].map(({ title, type }, index) => (
                  <ToggleButton
                    key={index}
                    title={title}
                    active={active === index}
                    onPress={() => setActive(index)}
                    color={colors[index]}
                  />
                ))}
              </div>
            </div>
            <div
              className="indicator"
              style={{
                left: `${indicatorPosition.current}%`,
              }}
            />
            {advanced === true ?
            <div className='chart-post-display-group'>
              <AdvancedChart
                data={scatterPlotData}
                color={colors[active]}
                labelOption={advancedLabelOptions[active]}
                dollarSign={dollarSign[active]}
                onPointClick={handlePointClick} />

              {selectedPost && <PostDisplayVertical key={selectedPost.postId} {...selectedPost} postId={selectedPost.id} followers={report?.numberOfFollowers} />}
            </div>
              :
              <BezierChartMobile
                data={datasets[active]}
                color={colors[active]}
                labelOption={labelOptions[active]}
                dollarSign={dollarSign[active]}
              />
            }
          </div>

      </div>

    </div>
  );
};

export default ReportMetricsContainer;