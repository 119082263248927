import React, { useState, useContext, useCallback, useEffect, useMemo } from 'react';

import styles from './CampaignOfferList.module.css';
import '../../../../Styles/globalStyles.css';

import { UserContext } from '../../../../App';

import TrianglesLoader from '../../../../Components/Loaders/TrianglesLoader/TrianglesLoader';

import { CampaignOffer, Campaign, CampaignMetricsSummary, sumCampaignOffers } from '../../../../Utils/Campaigns/processCampaigns';
import CampaignOfferHeader from '../CampaignOfferHeader/CampaignOfferHeader';
import AutocompleteTextField from '../../../Inputs/AutocompleteTextField/AutocompleteTextField';
import MainButton from '../../../Buttons/MainButton/MainButton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ModalCreateOffer from '../ModalCreateOffer/ModalCreateOffer';
import { uniq } from '../../../../Utils/Dev_Toolkit/removeDuplicates';
import { Tooltip } from '@mui/material';

export type SortOption = 'updatedAt' | 'createdAt' | 'offerBudget' | 'offerEarnedValue' | 'completionRate' | '';

type CampaignOfferListProps = {
    campaignOfferList: CampaignOffer[];
    campaignData: Campaign;
    onCampaignSummaryUpdate?: (summary: CampaignMetricsSummary) => void;
}

const CampaignOfferList: React.FC<CampaignOfferListProps> = ({ campaignOfferList, campaignData, onCampaignSummaryUpdate }) => {
    const [offers, setOffers] = useState<CampaignOffer[]>(campaignOfferList)
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");

    const { user, setUser } = userContext;
    const [loading, setLoading] = useState<boolean>(true);
    const [token, setToken] = useState<string | null>(null);  // Caching idToken

    const [sortOption, setSortOption] = useState<SortOption>('updatedAt');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const toggleSortOrder = useCallback(() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc'), []);

    const [selectedOfferHandle, setSelectedOfferHandle] = useState<string>("");
    const [openModalCreateOffer, setOpenModalCreateOffer] = useState<boolean>(false);

    useEffect(() => {
        setOffers(campaignOfferList);
    }, [campaignOfferList]);

    const handleCreateCampaignModal = () => {
        setOpenModalCreateOffer(!openModalCreateOffer)
    }

    const handleCreateCampaignModalOpen = () => {
        setOpenModalCreateOffer(true);
    };

    const handleNewOfferCreated = (newOffer: any) => {
        setOffers(prevOffers => [...prevOffers, newOffer]);
        setOpenModalCreateOffer(false);
    };

    const handleUpdatedOffer = useCallback((updatedOffer: CampaignOffer) => {
        setOffers((currentOffers) => currentOffers.map(offer => 
          offer.campaignOfferId === updatedOffer.campaignOfferId ? updatedOffer : offer
        ));
      }, []);

    const handleDeleteOfferCallback = useCallback((deletedOfferId: number) => {
        setOffers((currentOffers) => currentOffers.filter(offer => offer.campaignOfferId !== deletedOfferId));
      }, []);

    const campaignMetricsSummary = useMemo(() => sumCampaignOffers(offers), [offers]);


    useEffect(() => {
        if (onCampaignSummaryUpdate) {
            onCampaignSummaryUpdate(campaignMetricsSummary);
        }
    }, [campaignMetricsSummary, onCampaignSummaryUpdate]);

    const handleList = uniq(offers.map((campaignOffer: CampaignOffer, index: any) => ({
        title: campaignOffer.socialAccountHandle
    })))

    function getNestedValue(obj: any, path: string): any {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    }

    const sortedAndFilteredOffers = useMemo(() => [...offers]
    .filter((offer) => { 
        return selectedOfferHandle === "" || offer.socialAccountHandle === selectedOfferHandle;
    })
    .sort((a, b) => {
        if (!sortOption) return 0;
        const aValue = getNestedValue(a, sortOption);
        const bValue = getNestedValue(b, sortOption);
        if (sortOrder === 'desc') return bValue - aValue;
        return aValue - bValue;
    }), [offers, selectedOfferHandle, sortOption, sortOrder]);

    const offerCount = offers.length;
    const offerText = `${offerCount} ${(offerCount > 1) ? 'Offers' : 'Offer'}`;
    const tooltipPlacement = (window.innerWidth > 600) ? "right" : "top";
    const tooltipDelay = (window.innerWidth > 600) ? 700 : 0;

    return (
        <div className={styles.campaignOfferListContainer}>
            {offers ?
                <div className={styles.campaignOfferListContainer}>
                    <div className={styles.campaignOfferListContainerTopRow}>
                        <div className={styles.campaignOfferTitleAndSearch}>
                            <span className='section-subtitle' style={{ marginLeft: '10px' }}>{offerText}</span>
                            <div className={styles.campaignInputTopRow}>
                                <AutocompleteTextField 
                                    placeholder='Creator handle'
                                    textTip='Invite'
                                    optionList={handleList}
                                    onValueChange={(value) => setSelectedOfferHandle(value.title)}
                                    onNewValuePress={handleCreateCampaignModalOpen} />
                                <Tooltip title='Create a new offer' arrow placement={tooltipPlacement} enterTouchDelay={tooltipDelay}>
                                    <div><MainButton onPress={handleCreateCampaignModalOpen} icon={faPlus} style={{padding: '8px', aspectRatio: '1/1'}} /></div>
                                </Tooltip>
                            </div>
                        </div>
                        <div className={styles.campaignOfferListSortFilterGroupContainer}>
                            <select className={styles.campaignOfferListSortContainer} value={sortOption} onChange={(e) => setSortOption(e.target.value as SortOption)}>
                                <option value="updatedAt">Date updated</option>
                                <option value="createdAt">Date created</option>
                                <option value="offerBudget">Budget</option>
                                <option value="offerEarnedValue">Value</option>
                                <option value="completionRate">Completion rate</option>
                            </select>
                            <div className={styles.campaignOfferListOrderContainer}>
                                {sortOrder === 'desc' ? 'Descending' : 'Ascending'}
                                <button className={styles.campaignOfferListOrderButton} onClick={toggleSortOrder}>
                                    {sortOrder === 'desc' ? '▼' : '▲'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.listContainer}>
                        {sortedAndFilteredOffers.map((campaignOffer: CampaignOffer, index: any) => (
                            <CampaignOfferHeader 
                            campaignOfferData={campaignOffer} 
                            isClickable={true} 
                            key={campaignOffer.campaignOfferId}
                            onOfferUpdated={handleUpdatedOffer}
                            onDeleteOffer={handleDeleteOfferCallback}/>
                        ))}
                    </div>
                </div>
                :
                loading ?
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}><TrianglesLoader /></div>
                    :
                    <p style={{ textAlign: 'center' }}>No offer has been created yet for this campaign</p>
            }
            <ModalCreateOffer 
            open={openModalCreateOffer} 
            onClose={handleCreateCampaignModal} 
            offerHandle={selectedOfferHandle} 
            campaignId={campaignData.campaignId} 
            campaignName={campaignData.campaignName}
            onOfferCreated={handleNewOfferCreated} />
        </div>
    );
};

export default CampaignOfferList;
