import React, { useState } from 'react';
import { Alert } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

interface MenuItem {
    title: string;
    action: () => void;
}

// Add the menuItems prop to the component's props type
interface EllipsisMenuProps {
    menuItems: MenuItem[];
}
  
export default function EllipsisMenu({ menuItems }: EllipsisMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>
                <FontAwesomeIcon
                    icon={faEllipsisV}
                    color='grey' />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {menuItems.map((item, index) => (
                    <MenuItem key={index} onClick={(event: React.MouseEvent) => {
                        event.stopPropagation();
                        handleClose();
                        item.action();
                    }}>
                        {item.title}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}