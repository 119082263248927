import React from 'react';
import { Alert } from '@mui/material';
import styles from './CustomAlert.module.css'; // Adjust the path as needed

interface CustomAlertProps {
    open: boolean;
    onClose: () => void;
    severity: 'error' | 'warning' | 'info' | 'success';
    message: React.ReactNode;
    actions?: {
        label: string;
        onClick: () => void;
        className: 'delete' | 'cancel'; // Add more as needed or adjust to use custom classes
    }[];
}

const CustomAlert: React.FC<CustomAlertProps> = ({ open, onClose, severity, message, actions }) => {
    if (!open) return null;

    return (
        <Alert severity={severity} style={{ borderRadius: '16px', marginTop: '8px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1em' }}>
                <span>{message}</span>
                {actions && <div style={{ display: 'flex', gap: '1em' }}>
                    {actions.map((action, index) => (
                        <button
                            key={index}
                            className={`${styles.alertButton} ${styles[action.className]}`}
                            onClick={() => {
                                action.onClick();
                                // onClose();
                            }}
                        >
                            {action.label}
                        </button>
                    ))}
                </div>}
            </div>
        </Alert>
    );
};

export default CustomAlert;
