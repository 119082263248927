import React, { useState, useMemo, useEffect, useContext } from 'react';
import { DiscoveryReportData } from '../../../Utils/Reports/processReportData';

import '../../../Styles/globalColors.css';
import '../../../Styles/globalStyles.css';
import styles from './DiscoveryCardList.module.css';
import DiscoveryCard from '../DiscoveryCard/DiscoveryCard';

import { UserContext } from '../../../App';

type DiscoveryCardListProps = {
  reportList: DiscoveryReportData[];
  categoryName: string;
  expanded?: boolean;
  titleNoStyle?: boolean;
};

const DiscoveryCardList: React.FC<DiscoveryCardListProps> = ({ reportList, categoryName, expanded = false, titleNoStyle = false }) => {
  const userContext = useContext(UserContext);
  if (!userContext) throw new Error("You probably forgot to provide the UserContext value");
  const { user, setUser } = userContext;

  const [token, setToken] = useState<string | null>(null);

  // Compute 'cachedReports' before initializing the 'reports' state
  const cachedReports: DiscoveryReportData[] = useMemo(() => {
    if (!reportList) return [];
    return [...reportList].reverse();
  }, [reportList]);

  // Initialize 'reports' with 'cachedReports'
  const [reports, setReports] = useState<DiscoveryReportData[]>(cachedReports);

  const [showAll, setShowAll] = useState(expanded);

  useEffect(() => {
    if (user) {
      user.getIdToken(true).then((fetchedToken) => {
        setToken(fetchedToken);
      });
    }
  }, [user]);

  const handleReportDelete = (userReportId: number) => {
    const updatedReports = reports.filter(report => report.userReport?.userReportId !== userReportId);
    setReports(updatedReports);
  };

  const columns = window.innerWidth >= 1450 ? 7 :
    window.innerWidth >= 1200 ? 5 :
      window.innerWidth >= 480 ? 4 : 4;

  const reportsToShow = showAll ? reports : reports.slice(0, columns * 1);


  return (
    <div className={styles.discoveryListContainer}>
      <h1 className={styles.sectionTitle} style={titleNoStyle ? { border: 'none' } : {}}>{categoryName}</h1>
      <div className={styles.discoveryCardsWrapper}>
        {reportsToShow.map(report =>
          <DiscoveryCard 
          key={report.report.profilePictureUrl} 
          discoveryReportData={report} 
          token={token}
          onDelete={handleReportDelete} />
        )}
      </div>
      {!showAll && reports.length > columns * 1 && (
        <button className={styles.showAllButton} onClick={() => setShowAll(true)}>Load {reports.length - columns} more</button>
      )}
    </div>
  );
};

export default DiscoveryCardList;
