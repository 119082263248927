import React, { useState, useMemo, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, menuClasses } from 'react-pro-sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSignOutAlt, faHeart, faCompass, faGear, faSliders, faUser, faMoneyCheck, faChartSimple, faUsers, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import SearchBarSmall from '../../Inputs/SearchBar/SearchBarSmall';
import { UserContext } from '../../../App';

import { auth } from '../../../firebase';
import { signOut } from "firebase/auth";

// import { UserContext } from '../../../App';
// import { debounce } from 'lodash';

import styles from './SideBar.module.css';
import '../../../Styles/globalColors.css';
import { getWorkspaceList } from '../../../Utils/Workspace/getWorkspaces';

// import { Post, Report, ReportData } from '../../../Utils/Reports/processReportData';
// import { fetchUserReports } from '../../../Utils/Reports/fetchUserReports';
// import SocialProfile from '../../SocialProfile/SocialProfile/SocialProfile';

interface Workspace {
    brandProfilePicUrl: string | null;
    country: string | null;
    brandName: string;
    address: string | null;
    city: string | null;
}

interface SideBarProps {
    onPreferencesClick: () => void;
    onBillingClick: () => void;
    onCreateWorkspaceClick: () => void;
}
  
  const SideBar: React.FC<SideBarProps> = ({ onPreferencesClick, onBillingClick, onCreateWorkspaceClick}) => {
    const location = useLocation();
    const [isHovered, setIsHovered] = useState(false);
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const [activeSubmenu, setActiveSubmenu] = useState('');
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");
    const { user, setUser } = userContext;
    const [token, setToken] = useState<string | null>(null);
    const [workspaceList, setWorkspaceList] = useState<Workspace[]>([]);


    // const userContext = useContext(UserContext);
    // if (!userContext) throw new Error("You probably forgot to provide the UserContext value");

    // const { user, setUser } = userContext;
    // const [userReports, setUserReports] = useState<any>(null);
    // const [loading, setLoading] = useState<boolean>(false);
    // const [token, setToken] = useState<string | null>(null);  // Caching idToken

    const handleMouseEnter = () => {
        setIsHovered(true);
    }

    const handleMouseLeave = () => {
        setIsHovered(false);
        setIsSubmenuOpen(false)
    }

    const toggleSubmenu = (submenu: string) => {
        if (activeSubmenu === submenu) {
            setActiveSubmenu(''); // Close submenu if it's already open
        } 
        else {
            setActiveSubmenu(submenu); // Open the clicked submenu
        }
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            console.error(error);
        }
    }

    const getMenuItemClass = (path: string) => {
        return location.pathname === path ? styles.activeLinkIcon : styles.linkIcon;
    };

    useEffect(() => {
        if (user) {
        user.getIdToken(true).then((fetchedToken) => {
            setToken(fetchedToken);
        });
        }
      }, [user]);
      
      // Execute the API call when the component is rendered and when user or token changes
      useEffect(() => {
        handleGetWorkspaceList();
      }, [user, token]);
    

    const handleGetWorkspaceList = async () => {
        if (!token) {
          //console.error("Failed to retrieve ID token");
          return;
        }
    
        const result = await getWorkspaceList(user?.uid, token);
        if (result) {

            // console.log("Result for Workspace List", result);
            // console.log("result workspaceList", result.workspaceList)

            setWorkspaceList(result.workspaceList); // Update the state with the fetched list
        } else {
            console.error("Failed to get workspace list");
        }
    };

    // useEffect(() => {
    //     if (user) {
    //       user.getIdToken(true).then((fetchedToken) => {
    //         setToken(fetchedToken);
    //       });
    //     }
    //   }, [user]);

    // const getUserReports = useMemo(() => debounce(async () => {
    //     console.log('getUserReports is running');
    //     if (!token) {
    //         console.error("Failed to retrieve ID token");
    //         return;
    //     }

    //     setLoading(true);
    //     fetchUserReports(user?.uid, token)
    //         .then((data: ReportData[]) => {
    //             const limitedReports = data.slice(0, 5);
        
    //             setUserReports(limitedReports);
    //             setLoading(false);
    //         })
    //         .catch((error: any) => {
    //             console.error("Failed to fetch report:", error);
    //             setLoading(false);
    //         });
    // }, 300), [token, user]);

    // useEffect(() => {
    //     if (user?.uid && token) {  // Only fetch reports when userId and token are available
    //         getUserReports();
    //     }

    //     // return () => getUserReports.cancel(); // Cancel the debounce if the component unmounts
    // }, [getUserReports, user?.uid, token]);


    return (
        <div style={{ marginLeft: "20px", overflow: 'visible'}}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <Sidebar
                className={styles.container}
                width='190px'
                collapsedWidth='75px'
                rootStyles={{ position: 'fixed', borderRadius: 20, overflow: 'hidden', borderWidth: 0 }}
                collapsed={!isHovered}
            >
                <Menu
                    rootStyles={{
                        [`.${menuClasses.icon}`]: {
                            justifyContent: 'center'
                        },
                    }}
                    menuItemStyles={{
                        button: {
                            backgroundColor: 'transparent',
                            '&:hover': {
                                backgroundColor: 'rgba(205, 205, 205, 0.24)',
                                borderRadius: 80,
                            },
                        },
                    }}
                >
                    <div className={styles.menu} >
                        <div>
                            <MenuItem
                                component={<Link to="/" />}
                                icon={<FontAwesomeIcon
                                    icon={faHome}
                                    size='lg'
                                    className={getMenuItemClass("/")} />}
                            >
                                <span className={styles.linkTitle}>Home</span>
                            </MenuItem>
                            <MenuItem
                                component={<Link to="/discovery" />}
                                icon={<FontAwesomeIcon
                                    icon={faCompass}
                                    className={getMenuItemClass("/discovery")}
                                    size='lg' />}
                            >
                                <span className={styles.linkTitle}>Discovery</span>
                            </MenuItem>
                            <MenuItem
                                component={<Link to="/campaigns" />}
                                icon={<FontAwesomeIcon
                                    icon={faChartSimple}
                                    className={getMenuItemClass("/campaigns")}
                                    size='lg' />}
                                style={{marginTop: '0'}}
                            >
                                <span className={styles.linkTitle}>Campaigns</span>
                            </MenuItem>
                            <MenuItem
                                component={<Link to="/library" />}
                                icon={<FontAwesomeIcon
                                    icon={faHeart}
                                    className={getMenuItemClass("/library")}
                                    size='lg' />}
                            >
                                <span className={styles.linkTitle}>Library</span>
                            </MenuItem>
                            {/* <div>
                                {
                                userReports?.map((report: ReportData) =>
                                    <SocialProfile key={report.userReport.userReportId} reportData={report}/>
                                )}
                            </div> */}
                            </div>
                            <div>
                                {activeSubmenu === 'profile' &&
                                    <div className={styles.submenu}>
                                        <MenuItem
                                        onClick={onPreferencesClick}
                                        icon={<FontAwesomeIcon 
                                            icon={faGear} 
                                            size='sm' />}
                                        >
                                        <span className={styles.sublinkTitle}>Settings</span>
                                        </MenuItem>
                                        {/* <MenuItem
                                        onClick={onBillingClick}
                                        icon={<FontAwesomeIcon 
                                            icon={faSliders} 
                                            size='sm' />}
                                        >
                                        <span className={styles.sublinkTitle}>Plan & billing</span>
                                        </MenuItem> */}
                                        <MenuItem
                                            onClick={handleSignOut}
                                            icon={<FontAwesomeIcon
                                                icon={faSignOutAlt}
                                                size='sm' />}
                                        >
                                            <span className={styles.sublinkTitle}>Sign out</span>
                                        </MenuItem>
                                    </div>
                                }
                                {activeSubmenu === 'workspace' &&
                                    <div className={styles.submenu}>
                                        {workspaceList && workspaceList.length > 0 ? (
                                            workspaceList.map((workspace, index) => (
                                                <MenuItem key={index} /* onClick={to implement...} */>
                                                    <span className={styles.sublinkTitle}>{workspace.brandName}</span>
                                                </MenuItem>
                                            ))
                                        ) : (
                                            null
                                        )}
                                        <MenuItem
                                        onClick={onCreateWorkspaceClick}
                                        >
                                        <span className={styles.sublinkTitle}>+ New Workspace</span>
                                        </MenuItem>
                                    </div>
                                }
                            
                                {/* Workspace */}
                                {/* <MenuItem
                                    onClick={() => toggleSubmenu("workspace")}
                                    
                                    icon={<FontAwesomeIcon
                                        icon={faUsers}
                                        size='lg' />}
                                    >
                                    <span className={styles.linkTitle} style={{ fontWeight: 400 }}>Workspace</span>
                                </MenuItem> */}
                                {/* Profile */}
                                <MenuItem
                                    onClick={() => toggleSubmenu("profile")}
                                    icon={<FontAwesomeIcon
                                        icon={faUser}
                                        size='lg' />}
                                    >
                                    <span className={styles.linkTitle} style={{ fontWeight: 400 }}>Profile</span>
                                </MenuItem>     
                            </div>
                    </div>
                </Menu>
            </Sidebar>
        </div>
    );
};

export default SideBar;
