import React from 'react';
import { formatNumber, formatPercentage } from '../../../Utils/Reports/formatNumber';

import '../../../Styles/globalColors.css';
import styles from './ColorMediumCard.module.css';

interface NumProps {
  num: string | number | null | undefined;
  isCurrency?: boolean;
  isPercentage?: boolean;
};

interface TitleProps {
  title?: string;
};

interface ExtraTitleProps {
  title: string;
};

interface CardProps extends NumProps, TitleProps {
  className?: string;
  type?: string;
  topTitle?: string;
}

const getTextColor = (type?: string) => {
    switch (type) {
      case 'value':
        return 'var(--defaultGreen)';
      case 'imp':
        return 'var(--flashyPink)';
      case 'eng':
        return 'var(--handsomeBlue)';
      case 'followers':
        return "var(--valuenceBlack)";
      default:
        return 'var(--white)';
    }
  };

const NumText: React.FC<NumProps & { type?: string }> = ({ num, isCurrency = false, type, isPercentage = false }) => {
  const displayNum = isPercentage && typeof num === 'number'
  ? 
  formatPercentage(num) 
  : typeof num === 'number' 
  ? 
  formatNumber(num) 
  : num;
  const color = getTextColor(type);
  
  return (
    <div className={styles.numTextTSC} style={{ color: 'rgba(0, 0, 0, 0.72)' }}>
      {isCurrency ? `$${displayNum}` : isPercentage? `${displayNum}%` : displayNum}
    </div>
  );
};

const TitleText: React.FC<TitleProps & { type?: string }> = ({ title, type }) => {
  const color = getTextColor(type);
  
  return (
    <div className={styles.titleTextTSC} style={{ color: 'rgba(0, 0, 0, 0.72)' }}>
      {title}
    </div>
  );
};

const ExtraTitleText: React.FC<ExtraTitleProps & { type?: string }> = ({ title, type }) => {
  const color = getTextColor(type);
  
  return (
    <div className={styles.topTitleTextTSC} style={{ backgroundColor: color }}>
      {title}
    </div>
  );
};

const ColorMediumCard: React.FC<CardProps> = ({ num, title, isCurrency, className, type, isPercentage, topTitle}) => (
  <div className={className}>
    <NumText num={num} isCurrency={isCurrency} type={type} isPercentage={isPercentage}/>
    {topTitle && <ExtraTitleText title={topTitle} type={type}/>}
    <TitleText title={title} type={type}/>
  </div>
);

export default ColorMediumCard;
