import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import BouncingBalls from '../../Components/Loaders/BallsLoader/BouncingBalls';

import { UserContext } from '../../App';

import '../../Styles/globalColors.css'
import styles from './CampaignPage.module.css';
import { ReportData } from '../../Utils/Reports/processReportData';
import CampaignHeader from '../../Components/Campaign/CampaignHeader/CampaignHeader';

import CampaignOfferList from '../../Components/Campaign/CampaignOffers/CampaignOfferList/CampaignOfferList';
import { getCampaignOfferListWithCampaignId } from '../../Utils/CampaignOffers/getCampaignOfferListWithCampaignId';
import { CampaignMetricsSummary } from '../../Utils/Campaigns/processCampaigns';

type CampaignPageProps = {
    reportFromUserReports?: ReportData;
};

const CampaignPage: React.FC<CampaignPageProps> = ({ reportFromUserReports }) => {
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");

    const { user, setUser } = userContext;
    const [token, setToken] = useState<string | null>(null);  // Caching idToken
    const location = useLocation();
    const navigate = useNavigate();
    const [campaignData, setCampaignData] = useState<any>(null);
    const [campaignOfferList, setCampignOfferList] = useState<any[]>([]);
    const [campaignMetricsSummary, setCampaignMetricsSummary] = useState<CampaignMetricsSummary>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
          user.getIdToken(true).then((fetchedToken) => {
            setToken(fetchedToken);
          });
        }
      }, [user]);

    const fetchCampaignData = useMemo(() => async () => {
        const state = location.state;
        setCampaignData(state.campaignData);
    }, [location.state]);

    useEffect(() => {
        fetchCampaignData();
    });

    const fetchCampaignOfferList = () => {
        if (!token) {
            console.error("Failed to retrieve ID token");
            return;
        };
        getCampaignOfferListWithCampaignId(token, campaignData.campaignId)
            .then((campaignOfferList: any) => {
                setCampignOfferList(campaignOfferList)})
    }

    useEffect(() => {
        if (user?.uid && token) {
            fetchCampaignOfferList()
        }
    }, [user?.uid, token]);

    const handleCampaignSummaryUpdate = useCallback((summary: CampaignMetricsSummary) => {
        setCampaignMetricsSummary(summary);
    }, []);

    return (
        <div className={styles.mainCampaign}>
            <div style={{ display: 'flex', width: '100%' }}>
                <button onClick={() => navigate(-1)} className={styles.backButton}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                    Campaigns
                </button>
            </div>
            {loading ?
                <div className={styles.loaderContainer}>
                    <BouncingBalls />
                    <span>We're fetching your report</span>
                    <span>This may take up to 45 seconds</span>
                </div>
                :
                <div className={styles.campaignContainer}>
                    {
                        campaignData ?
                            <div className={styles.campaignContainer}>
                                <CampaignHeader campaignData={campaignData} extraInfoVisible={true} campaignMetricsSummary={campaignMetricsSummary} />
                                <CampaignOfferList campaignOfferList={campaignOfferList} campaignData={campaignData} onCampaignSummaryUpdate={handleCampaignSummaryUpdate} />
                            </div>
                            :
                            'This creator may not exist.  Please make sure their username is correct and that their profile is public'}
                </div>
            }
        </div>
    );
};

export default CampaignPage;