import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import EllipsisMenu from '../../../DumbComponents/EllipsisMenu/EllipsisMenu';
import { Campaign, CampaignMetricsSummary } from '../../../Utils/Campaigns/processCampaigns';

import '../../../Styles/globalColors.css'
import styles from './CampaignHeader.module.css';

import { UserContext } from '../../../App';

import VerticalBarChart from '../../Charts/VerticalBarChart/VerticalBarChart';
import ColorMediumCard from '../../Cards/ColorMediumCard/ColorMediumCard';
import LinearProgressBar from '../../Cards/ProgressBars/LinearProgressBar';
import MainButton from '../../Buttons/MainButton/MainButton';
import CustomAlert from '../../../DumbComponents/CustomAlert/CustomAlert';
import { deleteCampaign } from '../../../Utils/Campaigns/deleteCampaign';
import { formatNumber } from '../../../Utils/Reports/formatNumber';

type ToggleButtonProps = {
    title: string;
    active: boolean;
    onPress: () => void;
    color: string;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({ title, active, onPress, color }) => (
    <button
        onClick={onPress}
        className={`${styles.chartButton} ${active ? styles.activeChartTab : ''}`}
        style={{ '--color': color } as React.CSSProperties} >
        {title}
    </button>
);

interface CampaignHeaderProps {
    campaignData: Campaign;
    isClickable?: boolean;
    extraInfoVisible?: boolean;
    campaignMetricsSummary?: CampaignMetricsSummary;
}

const CampaignHeader: React.FC<CampaignHeaderProps> = ({ 
    campaignData, 
    isClickable, 
    extraInfoVisible, 
    campaignMetricsSummary,
    }) => {
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");
    const { user, setUser } = userContext;
    const [token, setToken] = useState<string | null>(null);  // Caching idToken
    const [campaignMetrics, setCampaignMetrics] = useState<CampaignMetricsSummary>();

    useEffect(() => {
        if (user) {
          user.getIdToken(true).then((fetchedToken) => {
            setToken(fetchedToken);
          });
        }
      }, [user]);

    useEffect(() => {
        if (campaignMetricsSummary) {
            setCampaignMetrics(campaignMetricsSummary);
        }
        else {
            setCampaignMetrics(campaignData.campaignMetricsSummary)
        };
    }, [campaignMetricsSummary]);

    const navigate = useNavigate();

    const campaign = campaignData;
    const addToUrl = campaign.campaignName.replace(/ /gi, '')

    const handleClick = () => {
        if (isClickable) {
            navigate(`/campaigns/${addToUrl}`, { state: { campaignData } });
        }
    };

    const [active, setActive] = useState(0);
    const indicatorPosition = useRef(active * 100);
    const [deleteCampaignAlert, setDeleteCampaignAlert] = useState<boolean>(false)

    useEffect(() => {
        indicatorPosition.current = active * 100;
    }, [active]);

    const campaignBudget = campaignMetrics?.offerBudgetSum || 0;
    const campaignEarnedValue = campaignMetrics?.offerBudgetSum || 0;
    const campaignImpressions = campaignMetrics?.expectedImpressionsHighSum || 0;
    const campaignEngagements = campaignMetrics?.expectedEngagementsLowSum || 0;
    const campaignCompletionRate = (campaignMetrics?.completionRateSum || 0) * 100;

    const colorsExpected = ['rgba(111, 174, 156, 0.48)', 'rgba(230, 128, 165, 0.48)', 'rgba(97, 130, 161, 0.48)'];
    const colorsActuals = ['rgba(111, 174, 156, 0.96)', 'rgba(230, 128, 165, 0.96)', 'rgba(97, 130, 161, 0.96)'];
    const dollarSign = [true, false, false];

    const CPI = campaignBudget / campaignImpressions;
    const CPE = campaignBudget / campaignEngagements;

    const picture = campaign.campaignPictureUrl || `https://source.boringavatars.com/beam/120/${campaign.campaignName}${campaign.campaignId}${user?.uid}?colors=E680A5,6182a1,F6C0C0,6fae9c,68989E,e9e4de,2E2E2E`

    if (!campaignData) {
        return (
            <div className={styles.campaignHeaderHeaderContainer}>
                <h2>No Report Data Available</h2>
            </div>
        );
    }

    const handleDeleteCampaign = (campaign: Campaign) => {
        if (token) {
            deleteCampaign(token, campaign.campaignId);
            navigate(`/campaigns`);
        }
    }

    return (
        <div>
            <div className={`${isClickable ? styles.clickableCampaignHeader : styles.campaignHeaderContainer}`} onClick={handleClick}>
                <div className={styles.topCard}>
                    <div className={`${styles.topCardFlexBoxClickable}`}>
                        <div className={styles.picTitle}>
                            <img
                                src={picture}
                                className={styles.campaignPic}
                                alt='Campaign picture'
                                loading='lazy'
                            />
                            <div className={styles.campaignTitleCard}>
                                <span className={styles.title}>{campaign.campaignName}</span>
                                <LinearProgressBar value={campaignCompletionRate} />
                                <div className={styles.postCountText}>
                                    <span className={styles.postCount}>0</span>/{campaignMetrics?.postCountSum} posts
                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                    <span className={styles.postCount}>0</span>/{campaignMetrics?.storyCountSum} stories
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.metricsAndDropdownButton}>
                        <div className={styles.campaignHeaderMetrics}>
                            <ColorMediumCard num={campaignEarnedValue} topTitle={`value`} title={`vs $${formatNumber(campaignBudget)} budget`} type='value' isCurrency={true} />
                            <ColorMediumCard num={campaignImpressions} topTitle="impressions" title={`vs $${formatNumber(campaignImpressions)} budget`} type='imp' />
                            <ColorMediumCard num={campaignEngagements} topTitle="engagements" title={`vs $${formatNumber(campaignEngagements)} budget`} type='eng' />
                        </div>
                        <EllipsisMenu menuItems={[{title: 'Delete', action: () => setDeleteCampaignAlert(true)}]} />
                    </div>
                </div>
                {/* {extraInfoVisible &&
                    <div className={styles.bottomCard}>
                        <div className={styles.chartButtonGroup}>
                            {campaignArrayResults.map(({ type }, index) => (
                            <ToggleButton
                                key={index}
                                title={type}
                                active={active === index}
                                onPress={() => setActive(index)}
                                color={colorsActuals[index]}
                            />
                            ))}
                        </div>
                        <div className={styles.chartContainer}>
                            <VerticalBarChart
                                dataset1={expectedResults.values}
                                dataset2={actualResults.values}
                                color1={colorsExpected[active]}
                                color2={colorsActuals[active]}
                                labels={campaignArrayMonth}
                                labelOption1={expectedResults.legend}
                                labelOption2={actualResults.legend}
                                dollarSign={dollarSign[active]}
                            />
                        </div>
                        <div className={styles.costMetrics}>
                            <ColorSmallCard num={CPI.toFixed(3)} title="per impression" type='followers' isCurrency={true} />
                            <ColorSmallCard num={CPE.toFixed(2)} title="per engagement" type='followers' isCurrency={true} />
                        </div>
                    </div>
                } */}
            </div>
            {deleteCampaignAlert && <CustomAlert 
                open={deleteCampaignAlert} 
                onClose={() => setDeleteCampaignAlert(false)} 
                severity={'error'} 
                message={<span>You are about to delete <b>{campaign.campaignName}</b></span>}
                actions={[
                    {label: 'Delete', onClick: () => handleDeleteCampaign(campaign), className:'delete' },
                    {label: 'Cancel', onClick: () => setDeleteCampaignAlert(false), className:'cancel' }
                    ]}
                />}
        </div>
    );
};

export default CampaignHeader;
