import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../../Styles/globalColors.css';
import './SegmentedButtons.css';

export interface ButtonProp {
  value?: boolean;
  label: string;
  icon?: any;

}

interface SegmentedButtonSoloProps {
  buttons: ButtonProp[];
  onToggle: () => void;
  className?: string;
}

const SegmentedButtonSolo: React.FC<SegmentedButtonSoloProps> = ({ buttons, onToggle, className }) => {
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
      setIsActive(!isActive);
      onToggle();
    };  

  return (
    <div className="segmented-buttons">
      {buttons.map((button, index) => (
        <button
          key={index}
          onClick={handleClick} // button.value is now boolean
          className={`segmented-button ${className} ${isActive ? "active-segment" : ""}`}>
          {button.icon && <FontAwesomeIcon icon={button.icon} className='button-icon'/>}
          {button.label}
        </button>
      ))}
    </div>
  );
};

export default SegmentedButtonSolo;
