import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartOutline } from '@fortawesome/free-regular-svg-icons';
import { faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import ColorSmallCard from '../../Cards/ColorSmallCard/ColorSmallCard';

import { getUserReport, getReport, ReportData } from '../../../Utils/Reports/processReportData';

import '../../../Styles/globalColors.css'
import styles from './SocialProfileHeader.module.css';

import { UserContext } from '../../../App';
import { updateUserReport } from '../../../Utils/User/updateUserReport';
import LinkButton from '../../Buttons/LinkButton/LinkButton';

interface SocialProfileHeaderProps {
    reportData: ReportData;
    extraInfoVisible?: boolean;
    isClickable?: boolean;
}

const SocialProfileHeader: React.FC<SocialProfileHeaderProps> = ({ reportData, extraInfoVisible, isClickable }) => {
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");
    const { user, setUser } = userContext;

    const [isPickerVisible, setIsPickerVisible] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        if (isClickable) {
            navigate('/creator', { state: { reportData } });
        }
    };

    const report = getReport(reportData);
    const userReport = getUserReport(reportData);
    const profilePic = report?.profilePictureUrl;
    const [isLiked, setIsLiked] = useState(userReport?.likedReport);
    const [userReportId, setUserReportId] = useState(userReport?.userReportId);
    const [token, setToken] = useState<string | null>(null);  // Caching idToken

    useEffect(() => {
        if (!isClickable && user) {
            user.getIdToken(true).then((fetchedToken) => {
                setToken(fetchedToken);
            });
        }
    }, [user]);

    if (!reportData) {
        return (
            <div className={styles.socialProfileHeaderContainer}>
                <h2>No Report Data Available</h2>
            </div>
        );
    }

    const followers = report?.numberOfFollowers;
    const biography = report?.biography;
    const avgPostPrice = report?.postPrice;
    const impRate = report?.avgImpressionRate;
    const engRate = report?.avgEngagementRate;
    const category = report?.creatorCategory;
    const handle = report?.accountHandle || '';

    const socialType = userReport?.reportType || '';
    function getSocialTypeIcon(socialType: string): any {
        switch (socialType) {
            case 'instagram':
                return faInstagram;
            case 'tiktok':
                return faTiktok;
            case 'youtube':
                return faYoutube;
            default:
                return faInstagram;
        }
    }

    function getSocialLink(socialType: string, handle: string): any {
        switch (socialType) {
            case 'instagram':
                return `https://instagram.com/${handle}`;
            case 'tiktok':
                return `https://tiktok.com/@${handle}`;
            case 'youtube':
                return `https://youtube.com/@${handle}`;
            default:
                return `https://instagram.com/${handle}`;
        }
    }

    const profileUrl = getSocialLink(socialType, handle);

    const handleLinkOpen = async (event: React.MouseEvent) => {
        event.stopPropagation();
        
        window.open(profileUrl);
        return;
    }

    const handleIsLiked = async (event: React.MouseEvent) => {

        if (!token) {
            console.error("Failed to retrieve ID token");
            return;
        }

        const updatedReport = await updateUserReport(userReportId, !isLiked, token)  // !isLiked to indicate the change
            .then((data: any) => {
                setIsLiked(data.updatedUserReport.likedReport);  // Toggle isLiked
            })
            .catch((error: any) => {
                console.error("Failed update user report isLiked:", error);
            });
    }

    const isMobile = (window.innerWidth < 800) ? true : false;

    return (
        <div className={`${isClickable ? styles.clickableSocialHeader : styles.socialProfileHeaderContainer}`} onClick={handleClick}>
            <div className={styles.topCard}>

                <div className={`${isClickable ? styles.topCardFlexBoxClickable : styles.topCardFlexBox}`}>
                    <div className={styles.picHandle}>
                        <img
                            src={profilePic}
                            className={styles.profilePic}
                            alt='Creator profile'
                            loading='lazy'
                        />
                        <div className={styles.textCard}>
                            <FontAwesomeIcon icon={getSocialTypeIcon(socialType)} size='2x' color='black' className={styles.profileIcon} />
                            <span className={styles.handle}>{report?.accountHandle}</span>
                            <LinkButton
                                title={''}
                                onPress={handleLinkOpen}
                                className={styles.linkButton}
                            />
                        </div>
                    </div>
                    { 
                    (!isClickable || isMobile) && <FontAwesomeIcon
                        icon={isLiked ? faHeartSolid : faHeartOutline as IconProp}
                        size='2x'
                        className={styles.heart}
                        onClick={handleIsLiked} />
                    }

                    {
                        isClickable &&
                            <div className={styles.socialProfileHeaderMetrics}>
                                <ColorSmallCard num={followers} topTitle="followers" type='followers' />
                                <ColorSmallCard num={impRate} topTitle="imp. rate" type='imp' isPercentage={true} />
                                <ColorSmallCard num={engRate} topTitle="eng. rate" type='eng' isPercentage={true} />
                                <ColorSmallCard num={avgPostPrice} topTitle="post value" type='value' isCurrency={true} />
                            </div>
                    }
                </div>
                { 
                    (isClickable && !isMobile) && <FontAwesomeIcon
                        icon={isLiked ? faHeartSolid : faHeartOutline as IconProp}
                        size='2x'
                        className={styles.heart}
                        onClick={handleIsLiked} />
                }
            </div>
            {/* {biography && (
                <div className={styles.biography}>
                    {biography}
                </div>
            )} */}
        </div>
    );
};

export default SocialProfileHeader;
