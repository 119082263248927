import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Popper, PopperProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import './../../../Styles/globalColors.css';
import styles from './AutocompleteTextField.module.css';

interface AutocompleteTextFieldProps {
  placeholder: string;
  optionList: OptionType[];
  onValueChange: (value: any) => void;
  onNewValuePress?: () => void;
  textTip?: string;
  widthCSS?: number | string;
  noNewValue?: boolean;
  providedRenderOption?: (props: object, option: OptionType, state: object) => React.ReactNode;
  providedRenderInput?: React.ReactNode;
}

export interface OptionType {
  inputValue?: string;
  title: string;
};

interface StyledTextFieldProps {
  inputIsFocused: boolean;
  popperIsOpen: boolean;
};

const StyledTextField = styled(TextField)<StyledTextFieldProps>(({ inputIsFocused, popperIsOpen }) => ({
  // borderRadius: popperIsOpen ? '20px 20px 0 0' : '20px',
  borderRadius: '20px',
  backgroundColor: inputIsFocused ? '#FFF' : 'transparent',
  borderBottomRadius: popperIsOpen ? '0' : '20px',
  '& fieldset': {
    borderRadius: '20px',
    borderWidth: '2px'
  },
  '& Mui-focused': {
    color: 'var(--defaultGreen)',
  },
  '& label': {
    backgroundColor: 'var(--beige)',
    paddingLeft: '6px',
    marginLeft: '-4.5px',
    paddingRight: '7.5px',
    borderRadius: '8px'
  },
  '& label.Mui-focused': {
    color: 'var(--defaultGreen)',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'var(--defaultGreen)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--defaultGreen)',
      color: 'black',
      // borderBottomLeftRadius: popperIsOpen ? '0' : '20px',
      // borderBottomRightRadius: popperIsOpen ? '0' : '20px',
      borderBottomLeftRadius: '20px',
      borderBottomRightRadius: '20px',
      borderWidth: 0,
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    },
  },
}));


const filter = createFilterOptions<OptionType>();

const AutocompleteTextField: React.FC<AutocompleteTextFieldProps> = ({
  placeholder,
  optionList,
  onValueChange,
  onNewValuePress,
  textTip,
  widthCSS,
  noNewValue = false,
  providedRenderOption,
  providedRenderInput }) => {
  const [value, setValue] = React.useState<OptionType | null>(null);
  const [inputIsFocused, setInputIsFocused] = useState<boolean>(false);
  const [popperIsOpen, setPopperIsOpen] = useState<boolean>(false);

  const CustomPopper: React.FC<PopperProps> = (props) => {
    const handleFocus = () => {
      setInputIsFocused(true);
    };

    const handleBlur = () => {
      setInputIsFocused(false);
    };

    if (providedRenderInput) {
      return null
    }

    return <Popper {...props} onFocus={handleFocus} onBlur={handleBlur} style={{ zIndex: 2000, width: widthCSS || 300 }} />;
  };

  return (
    <Autocomplete
      id='autocompleteValuenceBrands'
      value={value}
      className={styles.autocompleteRoot}
      onChange={(event, newValue) => {{
        if (providedRenderInput) {
          setPopperIsOpen(false);
          setInputIsFocused(false);
        }
          else if (typeof newValue === 'string') {
            setValue({ title: newValue });
            onValueChange(newValue);
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({ title: newValue.inputValue });
            onValueChange(newValue.inputValue);
            if (onNewValuePress) {
              onNewValuePress()
            };
          } else {
            setValue(newValue);
            onValueChange(newValue ? newValue : '');
          }
        }
      }}
      onOpen={() => setPopperIsOpen(true)}
      onClose={() => setPopperIsOpen(false)}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        if (inputValue !== '' && !isExisting && noNewValue === false) {
          filtered.push({
            inputValue,
            title: `${textTip ? textTip : 'Create'} "${inputValue}"`,
          });
        }

        return filtered;
      }}
      autoFocus
      clearOnBlur
      handleHomeEndKeys
      options={optionList}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={providedRenderOption || ((props, option) => <li {...props}>{option.title}</li>)}
      sx={{
        width: widthCSS || 300,
      }}
      freeSolo
      size='small'
      renderInput={(params) => {
        if (providedRenderInput) {
          return <div>
            <div style={{display: 'none'}}>
              <StyledTextField {...params} 
                inputIsFocused={false} 
                popperIsOpen={false} 
              />
            </div>
           
            {providedRenderInput}
          </div>
        }
        return (
          <StyledTextField {...params} label={placeholder} size='small'
            onFocus={() => setInputIsFocused(true)}
            onBlur={() => setInputIsFocused(false)}
            inputIsFocused={inputIsFocused}
            popperIsOpen={popperIsOpen}
          />
        );
      }}
      // The props below are to apply the styles to the components making up the Autocomplete component from MUI
      ListboxProps={{ className: styles.autocompleteListbox }}
      PopperComponent={(props) => <CustomPopper 
        {...props} 
        className={styles.autocompletePopper} 
        modifiers={[{name: 'flip', enabled: true}]}
      />}
    />
  );
}

export default AutocompleteTextField;