export const formatNumber = (num: number) => {
  if (num >= 1e13) return (num / 1e12).toFixed(0) + "T";
  if (num >= 1e12) return (num / 1e12).toFixed(2) + "T";
  if (num >= 1e11) return (num / 1e9).toFixed(0) + "B";
  if (num >= 1e10) return (num / 1e9).toFixed(0) + "B";
  if (num >= 1e9) return (num / 1e9).toFixed(1) + "B";
  if (num >= 1e8) return (num / 1e6).toFixed(0) + "M";
  if (num >= 1e7) return (num / 1e6).toFixed(0) + "M";
  if (num >= 1e6) return (num / 1e6).toFixed(1) + "M";
  else if (num >= 1e5) return (num / 1e3).toFixed(0) + "K";
  else if (num >= 1e3) return (num / 1e3).toFixed(1) + "K";
  else return num.toFixed(0).toString();
};

export const formatNumberOffer = (num: number) => {
  if (num >= 1e17) return "ok..";
  if (num >= 1e16) return ">=l";
  if (num >= 1e15) return ":o";
  if (num >= 1e14) return ":l";
  if (num >= 1e13) return ":)";
  if (num >= 1e12) return (num / 1e12).toFixed(2) + "T";
  if (num >= 1e11) return (num / 1e9).toFixed(0) + "B";
  if (num >= 1e10) return (num / 1e9).toFixed(0) + "B";
  if (num >= 1e9) return (num / 1e9).toFixed(1) + "B";
  if (num >= 1e8) return (num / 1e6).toFixed(0) + "M";
  if (num >= 1e7) return (num / 1e6).toFixed(0) + "M";
  if (num >= 1e6) return (num / 1e6).toFixed(1) + "M";
  else if (num >= 1e5) return (num / 1e3).toFixed(0) + "K";
  else if (num >= 1e4) return (num / 1e3).toFixed(1) + "K";
  else return num.toFixed(0).toString();
};

export const alwaysFormatNumber = (num: number) => {
  if (num >= 1e6) return (num / 1e6).toFixed(2) + "M";
  else if (num >= 1e5) return (num / 1e3).toFixed(0) + "K";
  else if (num >= 1e3) return (num / 1e3).toFixed(1) + "K";
  else return (Math.round(num / 5) * 5).toFixed(0).toString();
};


export function formatPercentage(input: number, decimalPlaces: number = 1): string {
  return `${(input * 100).toFixed(decimalPlaces)}`;
}
