import React, { useState } from 'react';
import Login from '../../Components/UserAuth/LoginOption/LoginOption';
import SignUp from '../../Components/UserAuth/SignUpOption/SignUpOption';
import SegmentedControl from '../../Components/Buttons/SegmentedLoginControl/SegmentedLoginControl';

import ValuenceLogo from '../../Assets/Images/LogoValuenceBlack.png';

import './LoginPage.css';

const LoginScreen: React.FC<any> = () => {
  const [isLogin, setIsLogin] = useState(true);

  const handleToggle = () => {
    setIsLogin(!isLogin);
  };

  return (
    <div className='loginPage'>
        <div className="logo">
          <img src={ValuenceLogo} width="240" alt="ValuenceLogo" />
        </div>
        
        <div className="main-login">
                <SegmentedControl onToggle={handleToggle} isLogin={isLogin}/>
                {isLogin ? <Login/> : <SignUp/>}
        </div>
    </div>
  );
};

export default LoginScreen;
