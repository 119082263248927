import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { alwaysFormatNumber } from '../../../Utils/Reports/formatNumber';
import {
    CategoryScale, 
    LinearScale, 
    Chart, 
    PointElement, 
    LineElement,
    Tooltip,

} from 'chart.js'; 

import './BezierChartMobile.css';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

type ChartProps = {
    data: any;
    color: string;
    labelOption: string;
    dollarSign: boolean;
};

const BezierChartMobile: React.FC<ChartProps> = ({ data, color, labelOption, dollarSign }) => {
    const chartRef = useRef<any>();

    useEffect(() => {
        if (chartRef && chartRef.current) {
            const chartInstance = chartRef.current;
            chartInstance.update();
        }
    }, [data]);

    
    const chartOptions: Record<string, any> = {
        scales: {
            y: {
                ticks: {
                    beginAtZero: true,
                    font: {
                        size: window.innerWidth > 650 ? 14 : 10
                    },
                    callback: function(value: string | number) {

                        if (typeof value === 'number') {
                            return dollarSign ? `$${alwaysFormatNumber(value)}` : alwaysFormatNumber(value);
                        }
                        return value;
                    }
                }
            }
        },
        maintainAspectRatio: false,
        responsive: true,
        layout: {
            padding: {
                left: 20,
                right: 40
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function(context: any) {
                        const labelName = context.dataset.label;
                        const value = Number(context.parsed.y);
        
                        const formattedValue = typeof value === 'number'
                            ? (dollarSign ? `$${alwaysFormatNumber(value)}` : alwaysFormatNumber(value))
                            : value;
        
                        return `${labelName}: ${formattedValue}`;
                    }
                }
            }
        }        
    };

    const chartData = {
        labels: data.labels,
        datasets: [{
            data: data.datasets[0].data,
            label: labelOption,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 4,
            pointBackgroundColor: color,
            pointBorderWidth: 4,
            pointRadius: 5,
            tension: 0.4
        }],
    };

    return (
        <div style={{width: 'calc(100% - 12px)', height: '364px'}}>
            <Line ref={chartRef} data={chartData} options={chartOptions} />
        </div>
    );
}

export default BezierChartMobile;
