export const uploadCampaignImageToFirebase = async (file: File, campaignName: string, userId: string) => {
    const campaignId = campaignName.replace(/ /gi, '')

    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", userId);
    formData.append("campaignId", campaignId); // Update as needed

    const requestOptions = {
        method: 'POST',
        body: formData,
        redirect: 'follow' as RequestRedirect
    };

    try {
        const response = await fetch("https://valuencer-e3e83.ue.r.appspot.com/uploadCampaignProfilePic", requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.text();
        console.log("Response Data:", data);
        return data

    } catch (error) {
        console.error("Error uploading campaign image:", error);
    }
};