import React, { useState, useEffect, useContext, useMemo } from 'react';
import SearchBar from '../../Components/Inputs/SearchBar/SearchBar';
import DiscoveryCardList from '../../Components/Discovery/DiscoveryCardList/DiscoveryCardList';

import { UserContext } from '../../App';
import { debounce } from 'lodash';

import styles from './HomePage.module.css';
import { fetchUserReports } from '../../Utils/Reports/fetchUserReports';

import ValuenceLogo from '../../Assets/Images/LogoValuenceBlack.png';
import TrianglesLoader from '../../Components/Loaders/TrianglesLoader/TrianglesLoader';

const HomePage: React.FC = () => {
  const userContext = useContext(UserContext);
  if (!userContext) throw new Error("You probably forgot to provide the UserContext value");

  const { user, setUser } = userContext;
  const [userReports, setUserReports] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      user.getIdToken(true).then((fetchedToken) => {
        setToken(fetchedToken);
      });
    }
  }, [user]);

  const getUserReports = useMemo(() => debounce(async () => {
    if (!token) {
        console.error("Failed to retrieve ID token");
        return;
    }

    setLoading(true);
    fetchUserReports(user?.uid, token)
        .then((data: any) => {
          setUserReports(data);
            setLoading(false);
        })
        .catch((error: any) => {
            console.error("Failed to fetch report:", error);
            setLoading(false);
        });
}, 300), [token, user]);

  useEffect(() => {
    if (user?.uid && token) {
      getUserReports();
    }

    return () => getUserReports.cancel();
  }, [getUserReports, user?.uid, token]);


  return (
    <div className={styles.mainHome}>
      <div className={styles.homeTopSection}>
        <div className={styles.logo}>
            <img src={ValuenceLogo} width="200" alt="ValuenceLogo" />
          </div>
        {/* <h1 className={styles.homeTitle}>Valuence</h1> */}
        <SearchBar />
      </div>
      {userReports ?
        <DiscoveryCardList reportList={userReports} categoryName={'Recently viewed'} expanded={true} titleNoStyle={true} />
        :
        loading ?
        <TrianglesLoader />
          :
        <p style={{textAlign: 'center'}}>You have no search history yet.
          <br></br>
          <br></br> 
          Look up any creator who comes to mind using the search bar above to see their Valuence report.</p>
      }
    </div>
  );
};

export default HomePage;
