import React, { useState, useEffect, useRef } from 'react';
import { Scatter, Line } from 'react-chartjs-2';
import { alwaysFormatNumber } from '../../../Utils/Reports/formatNumber';
import {
    CategoryScale,
    LinearScale,
    Chart,
    PointElement,
    LineElement,
    Tooltip,

} from 'chart.js';
import { format } from 'date-fns';

import '../../../Styles/globalColors.css';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

type ChartProps = {
    data: Array<{
        x: any,
        y: number,
        sponsored: boolean,
        postId: string
    }>;
    color: string;
    labelOption: string;
    dollarSign: boolean;
    onPointClick: (post: any) => void;
};

const AdvancedChart: React.FC<ChartProps> = ({ data, color, labelOption, dollarSign, onPointClick }) => {
    const chartRef = useRef<any>();

    useEffect(() => {
        if (chartRef && chartRef.current) {
            const chartInstance = chartRef.current;
            chartInstance.update();
        }
    }, [data]);

    const chartOptions: Record<string, any> = {
        scales: {
            x: {
                ticks: {
                  callback: function(value: number, index: any, ticks: any) {
                    return format(new Date(value), 'd-MMM-yy');
                    },
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    font: {
                        size: window.innerWidth > 650 ? 14 : 10,
                    },
                    callback: function(value: number, index: any, ticks: any) {

                      return dollarSign ? `$${alwaysFormatNumber(value)}` : alwaysFormatNumber(value);
                    },
                }
                
            },
        },
        maintainAspectRatio: false,
        responsive: true,
        layout: {
            padding: {
                left: 20,
                right: 40
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function(context: any) {
                        const labelName = context.dataset.label;
                        const value = Number(context.parsed.y);
    
                        const formattedValue = typeof value === 'number'
                            ? (dollarSign ? `$${alwaysFormatNumber(value)}` : alwaysFormatNumber(value))
                            : value;
    
                        return `${labelName}: ${formattedValue}`;
                    }
                }
            }
        },
        onClick: function (event: any, item: any) {
        if (item.length > 0) {
            const index = item[0].index;
            onPointClick(index)
            }
        }
    };

    const chartData = {
        datasets: [
            {
                label: labelOption,
                data: data,
                borderColor: data.map((d) => (d.sponsored ? '#2E2E2E' : color)),
                borderWidth: 4,
                pointBackgroundColor: color,
                pointBorderWidth: 4,
                pointRadius: 5,
            },
        ],
    };

    return (
        <div style={{width: 'calc(100% - 270px)', height: '364px'}}>
            <Scatter ref={chartRef} data={chartData} options={chartOptions} />
        </div>
    );
};

export default AdvancedChart;
