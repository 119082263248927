import { SERVER_URLS } from "../../constants";

export const createCampaignOffer = async (
        brandUserId: string | undefined, 
        idToken: string | undefined, 
        socialAccountId: string, 
        socialAccountHandle: string, 
        socialAccountType: string, 
        socialAccountPictureUrl: string, 
        creatorUserId: string, 
        creatorFirstName: string, 
        creatorLastName: string, 
        campaignId: number, 
        campaignName: string, 
        offerBudget: number, 
        postCount: number, 
        storyCount: number, 
        offerCPI: number, 
        offerCPE: number, 
        expectedImpressionsLow: number, 
        expectedImpressionsHigh: number, 
        expectedEngagementsLow: number, 
        expectedEngagementsHigh: number, 
        termsNote: string): Promise<any> => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${idToken}`)
  
    const rawCreateCampaignOffer = JSON.stringify({
        "socialAccountId": socialAccountId, 
        "socialAccountHandle": socialAccountHandle, 
        "socialAccountType": socialAccountType, 
        "socialAccountPictureUrl": socialAccountPictureUrl, 
        "creatorUserId": creatorUserId, 
        "creatorFirstName": creatorFirstName, 
        "creatorLastName": creatorLastName, 
        "brandUserId": brandUserId, 
        "campaignId": campaignId, 
        "campaignName": campaignName, 
        "offerBudget": offerBudget, 
        "postCount": postCount, 
        "storyCount": storyCount, 
        "offerCPI": offerCPI, 
        "offerCPE": offerCPE, 
        "expectedImpressionsLow": expectedImpressionsLow, 
        "expectedImpressionsHigh": expectedImpressionsHigh, 
        "expectedEngagementsLow": expectedEngagementsLow, 
        "expectedEngagementsHigh": expectedEngagementsHigh, 
        "termsNote": termsNote
    });
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: rawCreateCampaignOffer,
      redirect: 'follow' as RequestRedirect
    };
  
    try {
      const response = await fetch(SERVER_URLS.BACKEND_URL+"/valuence/api/createCampaignOffer", requestOptions);
      if (!response.ok) {
        throw new SyntaxError('SyntaxError');
      }
      const result = await response.json();

      return result;
      
    } catch (error) {
        console.log("Couldn't get /createCampaignOffer in createCampaignOffer: ", error)
    }
  };