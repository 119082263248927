import React from 'react';
import './BouncingBalls.css';

const BouncingBalls: React.FC = () => {
  return (
    <div className="bouncing-balls-container">
      <div className="ball"></div>
      <div className="ball"></div>
      <div className="ball"></div>
      <div className="ball"></div>
      <div className="ball"></div>
      <div className="ball"></div>
      <div className="ball"></div>
    </div>
  );
}

export default BouncingBalls;
