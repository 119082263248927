import React, { CSSProperties } from 'react';

import GoogleIcon from '../../../Assets/Icons/GoogleIcon';

import styles from './MainButton.module.css';
import '../../../Styles/globalColors.css';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ButtonProps {
  title?: string;
  onPress: any;
  color?: string;
  width?: number | string;
  googleIcon?: boolean;
  className?: string;
  icon?: IconProp;
  style?: CSSProperties;
}

const MainButton: React.FC<ButtonProps> = ({ title, onPress, googleIcon, className, icon, style }) => {
  return (
        <button 
          onClick={onPress} 
          className={`${styles.mainButton} ${googleIcon ? styles.googleButton : ""} ${className}`}
          style={style}
        >
          {googleIcon && 
              <GoogleIcon className={styles.googleIcon}/>
          }
          {icon && 
          <FontAwesomeIcon icon={icon} className={styles.icon} style={title == undefined ? {margin: 0} : {}}/>}
          <span>{title}</span>
        </button>
  );
};

export default MainButton;
