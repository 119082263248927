// Function to remove duplicates using a hashtable
function uniq<T>(a: T[]): T[] {
    const seen: Record<string, boolean> = {};
    return a.filter((item) => {
      const key = typeof item === 'object' && item !== null ? JSON.stringify(item) : String(item);
      return seen.hasOwnProperty(key) ? false : (seen[key] = true);
    });
  }
  
  // Function to remove duplicates by combining hash lookups for primitives and linear search for objects
  function uniqUniversal<T>(a: T[]): T[] {
    const prims: { [type: string]: Record<string, boolean> } = { boolean: {}, number: {}, string: {} };
    const objs: T[] = [];
  
    return a.filter((item) => {
      const type = typeof item;
      if (type in prims) {
        const key = String(item);
        return prims[type].hasOwnProperty(key) ? false : (prims[type][key] = true);
      } else {
        return objs.indexOf(item) >= 0 ? false : objs.push(item);
      }
    });
  }
  
  // Function to remove duplicates by sorting first, then removing elements equal to the preceding one
  function uniqSort<T>(a: T[]): T[] {
    return a.slice().sort().filter((item, pos, ary) => {
      return !pos || (typeof item === 'object' ? JSON.stringify(item) !== JSON.stringify(ary[pos - 1]) : item !== ary[pos - 1]);
    });
  }
  
  // Function to uniquify a list based on a key
  function uniqByKey<T, K extends PropertyKey>(a: T[], key: (item: T) => K): T[] {
    const seen: Record<K, boolean> = {} as Record<K, boolean>;
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }
  
  export { uniq, uniqUniversal, uniqSort, uniqByKey };
  