import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../../Styles/globalColors.css';
import './SegmentedButtons.css';

export interface ButtonProp {
  value: string;
  label: string;
  icon?: any;
  className?: string;
}

interface SegmentedButtonsProps {
  buttons: ButtonProp[];
  onValueChange: (value: string) => void;
}

const SegmentedButtons: React.FC<SegmentedButtonsProps> = ({ buttons, onValueChange}) => {
  const [value, setValue] = useState(buttons[0].value);

  useEffect(() => {
    onValueChange(value);
  }, [value, onValueChange]);

  return (
    <div className="segmented-buttons">
      {buttons.map((button, index) => (
        <button
          key={index}
          onClick={() => setValue(button.value)}
          className={`segmented-button ${button.className} ${value === button.value ? "active-segment" : ""}`}>
          {button.icon && <FontAwesomeIcon icon={button.icon} className='button-icon'/>}
          {button.label}
        </button>
      ))}
    </div>
  );
};

export default SegmentedButtons;
