import React, { useState, FC } from 'react';
import { IconButton, TextField, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

import '../../../Styles/globalColors.css';
import './PasswordInput.css';

interface StyledTextFieldProps { 
  isFocused: boolean;
 };

const StyledTextField = styled(TextField)<StyledTextFieldProps>(({ isFocused }) => ({
    borderRadius: '20px',
    backgroundColor: isFocused ? '#FFF' : 'transparent',
    borderBottomRadius: '20px',
    '& fieldset': {
      borderRadius: '20px',
      borderWidth: '2px'
    },
    '& Mui-focused': {
      color: 'var(--defaultGreen)',
    },
    '& label': {
      backgroundColor: 'var(--beige)',
      paddingLeft: '6px',
      marginLeft: '-4.5px',
      paddingRight: '7.5px',
      borderRadius: '8px'
    },
    '& label.Mui-focused': {
      color: 'var(--defaultGreen)',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'var(--defaultGreen)', 
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--defaultGreen)',
        color: 'black',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        borderWidth: 0,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      },
      },
  }));

interface PasswordInputProps {
  value: string;
  onChangeText: (text: string) => void;
  isLogin?: boolean;
  className?: string;
}

const PasswordInput: FC<PasswordInputProps> = ({ value, onChangeText, isLogin, className }) => {
  const [isPasswordVisible, setPasswordVisibility] = useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const hasErrors = () => {
    if (!value) return false;
    if (isLogin) return false;
    return !(value.length >= 7 && /\d/.test(value) && /[a-zA-Z]/.test(value) && /\W/.test(value));
  };

  return (
    <div>
      <StyledTextField 
        isFocused={isFocused}
        type={isPasswordVisible ? "password" : "text"} 
        style={{width: '100%'}}
        value={value}
        placeholder="Password"
        className={className}
        size='small'
        onChange={e => onChangeText(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        // onFocus={() => setIsFocused(true)}
        // onBlur={() => setIsFocused(false)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setPasswordVisibility(!isPasswordVisible)}>
                <FontAwesomeIcon icon={isPasswordVisible ? faEyeSlash : faEye} size='xs' />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {hasErrors() && 
      <div className="errorMessages">
        <span style={{fontWeight: 'bold', marginBottom: "4px"}}>Password not strong enough:</span>
        <div className='pwRequirements'>
          <i>- Minimum of 7 characters</i>
          <i>- At least 1 letter</i>
          <i>- At least 1 number</i>
          <i>- At least 1 special character</i>
        </div>
      </div>}
    </div>
  );
};

export default PasswordInput;
