import React, { useState, useEffect, useRef } from 'react';
import ColorSmallCard from '../../Cards/ColorSmallCard/ColorSmallCard';
import { MediaComponent } from '../MediaComponent/MediaComponent';
import LinkButton from '../../Buttons/LinkButton/LinkButton';
import Modal from '@mui/material/Modal';

import { format } from 'date-fns';
import { Post, toUnixTimestamp } from '../../../Utils/Reports/processReportData';
import { getImageAsBase64 } from '../../../Utils/Images/getImageBase64';

import '../../../Styles/globalColors.css';
import "./PostDisplay.css";
import TrianglesLoader from '../../Loaders/TrianglesLoader/TrianglesLoader';


const PostDisplay: React.FC<Post> = ({ 
    estPrice, 
    impressions, 
    engagements, 
    displayUrl, 
    videoUrl, 
    estimated, 
    isSponsoredPost, 
    timeData, 
    postLink, 
    caption,
    postId,
    }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [base64Image, setBase64Image] = useState<string | undefined>(undefined);
    const blurContainerRef = useRef<HTMLDivElement | null>(null);

    const postDate = format(new Date(toUnixTimestamp(timeData.timestamp)), 'MMM d, yyyy')
    const postDateLong = format(new Date(toUnixTimestamp(timeData.timestamp)), 'MMMM d, yyyy')
      
    const isVideo = () => {
      if (videoUrl) {
        return true
      }
      return false
    }
  
    const url = () => {
      if (videoUrl) {
        return videoUrl
      }
      return displayUrl
    }

    useEffect(() => {
        getImageAsBase64(displayUrl, postId)
            .then((base64String) => {
                setBase64Image(`data:image/jpeg;base64, ${base64String}`);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [displayUrl]);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (blurContainerRef.current && !blurContainerRef.current.contains(event.target)) {
                setModalVisible(false);
            }
        };
    
        if (modalVisible) {
            document.addEventListener("mousedown", handleClickOutside);
        }
    
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalVisible]);
    

    return (
        <div>
            <button onClick={() => setModalVisible(true)} className='post-display'>
                {
                    base64Image ?
                    <img src={base64Image} alt="Media" className='media' loading='lazy'/>
                    :
                    <TrianglesLoader />
                }
                <div className='postMetricsDateGroup'>
                    <span>{postDate}</span>
                    <div className="postMetrics">
                        <ColorSmallCard num={estPrice} topTitle="value" type='value' isCurrency={true} title='' />
                        <ColorSmallCard num={impressions} topTitle="impressions" type='imp' />
                        <ColorSmallCard num={engagements} topTitle="engagements" type='eng' />
                    </div>
                </div>
            </button>
            
            {modalVisible && (
                <Modal
                    open={modalVisible}
                    onClose={() => setModalVisible(false)}
                >
                    <div className="centeredView">
                        <div className="blurContainer" ref={blurContainerRef}>
                            <div className="modalTopRow">
                                <span style={{ color: 'rgba(0, 0, 0, 0.64)', fontWeight: '500', fontSize: '14px' }}>{postDateLong}</span>
                                <LinkButton
                                    title={'Open'}
                                    onPress={() => window.open(postLink)}
                                    className="linkButton"
                                />
                            </div>
                            <MediaComponent url={url()} isVideo={isVideo()} caption={caption} postId={postId}/>
                            <div className="postMetrics" style={{ padding: 32, width: '92%' }}>
                                <ColorSmallCard num={estPrice} title="value" type='value' isCurrency={true} />
                                <ColorSmallCard num={impressions} title="impressions" type='imp' />
                                <ColorSmallCard num={engagements} title="engagements" type='eng' />
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default PostDisplay;
