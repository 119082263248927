import React, { useContext, useState, useEffect } from 'react';
import { Modal } from '@mui/material';
import StandardTextInput from '../../Inputs/StandardTextInput/StandardTextInput';
import CurrencyDropdown from '../../Inputs/CurrencyDropdown/CurrencyDropdown';
import TrianglesLoader from '../../Loaders/TrianglesLoader/TrianglesLoader';

import { UserContext } from '../../../App';

import styles from './ModalCreateCampaign.module.css'
import './../../../Styles/globalColors.css'
import MainButton from '../../Buttons/MainButton/MainButton';
import { createCampaign } from '../../../Utils/Campaigns/createCampaign';
import { uploadCampaignImageToFirebase } from '../../../Utils/Images/uploadImagesToFirebase';


interface ModalCreateCampaignProps {
    open: boolean;
    onClose?: () => void;
    campaignName?: string;
    onCampaignCreated?: (newCampaign: any) => void
}

const ModalCreateCampaign: React.FC<ModalCreateCampaignProps> = ({ open, onClose, campaignName = '', onCampaignCreated }) => {
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");
    const { user, setUser } = userContext;
    const [token, setToken] = useState<string | null>(null);

    const [newCampaignName, setNewCampaignName] = useState<string>('');

    const [uploadedImage, setUploadedImage] = useState<string | null>(null);
    const [uploadedImageFile, setUploadedImageFile] = useState<File | null>(null);
    const [uploadError, setUploadError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            // Check file size (2.5MB)
            if (file.size > 2500000) {
                setUploadError('Image size must be less than 2.5MB');
                return;
            }
            // Check file type is image
            if (!['image/gif', 'image/jpeg', 'image/png'].includes(file.type)) {
                setUploadError('Image must be a .gif, .jpeg, .jpg, or .png');
                return;
            }

            setUploadedImageFile(file);
            setUploadError(''); // Clear previous error if any
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setUploadedImage(reader.result as string);
            };
        }
    };

    useEffect(() => {
        if (user) {
            user.getIdToken(true).then((fetchedToken) => {
                setToken(fetchedToken);
            });
        }
    }, [user]);

    useEffect(() => {
        if (campaignName) {
            setNewCampaignName(campaignName)
        }
    })

    const handleCreateCampaign = async () => {
        if (!token) {
            console.error("Failed to retrieve ID token");
            return;
        }

        setLoading(true);

        try {
            if (newCampaignName !== '' && user) {
                let imageUrl: string = '';
                if (uploadedImageFile) {
                    const uploadedUrl = await uploadCampaignImageToFirebase(uploadedImageFile, newCampaignName, user.uid);
                    imageUrl = uploadedUrl || ''
                }
                const response = await createCampaign(user.uid, token, newCampaignName, imageUrl);
    
                if (response && response.campaign) {
                    onCampaignCreated?.(response.campaign); // Call the callback with the campaign response
                }
            } else {
                console.error("Cannot create campaign without a name or user context");
            }
        } catch (error) {
            console.error("Error in creating campaign:", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal open={open} onClose={onClose} className={styles.background}>
            <div className={styles.modalContainer}>
                <div className={styles.profileSettings}>
                    <span className={styles.title}>New Campaign</span>
                    <div className={styles.profileSubSection}>
                        <div className={styles.profileInputs}>
                            <StandardTextInput value={newCampaignName} placeholder={campaignName} onChangeText={setNewCampaignName} className={styles.campaignName}/>
                            <span className={styles.subText}>
                                This campaign name and this picture will appear on the offers your send to creators.
                            </span>
                        </div>
                        <div className={styles.imageContainer}>
                            {uploadedImage ? 
                            <img src={uploadedImage} alt="Uploaded Preview" className={styles.imagePreview} /> 
                            :
                            <div className={styles.imagePlaceholder}></div>}
                            <div className={styles.customUploadButton} onClick={() => document.getElementById("hiddenFileInput")?.click()}>
                                Upload Image
                            </div>
                            <input
                                type="file"
                                id="hiddenFileInput"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleImageUpload}
                            />
                            {uploadError && <div className={styles.error}>{uploadError}</div>}
                        </div>
                    </div>
                </div>
                {loading ? 
                    <TrianglesLoader className={styles.loader}/>
                    :
                    <MainButton 
                        title={'Create Campaign'} 
                        onPress={handleCreateCampaign} 
                        style={{width: '100%'}}
                        className={(uploadError || newCampaignName == '') ? styles.unclickableButton : ''}
                    />
                }
            </div>
        </Modal>
    );
};

export default ModalCreateCampaign;