import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import UserReportList from '../../Components/UserReports/UserReportsList/UserReportList';
import { fetchUserReports } from '../../Utils/Reports/fetchUserReports';

import styles from './LikesPage.module.css';
import '../../Styles/globalStyles.css';

import { UserContext } from '../../App';
import { debounce } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faHeartOutline } from '@fortawesome/free-regular-svg-icons';
import TrianglesLoader from '../../Components/Loaders/TrianglesLoader/TrianglesLoader';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const LikesPage: React.FC = () => {
    const userContext = useContext(UserContext);
    if (!userContext) throw new Error("You probably forgot to provide the UserContext value");

    const { user, setUser } = userContext;
    const [userReports, setUserReports] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [token, setToken] = useState<string | null>(null);  // Caching idToken

    useEffect(() => {
        if (user) {
            user.getIdToken(true).then((fetchedToken) => {
                setToken(fetchedToken);
            });
        }
    }, [user]);

    const fetchReports = async () => {
        if (!token) {
            console.error("Failed to retrieve ID token");
            return;
        }

        setLoading(true);
        fetchUserReports(user?.uid, token)
            .then((data) => {
                setUserReports(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Failed to fetch report:", error);
                setLoading(false);
            });
    };

    const getUserReports = useCallback(debounce(fetchReports, 300), [token, user]);

    useEffect(() => {
        if (user?.uid && token) {
            getUserReports();
        }

        return () => getUserReports.cancel(); // Cancel the debounce if the component unmounts
    }, [getUserReports, user?.uid, token]);

    return (
        <div className={styles.likesPageContainer}>
            <h1>Your Library</h1>
            {userReports ?
                <UserReportList reportList={userReports} />
                :
                loading ?
                    <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}><TrianglesLoader /></div>
                    :
                    <p style={{textAlign: 'center'}}>You haven't liked any creator yet. Search creators and click on the 
                        <FontAwesomeIcon 
                        size='1x'
                        icon={faHeartOutline as IconProp}
                        color='var(--defaultGreen)'
                        style={{marginLeft: '4px', marginRight: '4px'}}
                        /> 
                    icon to add them to your library</p>
            }
        </div>
    );
};

export default LikesPage;
