import { SERVER_URLS } from "../../constants";
import { ReportData } from "./processReportData";

export const getReport = async (type: string, handle: string, userId: string | undefined, idToken: string | undefined): Promise<any> => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${idToken}`)

    const rawGetReport = JSON.stringify({
        "user_id": userId,
        "type": type,
        "handle": handle
    });

    if (!rawGetReport) {
        throw new Error('Request body is not defined');
    }

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: rawGetReport,
        redirect: 'follow' as RequestRedirect
    };

    try {
        const response = await fetch(SERVER_URLS.BACKEND_URL + "/valuence/api/getReport", requestOptions);
        if (!response.ok) {
            throw new SyntaxError('SyntaxError');
        }
        const report = await response.json();

        if (report.report) {
            return report;
        } else {
            throw new Error('Report not found'); // This will trigger the catch block
        }

    } catch (error) {

    }

};  