import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Popover from '@mui/material/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSignOutAlt, faHeart, faCompass, faGear, faSliders, faUser, faChartSimple } from '@fortawesome/free-solid-svg-icons';

import { auth } from '../../../firebase';
import { signOut } from "firebase/auth";

import './BottomNavBar.css';
import '../../../Styles/globalColors.css'

interface BottomNavBarProps {
    onPreferencesClick: () => void;
    onBillingClick: () => void;
  }
  
  const BottomNavBar: React.FC<BottomNavBarProps> = ({ onPreferencesClick, onBillingClick }) => {
    const location = useLocation();
    const [value, setValue] = useState(location.pathname);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            console.error(error);
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <BottomNavigation
            value={value}
            onChange={handleChange}
            // showLabels
            className="bottom-nav"
            style={{backgroundColor: 'var(--white)'}}>
            <BottomNavigationAction
                value="/"
                icon={<FontAwesomeIcon
                    icon={faHome}
                    size='lg'
                    className={value === "/" ? 'tab-active' : 'tab'} />}
                component={Link}
                to="/" />
            <BottomNavigationAction
                value="/discovery"
                icon={<FontAwesomeIcon
                    icon={faCompass}
                    size='lg'
                    className={value === "/discovery" ? 'tab-active' : 'tab'} />}
                component={Link}
                to="/discovery" />
            <BottomNavigationAction
                value="/campaigns"
                icon={<FontAwesomeIcon
                    icon={faChartSimple}
                    size='lg'
                    className={value === "/campaigns" ? 'tab-active' : 'tab'} />}
                component={Link}
                to="/campaigns" />
            <BottomNavigationAction
                value="/library"
                icon={<FontAwesomeIcon
                    icon={faHeart}
                    size='lg'
                    className={value === "/library" ? 'tab-active' : 'tab'} />}
                component={Link}
                to="/library" />
            <BottomNavigationAction
                value="/profile"
                icon={<FontAwesomeIcon
                    icon={faUser}
                    size='lg'
                    className={value === "/profile" ? 'tab-active' : 'tab'} />}
                onClick={handleOpenPopover} />

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                slotProps={{
                    paper: {
                        style: {
                            backgroundColor: 'var(--softBlack)',
                            borderRadius: 20,
                            paddingTop: 8,
                            paddingBottom: 8
                        }
                    }
                  }}
            >
                <BottomNavigationAction
                    label="Preferences"
                    onClick={onPreferencesClick}
                    icon={<FontAwesomeIcon
                        icon={faGear}
                        size='sm'
                        color={'var(--white)'} />}
                    />
                <BottomNavigationAction
                    label="Plan & billing"
                    onClick={onBillingClick}
                    icon={<FontAwesomeIcon
                        icon={faSliders}
                        size='sm'
                        color={'var(--white)'} />}
                    />
                <BottomNavigationAction
                    label="Sign out"
                    onClick={handleSignOut}
                    icon={<FontAwesomeIcon
                        icon={faSignOutAlt}
                        size='sm'
                        color={'var(--white)'} />}
                    component={Link}
                    to="/login" />

            </Popover>

        </BottomNavigation>
    );
};

export default BottomNavBar;
