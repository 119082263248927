import React, { useMemo } from 'react';
import { formatNumber, formatNumberOffer, formatPercentage } from '../../../Utils/Reports/formatNumber';

import './ColorCardLarge.css';

interface ColorCardLargeProps {
  num?: string | number;
  title?: string;
  format?: 'currency' | 'offer' | 'percentage' | null;
  className?: string;
  textColor?: string;
}

interface NumProps {
  num?: string | number;
  format?: 'currency' | 'offer' | 'percentage' | null;
  textColor?: string;
}

const NumText: React.FC<NumProps> = ({ num, format, textColor }) => {
  const displayNum = useMemo(() => {
    if (typeof num !== 'number') return num;
    // Replace with your own implementations.
    switch (format) {
      case 'currency':
        return `$${formatNumber(num)}`;
        case 'offer':
          return `$${formatNumberOffer(num)}`;
      case 'percentage':
        return `${formatPercentage(num)}%`;
      default:
        return formatNumber(num);
    }
  }, [num, format]);

  return (
    <span className="numText" style={{color: textColor}}>
      {displayNum}
    </span>
  );
};

const TitleText: React.FC<{ title?: string, textColor?: string }> = ({ title, textColor }) => (
  <span className="titleText" style={{color: textColor}}>{title}</span>
);

const ColorCardLarge: React.FC<ColorCardLargeProps> = ({ num, title, className, format, textColor }) => (
  <div className={`gradientContainer ${className}`}>
    <NumText num={num} format={format} textColor={textColor} />
    <TitleText title={title} textColor={textColor} />
  </div>
);

export default ColorCardLarge;
