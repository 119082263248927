import React, { useState } from 'react';
import StandardTextInput from '../../Inputs/StandardTextInput/StandardTextInput';
import PasswordInput from '../../Inputs/PasswordInput/PasswordInput';
import MainButton from '../../Buttons/MainButton/MainButton';

import { auth } from '../../../firebase';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { SERVER_URLS } from '../../../constants';

import axios from 'axios';

import './LoginOption.css';

interface LoginProps {
  style?: React.CSSProperties;
}

const Login: React.FC<LoginProps> = ({ style }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // Error message state

  const sendUserData = async (user: any, displayName: string, isCreator: boolean = true) => {
    if(user) {
        const data = {
            user_id: user.uid,
            email: user.email,
            name: displayName,
            isCreator: isCreator,
        };
        const idToken = await user.getIdToken(true);
        console.log("JWT:", idToken);
        const url = SERVER_URLS.BACKEND_URL+'/valuence/api/getOrCreateUser';
        try {
            const response = await axios.post(
                url,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`
                    },
                }
            );
            console.log("Data sent successfully to /getOrCreateUser: ", response.data);
        } catch (error) {
            console.log("Error occurred while sending data to /getOrCreateUser: ", error);
        }
    }
};

  const handleLogin = async () => {
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      sendUserData(user, "displayName");
    } catch (error) {
      setErrorMessage("Invalid email or password."); // Update error message state
      console.log(error);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const { user } = await signInWithPopup(auth, provider);
      sendUserData(user, "displayName");
      console.log('Signed in with Google!');
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className='LoginOption'>
      <StandardTextInput value={email} onChangeText={setEmail} placeholder='Email address'/>
      <PasswordInput value={password} onChangeText={setPassword} isLogin={true}/>
      <MainButton title="Login" onPress={handleLogin}/>
      <MainButton title="Login with Google" onPress={handleGoogleLogin} googleIcon={true} />
      {errorMessage && <p style={{ color: 'var(--flashyPink)' }}>{errorMessage}</p>}
    </div>
  );
};

export default Login;
