import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import styles from './LinkButton.module.css';
import '../../../Styles/globalColors.css';

interface ButtonProps {
  title: string;
  onPress: any;
  color?: string;
  width?: number | string;
  className?: string;
}

const LinkButton: React.FC<ButtonProps> = ({ title, onPress, className }) => {
  return (
        <button 
          onClick={onPress} 
          className={`${styles.linkButton} ${className}`}
        > 
          {title}
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={styles.linkIcon}/>
        </button>
  );
};

export default LinkButton;
