import React, { FC, ChangeEvent, useState } from 'react';

import styles from './CurrencyDropdown.module.css';
import './../../../Styles/globalColors.css';

interface CurrencyDropdownProps {
  onChange?: (currency: string) => void;
}

const CurrencyDropdown: FC<CurrencyDropdownProps> = ({ onChange }) => {
  const [selectedCurrency, setSelectedCurrency] = useState<string>('USD');

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newCurrency = e.target.value;
    setSelectedCurrency(newCurrency);

    if (onChange) {
      onChange(newCurrency);
    }
  };

  return (
    <select className={styles.dropdownContainer} value={selectedCurrency} onChange={handleChange}>
      <option value="USD">USD</option>
      <option value="EUR">EUR</option>
      <option value="CAD">CAD</option>
      <option value="GBP">GBP</option>
      <option value="AUD">AUD</option>
    </select>
  );
};

export default CurrencyDropdown;
