import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute, faVolumeUp, faBars, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { getImageAsBase64 } from '../../../Utils/Images/getImageBase64';

import "./MediaComponent.css";

interface VideoComponentProps {
    videoUrl: string;
    caption?: string;
}

interface ImageComponentProps {
    imageUrl: string;
    caption?: string;
    postId: string;
}

export const VideoComponent: React.FC<VideoComponentProps> = ({ videoUrl, caption }) => {
    const [muted, setMuted] = useState(true);
    const [showCaption, setShowCaption] = useState(false);

    return (
        <div className="videoContainer">
            <video
                src={videoUrl}
                autoPlay
                loop
                muted={muted}
                className="backgroundVideo"
                controls
            />
            {/* <div className="muteButtonContainer">
                <button onClick={() => setMuted(!muted)} className="buttonIcon">
                    <FontAwesomeIcon icon={muted ? faVolumeMute : faVolumeUp} size="lg" />
                </button>
            </div> */}
            <div className="captionButtonContainer">
                <FontAwesomeIcon icon={showCaption ? faArrowDown : faBars} 
                    size="lg" 
                    onClick={() => setShowCaption(!showCaption)} 
                    className="buttonIcon"/>
            </div>
            {showCaption && (
                <div className="captionContainer">
                    <span className="captionText">{caption}</span>
                </div>
            )}
        </div>
    );
}

export const ImageComponent: React.FC<ImageComponentProps> = ({ imageUrl, caption, postId }) => {
    const [showCaption, setShowCaption] = useState(false);
    const [base64Image, setBase64Image] = useState<string | undefined>(undefined);

    useEffect(() => {
        getImageAsBase64(imageUrl, postId)
            .then((base64String) => {
                setBase64Image(`data:image/jpeg;base64, ${base64String}`);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [imageUrl]);

    return (
        <div className="imageContainer">
            <img src={base64Image} alt="Media Content" className="image" />
            <div className="captionButtonContainer">
                <button onClick={() => setShowCaption(!showCaption)} className="buttonIcon">
                    <FontAwesomeIcon icon={showCaption ? faArrowDown : faBars} size="lg" />
                </button>
            </div>
            {showCaption && (
                <div className="captionContainer">
                    <span className="captionText">{caption}</span>
                </div>
            )}
        </div>
    );
};

interface MediaComponentProps {
    url: string;
    isVideo: boolean;
    caption?: string
    postId: string;
}

export const MediaComponent: React.FC<MediaComponentProps> = ({ url, isVideo, caption, postId }) => {
    return isVideo ? (
        <VideoComponent videoUrl={url} caption={caption} />
    ) : (
        <ImageComponent imageUrl={url} caption={caption} postId={postId} />
    );
}
