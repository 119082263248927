import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PostDisplay from '../PostDisplay/PostDisplay';
import { Post, getPosts } from '../../../Utils/Reports/processReportData';
import { DesktopContext } from '../../../App';

import '../../../Styles/globalColors.css';
import './PostDisplayList.css';

type PostDisplayProps = {
  reportData: any;
};

export type SortOption = 'estPrice' | 'timestamp' | 'engagements' | 'impressions' | '';

const PostDisplayList: React.FC<PostDisplayProps> = ({ reportData }) => {
  const { isDesktop } = React.useContext(DesktopContext);
  const [sortOption, setSortOption] = useState<SortOption>('timestamp');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [showSponsored, setShowSponsored] = useState(false);

  const toggleSortOrder = useCallback(() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc'), []);

  const socialPosts = useMemo(() => getPosts(reportData), [reportData]);

  const posts: Post[] = useMemo(() => {
    if (!socialPosts) return [];
    return socialPosts.map((post: any) => {
      return {
        postId: post.id,
        estPrice: Math.floor(post.estPrice),
        impressions: Math.floor(post.impressions),
        engagements: Math.floor(post.engagements),
        displayUrl: post.displayUrl,
        isSponsoredPost: post.isSponsoredPost,
        timeData: {
          timestamp: post.timeData.timestamp
        },
        postType: post.postType,
        estimated: post.isEstimated,
        videoUrl: post.videoData?.videoUrl,
        postLink: post.postLink,
        caption: post.caption
      }
    })
  }, [socialPosts]);

  const sortedAndFilteredPosts = useMemo(() => [...posts]
    .sort((a, b) => {
      if (!sortOption) return 0;
      if (sortOption === 'timestamp') {
        if (sortOrder === 'desc') return new Date(b.timeData.timestamp).getTime() - new Date(a.timeData.timestamp).getTime();
        return new Date(a.timeData.timestamp).getTime() - new Date(b.timeData.timestamp).getTime();
      }
      if (sortOrder === 'desc') return b[sortOption] - a[sortOption];
      return a[sortOption] - b[sortOption];
    })
    .filter((post) => !showSponsored || post.isSponsoredPost), [posts, sortOption, sortOrder, showSponsored]);

    const [displayedPostsCount, setDisplayedPostsCount] = useState<number>(10);
    
    const handleScroll = useCallback(() => {
      if (window.innerHeight + document.documentElement.scrollTop + 24 <= document.documentElement.offsetHeight) return;
    
      setDisplayedPostsCount(prevCount => prevCount + 10);
    }, []);

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [handleScroll]);

  return (
    <div className='post-list-container'>
      <div className="sort-filter-group-container">
        <select className="sort-container" value={sortOption} onChange={(e) => setSortOption(e.target.value as SortOption)}>
          <option value="timestamp">Date posted</option>
          <option value="estPrice">Value</option>
          <option value="engagements">Engagements</option>
          <option value="impressions">Impressions</option>
        </select>
        <button className="order-button" onClick={toggleSortOrder}>
          Order {sortOrder === 'desc' ? '▼' : '▲'}
        </button>
        <button className={showSponsored ? "btn-active" : "btn-inactive"} onClick={() => setShowSponsored(!showSponsored)}>
          {showSponsored ? "Sponsored posts" : "All posts"}
        </button>
      </div>
      <div className={isDesktop ? "desktop-post-list-wrapper" : "post-list-wrapper"}>
      {sortedAndFilteredPosts.slice(0, displayedPostsCount).map(post => <PostDisplay key={post.postId} {...post} />)}
      </div>
    </div>
  );
};

export default PostDisplayList;
