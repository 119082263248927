import { Post } from "../Posts/processPosts";
// 
// 
// CAMPAIGN

export type CampaignMetricsSummary = {
  offerBudgetSum: number;
  postCountSum: number;
  storyCountSum: number;
  offerCPISum: number;
  offerCPESum: number;
  completionRateSum: number;
  expectedImpressionsHighSum: number;
  expectedImpressionsLowSum: number;
  expectedEngagementsLowSum: number;
  expectedEngagementsHighSum: number;
};


export type Campaign = {
    campaignId: number;
    workspaceId: number;
    campaignTeamId: number;
    adminUserId: string;
    campaignPictureUrl: string;
    campaignName: string;
    campaignStatus: string;
    dateCreated: number;
    updatedAt: number;

    campaignMetricsSummary?: CampaignMetricsSummary;
};

// 
// 
// CAMPAIGN OFFER

export type CampaignOffer = {
    creatorUserId: string;
    creatorUserFirstName: string;
    creatorUserLastName: string;
    socialAccountId: string;
    socialAccountHandle: string;
    socialAccountType: string;
    socialAccountPictureUrl: string;
    brandUserId: string;
    // TO ADD in Creator App version
    // brandUserFirstName: string;
    // brandUserLastName: string;
    // brandUserPictureUrl: string;
    campaignId: number;
    campaignOfferId: number;
    campaignName: string;
    dateCreated: number;
    dateLastModified: number;
    dateOfferAccepted: number;
    offerStartDate: number;
    offerEndDate: number;
    offerBudget: number;
    postCount: number;
    storyCount: number;
    offerCPI: number;
    offerCPE: number;
    expectedImpressionsLow: number;
    expectedImpressionsHigh: number;
    expectedEngagementsLow: number;
    expectedEngagementsHigh: number;
    offerStatus: 'Staging' | 'Sent' | 'In discussion' | 'Active' | 'Cancelled' | 'Completed';
    completionRate: number;
    termsNote: string;
};

// 
// 
// CAMPAIGN TEAM

export type CampaignTeam = {
    campaignTeamId: string;
    campaignId: string;
    contributorUserId: string;
    contributorRole: string
}

export function sumCampaignOffers(offers: CampaignOffer[]) {
    return offers.reduce((summary, offer) => {
        summary.offerBudgetSum += offer.offerBudget;
        summary.expectedImpressionsHighSum += offer.expectedImpressionsHigh;
        summary.expectedImpressionsLowSum += offer.expectedEngagementsLow;
        summary.expectedEngagementsHighSum += offer.expectedEngagementsHigh;
        summary.expectedEngagementsLowSum += offer.expectedEngagementsLow;
        // summary.campaignEarnedValue += offer.offerBudget;
        summary.postCountSum += offer.postCount;
        summary.storyCountSum += offer.storyCount;
        summary.completionRateSum += 0;
        summary.offerCPISum += offer.offerCPI;
        summary.offerCPESum += offer.offerCPE;
        return summary;
    }, {
        offerBudgetSum: 0,
        expectedImpressionsHighSum: 0,
        expectedImpressionsLowSum: 0,
        expectedEngagementsHighSum: 0,
        expectedEngagementsLowSum: 0,
        // campaignEarnedValue: 0,
        postCountSum: 0,
        storyCountSum: 0,
        completionRateSum: 0,
        offerCPISum: 0,
        offerCPESum: 0
    });
}